<div class="rounded-appt">
  <div fxLayout="row" class="p-1" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" class="p-3 full-width">
      <div class="dropdown-appointment-dates mr-4">
        {{
        (appt.startDate | date: 'medium') +
        ' - ' +
        (appt.endDate | date: 'shortTime')
        }}
      </div>
      <button *ngIf="showButton" mat-raised-button (click)="select(appt)">{{"meet.select" | translate}}</button>
    </div>
  </div>
</div>


