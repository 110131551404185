import {Type} from "class-transformer";

export enum CareLogPriority {
  normal = 'normal',
  warning = 'warning',
  critical = 'critical',
}
export class CareLogModel {
  _id: string;
  month: number;
  year: number;
  hasWarning: boolean;
  hasCritical: boolean;
  date: Date;
  entries: CareLogEntryModel[];
}

export class CareLogEntryModel {
  csId: string;
  loId: string;
  @Type(() => Date)
  date: Date;
  name: string;
  entry: string;
  priority: CareLogPriority;
}
