import {CsBankModel} from "./Bank";

export class CsInteracDepositModel {
  _id: string;
  amount: number;
  date: Date;
  reference: string;
  csId: string;
  csEmail: string;
  csName: string;
  bankInfo: CsBankModel;
}
