/// <reference types="@types/google.maps" />
import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AddressType } from "@googlemaps/google-maps-services-js";
import { AddressModel, GeneralUtil } from "@nx-c4g/c4g-models";
import { firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UIENVSETTINGS } from "../environment";

export type StateAbbreviationMap = {
  [countryCode: string]: {
    [stateOrProvince: string]: string
  }
};

export type CountryAbbreviationMap = {
  [countryCode: string]: string
}

@Injectable({
  providedIn: "root"
})
export class MapsLoaderService {


  private mapsLoaded = false;

  countryNames: CountryAbbreviationMap = {
    "canada": "CA",
    "united states": "US",
    "mexico": "MX"
  };

  stateProvinceNames: StateAbbreviationMap = {
    "CA": {
      "alberta": "AB",
      "british columbia": "BC",
      "manitoba": "MB",
      "new brunswick": "NB",
      "newfoundland and labrador": "NL",
      "northwest territories": "NT",
      "nova scotia": "NS",
      "nunavut": "NU",
      "ontario": "ON",
      "prince edward island": "PE",
      "quebec": "QC",
      "saskatchewan": "SK",
      "yukon": "YT"
    },
    "US": {
      "alabama": "AL",
      "alaska": "AK",
      "arizona": "AZ",
      "arkansas": "AR",
      "california": "CA",
      "colorado": "CO",
      "connecticut": "CT",
      "delaware": "DE",
      "florida": "FL",
      "georgia": "GA",
      "hawaii": "HI",
      "idaho": "ID",
      "illinois": "IL",
      "indiana": "IN",
      "iowa": "IA",
      "kansas": "KS",
      "kentucky": "KY",
      "louisiana": "LA",
      "maine": "ME",
      "maryland": "MD",
      "massachusetts": "MA",
      "michigan": "MI",
      "minnesota": "MN",
      "mississippi": "MS",
      "missouri": "MO",
      "montana": "MT",
      "nebraska": "NE",
      "nevada": "NV",
      "new hampshire": "NH",
      "new jersey": "NJ",
      "new mexico": "NM",
      "new york": "NY",
      "north carolina": "NC",
      "north dakota": "ND",
      "ohio": "OH",
      "oklahoma": "OK",
      "oregon": "OR",
      "pennsylvania": "PA",
      "rhode island": "RI",
      "south carolina": "SC",
      "south dakota": "SD",
      "tennessee": "TN",
      "texas": "TX",
      "utah": "UT",
      "vermont": "VT",
      "virginia": "VA",
      "washington": "WA",
      "west virginia": "WV",
      "wisconsin": "WI",
      "wyoming": "WY",
      "puerto rico": "PR",
      "guam": "GU",
      "virgin islands": "VI",
      "american samoa": "AS"
    },
    "MX": {
      "aguascalientes": "AGU",
      "baja california": "BC",
      "baja california sur": "BCS",
      "campeche": "CAM",
      "coahuila": "COA",
      "colima": "COL",
      "chiapas": "CHP",
      "chihuahua": "CHH",
      "durango": "DUR",
      "guanajuco": "GUA",
      "guerrero": "GRO",
      "hidalgo": "HID",
      "jalisco": "JAL",
      "mexico": "MEX",
      "michoacán": "MIC",
      "morelos": "MOR",
      "nayarit": "NAY",
      "nuevo león": "NLE",
      "oaxaca": "OAX",
      "puebla": "PUE",
      "querétaro": "QUE",
      "quintana roo": "ROO",
      "san luis potosí": "SLP",
      "sinaloa": "SIN",
      "sonora": "SON",
      "tabasco": "TAB",
      "tamaulipas": "TAM",
      "tlaxcala": "TLA",
      "veracruz": "VER",
      "yucatán": "YUC",
      "zacatecas": "ZAC"
    }
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    @Inject(UIENVSETTINGS) private environment: any

  ) {
  }

  public load(): Promise<any> {
    if (this.mapsLoaded) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const script = this.document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyB6pW9jeHZUp2t__bfzGKJVW5_yyR-6fh8&libraries=places&callback=initMap";

      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.mapsLoaded = true;
        resolve(this.mapsLoaded);
      };
      script.onerror = (error: any) => {
        reject(error);
      };
      document.body.appendChild(script);
    });


  }

  public async cleanAddressWithGoogleMaps(address: AddressModel): Promise<AddressModel> {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    const addressClean: AddressModel = {
      CityStatePostalCode: "",
      Clean: true,
      Type: "",
      Street: "",
      Line3: "",
      City: "",
      State: "",
      stateOrProvinceCode: "",
      PostalCode: "",
      Country: "",
      countryCode: "",
      Latitude: undefined,
      Longitude: undefined
    };

    try {
      const response = await geocoder.geocode({
        address: address.Street + "," +
          address.City + "," +
          address.State + "," +
          address.PostalCode + "," + address.Country
      });
      console.log("angularCleanAddressWithGoogleMaps", response);
      if (response.results && response.results.length > 0) {
        const addressComponents = response.results[0].address_components;
        for (const component of addressComponents) {
          if (component.types.includes(AddressType.street_number)) {
            addressClean.Street = component.long_name;
          } else if (component.types.includes(AddressType.route)) {
            addressClean.Street = addressClean.Street + " " + component.long_name;
          } else if (component.types.includes(AddressType.subpremise)) {
            addressClean.Line3 = component.long_name;
          } else if (component.types.includes(AddressType.locality)) {
            addressClean.City = component.long_name;
          } else if (component.types.includes(AddressType.administrative_area_level_1)) {
            addressClean.State = component.long_name;
            addressClean.stateOrProvinceCode = component.short_name;

          } else if (component.types.includes(AddressType.postal_code)) {
            if (address.PostalCode && address.PostalCode !== "" && address.PostalCode !== component.long_name) {
              console.log("Postal code mismatch", address.PostalCode, component.long_name);
              addressClean.PostalCode = address.PostalCode;
            } else {
              addressClean.PostalCode = component.long_name;
            }
          } else if (component.types.includes(AddressType.country)) {
            addressClean.Country = component.long_name;
          }
        }
        const geometry = response.results[0].geometry;
        addressClean.Latitude = geometry.location.lat();
        addressClean.Longitude = geometry.location.lng();
        addressClean.timeZone = await this.timeZoneByCoordinates(addressClean.Latitude, addressClean.Longitude);

        addressClean.countryCode = GeneralUtil.AddressManagement.returnCountryCode(addressClean.Country);
      } else {
        console.log("Google Maps API error: No results");
      }
    } catch (e) {
      console.log("Google Maps API error: " + e);
    }


    console.log("Cleaned address: ", addressClean);
    return addressClean;
  }

  async timeZoneByCoordinates(lat: number, lng: number): Promise<any> {
    const timestamp = Date.now() / 1000;
    const tz = await firstValueFrom(this.http.get(`${this.environment.api}tasks/timezone/${lat}/${lng}`));
    console.log("tz", tz);
    return tz;
  }
}
