import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-toolbar2',
  templateUrl: './toolbar2.component.html',
})
export class Toolbar2Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService: AppService) {}

  ngOnInit() {}

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }
}
