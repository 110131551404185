import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { AuthenticationService } from '../../../../services/authentication-service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLangService } from '../../../../services/current-lang.service';
import { AppointmentExtensionModel, CGTeamsModel, CareGiverModel } from '@nx-c4g/c4g-models';
import { ExtensionsService } from '../../../../services/extensions/extensions.service';
import { CareTeamExpandedService } from '../../../../services/care-team/care-team-expanded.service';
import { CaregiverProfileService } from '../../../../services/profile/caregiver-profile.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [MenuService],
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  static loadedMenuItems: Array<Menu> = [];
  user: any = null;

  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu> = [];
  extensions: AppointmentExtensionModel[];
  public careTeams: CGTeamsModel[];
  cgProfile: CareGiverModel;
  private subCareTeamExpandedService;
  private subCareTeamExpandedServiceCache;

  constructor(
    public menuService: MenuService,
    public auth: AuthenticationService,
    private translate: TranslateService,
    private currentLangService: CurrentLangService,
    private extensionsService: ExtensionsService,
    private caregiverProfileService: CaregiverProfileService,
    private careTeamxpandedService: CareTeamExpandedService
  ) {}

  async ngOnInit() {
    await this.auth.currentUser();

    // this.auth.userData.subscribe((userData) => {
    this.user = this.auth.user;
    if (this.auth.user) {
      this.extensionsService.extensions.subscribe((extensions) => {
        this.extensions = extensions;
        if (this.extensions.length === 0) {
          this.extensions = null;
        }
      });

      this.extensionsService
        .getExtensionsOrLoad(this.auth.user.uid)
        .then(() => {
          console.log('Extensions loaded');
        });

        this.cgProfile = await this.caregiverProfileService.getProfile(this.auth.user.uid);
      this.careTeams = await this.careTeamxpandedService.reloadCB(
        this.cgProfile,
        true
      );

      this.subCareTeamExpandedService =
      this.careTeamxpandedService.changed.subscribe(async () => {
        console.log('ExtensionsComponent careTeamxpandedService.changed');
        this.careTeams = await this.careTeamxpandedService.reloadCB(
        this.cgProfile,
        true
      );
      });

      this.subCareTeamExpandedServiceCache =
      this.careTeamxpandedService.cacheChanged.subscribe(async () => {
        console.log('ExtensionsComponent careTeamxpandedService.cacheChanged');
        this.careTeams = await this.careTeamxpandedService.reloadCB(
          this.cgProfile,
          true
        );
      });
    }
    // });
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.menuItems = this.menuItems.filter(
      (item) => item.parentId == this.menuParentId
    );
  }

  get invitationCount() {
    if (!this.careTeams) {
      return 0;
    }
    return this.careTeams.filter((ct) => ct.status === 'invited').length;
  }

  ngOnDestroy(): void {
    if (this.subCareTeamExpandedService) {
      this.subCareTeamExpandedService.unsubscribe();
    }

    if (this.subCareTeamExpandedServiceCache) {
      this.subCareTeamExpandedServiceCache.unsubscribe();
    }
  }
}
