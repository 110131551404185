export class MultilingualEntry {
  value = '';
  en = '';
  fr = '';
  uploadRequired?: boolean;
}

export class ServicesSkillsQualificationsList {
  static qualificationsStandard: MultilingualEntry[] = [
    {
      value: 'Companion',
      uploadRequired: false,
      en: 'Companion',
      fr: 'Accompagnateur'
    },
    {value: 'PSW', uploadRequired: true, en: 'Personal Support Worker', fr: 'Préposé aux soins'},
    {value: 'PAB', uploadRequired: true, en: 'Nursing Assistant (PAB)', fr: 'Préposé aux bénéficiaires'},
    {value: 'LPN', uploadRequired: true, en: 'Licensed Practical Nurse (LPN)', fr: 'Infirmière auxiliaire (OIIAQ)'},
    {value: 'RN', uploadRequired: true, en: 'Registered Nurse (RN)', fr: 'Infirmière diplômée (OIIQ)'},
    {
      value: 'APRN',
      uploadRequired: true,
      en: 'Advanced Practice Registered Nurse (APRN)',
      fr: 'Advanced Practice Registered Nurse (APRN)'
    },
    {value: 'None', uploadRequired: false, en: 'None', fr: 'Aucune'},

  ];

  static qualificationsOther: MultilingualEntry[] = [
    {
      value: 'STUDENT',
      uploadRequired: false,
      en: 'Student Nurse',
      fr: 'Étudiant infirmier'
    },
    {value: 'MASSO', uploadRequired: true, en: 'Massotherapist', fr: 'Massothérapeute'},
    {value: 'PHYSIO', uploadRequired: true, en: 'Physiotherapist', fr: 'Physiothérapeute'},
    {value: 'PODIA', uploadRequired: true, en: 'Podiatrist', fr: 'Podiatre'},
    {value: 'TRAINER', uploadRequired: true, en: 'Physical Trainer', fr: 'Entraîneur physique'},
    {
      value: 'NONE',
      uploadRequired: false,
      en: 'None of the above',
      fr: 'Aucune'
    },
  ];

  static services: MultilingualEntry[] = [
    {value: 'Continence', en: "Continence Support", fr: 'Soutien à la continence'},
    {value: 'Hygiene', en: "Personal Care/ Hygiene", fr: 'Soins personnels/hygiène'},
    {
      value: 'Household organization',
      en: "Personal assistant/Hire a daughter (make appts, organize calendars, organize transportation, etc.)",
      fr: 'Organization personnelle (prendre des rendez-vous, organiser des calendriers, organiser le transport, etc.)\n'
    },
    {value: 'Light cleaning', en: "Light cleaning", fr: 'Entretien ménager léger'},
    {value: 'Housekeeping', en: "Housekeeping", fr: 'Entretien ménager'},
    {value: 'Toileting', en: "Toileting", fr: 'Toilette'},
    {value: 'Bathing', en: "Bathing", fr: 'Bain'},
    {value: 'Food preparation', en: "Food preparation", fr: 'Préparation de repas'},
    {value: 'Groceries / errands', en: "Groceries / errands", fr: 'Épicerie / courses'},
    {value: 'Driving (short drives)', en: "Transportation", fr: 'Transport'},
    {value: 'Companionship', en: "Companionship", fr: 'Compagnie'},
    {value: 'Medication', en: "Medication Administration", fr: 'Administration des médicaments'},
    {value: 'Hairdressing / haircut', en: "Grooming (Beauty care)", fr: 'Soins de beauté'},
    {value: 'Handyman / Home repairs', en: "Handyman / Home repairs", fr: 'Bricolage / réparations à domicile'},
    {value: 'Garden work / snow removal', en: "Garden work / snow removal", fr: 'Travaux de jardinage / déneigement'},
  ]

  static skills: MultilingualEntry[] = [
    {value: 'Dementia', en: "Dementia care", fr: 'Soins aux personnes atteintes de démence'},
    {value: 'Parkinson', en: "Parkinson", fr: 'Parkinson'},
    {value: 'MS', en: "MS", fr: 'SEP'},
    {value: 'PostOp', en: "Post Operative patients", fr: 'Patients postopératoires'},
    {value: 'Sundown', en: "Sundown syndrome", fr: 'Syndrome du coucher du soleil (sundown)'},
    {value: 'Lift', en: "Using a lift", fr: 'Utilisation d\'un lève-personne'},
    {value: 'Tube', en: "Tube feeding", fr: 'Alimentation par sonde'},
    {value: 'Wounds', en: "Dressing and wounds", fr: 'Pansements et plaies'},
    {value: 'Injections', en: "Injections", fr: 'Injections'},
    {value: 'Suction', en: "Suction", fr: 'Aspiration'},
    {value: 'Foley', en: "Foley bags and catheters", fr: 'Sondes de Foley et cathéters'},
  ]

  static languages: MultilingualEntry[] = [
    {value: 'English', en: "English", fr: 'Anglais'},
    {value: 'Spanish', en: "Spanish", fr: 'Espagnol'},
    {value: 'Italian', en: "Italian", fr: 'Italien'},
    {value: 'Hindi', en: "Hindi", fr: 'Hindi'},
    {value: 'Russian', en: "Russian", fr: 'Russe'},
    {value: 'German', en: "German", fr: 'Allemand'},
    {value: 'French', en: "French", fr: 'Français'},
    {value: 'Arabic', en: "Arabic", fr: 'Arabe'},
    {value: 'Greek', en: "Greek", fr: 'Grec'},
    {value: 'Mandarin', en: "Mandarin", fr: 'Mandarin'},
    {value: 'Portuguese', en: "Portuguese", fr: 'Portugais'},
    {value: 'Hungarian', en: "Hungarian", fr: 'Hongrois'},
    {value: 'Romanian', en: "Romanian", fr: 'Roumain'},
    {value: 'Tagalog', en: "Tagalog", fr: 'Tagalog'},
    {value: 'Dutch', en: "Dutch", fr: 'Néerlandais'},
    {value: 'Hebrew', en: "Hebrew", fr: 'Hébreu'},
    {value: 'Vietnamese', en: "Vietnamese", fr: 'Vietnamien'},
    {value: 'Japanese', en: "Japanese", fr: 'Japonais'},
    {value: 'Other', en: "Other", fr: 'Autre'},

  ]
}


