export class AddressModel {
  Street: string;
  City: string;
  CityStatePostalCode: string;
  Country: string;
  Latitude: number;
  Line3?: string;
  Longitude: number;
  PostalCode: string;
  State: string;
  stateOrProvinceCode?: string;
  Type: string;
  Clean = false;
  countryCode?: string;
  timeZone?: string;
}

