import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication-service/authentication.service';
import { AppService } from '../../../app.service';
import { CareGiverModel } from '@nx-c4g/c4g-models';
import { CaregiverProfileService } from '../../../services/profile/caregiver-profile.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  user: any = null;
  careGiver: CareGiverModel;

  constructor(
    public appService: AppService,
    public auth: AuthenticationService,
    private titleService: Title,
    private translateService: TranslateService,
    private caregiverProfileService: CaregiverProfileService
  ) {}

  async ngOnInit() {
    await this.auth.currentUser();
    // this.auth.userData.subscribe(async (userData) => {
    this.user = this.auth.user;
    if (this.user) {
      this.careGiver = await this.caregiverProfileService.getProfile(
        this.user.uid
      );
      this.titleService.setTitle(
        'CareGiver - ' + this.careGiver.Name.FirstAndLast
      );
    }
    // });
  }

  async deleteAccount() {
    if (confirm(this.translateService.instant('Menu.DeleteAccountConfirmation'))) {
      await this.caregiverProfileService.deleteProfile(this.user.uid);
    }
  }

  isAdmin(): boolean {
    return this.auth.isAdmin();
  }

}
