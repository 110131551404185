import { DaysEnum, WeeklyAvailabilitiesModel } from '../../careGiverModel';
import { AppointmentModel } from '../../appointmentModel';
import { AppointmentTypesModel } from '../../enums/AppointmentTypesModel';
import { PricingUtil } from '../pricing/pricing.util';

export class DateUtil {


  static LEAD_TIME = 3;

  static fixAppointmentDates(appointment: AppointmentModel) {
    appointment.startDate = new Date(appointment.startDate);
    appointment.endDate = new Date(appointment.endDate);
  }

  static minutesBetweenDates(time: Date, time1: Date) {
    const diff = Math.abs(new Date(time).getTime() - new Date(time1).getTime());
    return Math.floor(diff / 60000);
  }

  static differentTimeZone(start: Date, end: Date): boolean {
    return new Date(start).getTimezoneOffset() !== new Date(end).getTimezoneOffset();
  }

  static timeZoneDifference(start: Date, end: Date): number {
    return new Date(start).getTimezoneOffset() - new Date(end).getTimezoneOffset();
  }

  static isBefore(startDate: Date, targetDate: Date) {
    startDate = new Date(startDate);
    targetDate = new Date(targetDate);
    return startDate < targetDate;
  }

  static isTomorrow(start: any) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    start = new Date(start);
    return (
      start.getDate() === tomorrow.getDate() &&
      start.getMonth() === tomorrow.getMonth() &&
      start.getFullYear() === tomorrow.getFullYear()
    );
  }

  static isToday(start: Date) {
    const today = new Date();
    start = new Date(start);
    return (
      start.getDate() === today.getDate() &&
      start.getMonth() === today.getMonth() &&
      start.getFullYear() === today.getFullYear()
    );
  }

  static endOfTime(): Date {
    return new Date(8640000000000000);
  }

  static sameDate(apptDate: Date, day: Date) {
    // return if Date parts are equal
    return (
      apptDate.getDate() === day.getDate() &&
      apptDate.getMonth() === day.getMonth() &&
      apptDate.getFullYear() === day.getFullYear()
    );
  }

  static isGreaterThan(dateToCheck: Date, targetDate: Date) {
    // check that dateToCheck is greater than targetDate
    return dateToCheck.getTime() > targetDate.getTime();
  }

  static isWithin(date: Date, startDate: Date, endDate: Date) {
    // return if date is within startDate and endDate
    return (
      date.getTime() >= startDate.getTime() &&
      date.getTime() <= endDate.getTime()
    );
  }

  static isPast(date: Date): boolean {
    const now = new Date();
    return date < now;
  }

  static validTimeFormat(time: string): boolean {
    if (
      time.toLowerCase().includes('am') ||
      time.toLowerCase().includes('pm')
    ) {
      const timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9]\s?(am|pm)$/i;
      return timeRegex.test(time);
    } else {
      const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return timeRegex.test(time);
    }
  }

  static convertTimeTo24HourFormat(time: string): string {
    // Check if the time is already in 24-hour format
    if (/^([01]\d|2[0-3]):[0-5]\d$/.test(time)) {
      return time;
    }
    // If not ending with AM/PM check if is is in fact 24h model, but wihtout leading 0
    if (
      !time.toLowerCase().includes('am') &&
      !time.toLowerCase().includes('pm')
    ) {
      const match = time.match(/^(\d{1,2}):(\d{2})$/i);
      if (match) {
        return `${match[1].padStart(2, '0')}:${match[2]}`;
      }
    }

    // Convert the time to 24-hour format
    const match = time.match(/^(\d{1,2}):(\d{2})\s?(AM|PM)$/i);
    if (!match) {
      throw new Error(`Invalid time format: ${time}`);
    }
    let hour = parseInt(match[1]);
    const minute = match[2];
    const meridian = match[3];
    if (meridian.toUpperCase() === 'PM' && hour !== 12) {
      hour += 12;
    } else if (meridian.toUpperCase() === 'AM' && hour === 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, '0')}:${minute}`;
  }

  static decimalMinutesToString(minutesInput: number): string {
    const sign = minutesInput < 0 ? '-' : '';
    const hrs = Math.abs(minutesInput) / 60;
    const hrsPart = Math.floor(hrs);
    const minutes = (hrs % 1) * 60;
    const minPart = Math.floor(minutes);
    // const secs = Math.floor(minutes % 1 * 60);

    return (
      sign +
      hrsPart.toString().padStart(2, '0') +
      ':' +
      minPart.toString().padStart(2, '0')
    ); //
    // + ":" + secs.toString().padStart(2, '0');
  }

  static decimalMinutesToShortString(minutesInput: number): string {
    const sign = minutesInput < 0 ? '-' : '';
    const hrs = Math.abs(minutesInput) / 60;
    const hrsPart = Math.floor(hrs);
    const minutes = (hrs % 1) * 60;
    const minPart = Math.floor(minutes);
    return (
      sign +
      hrsPart.toString().padStart(2, '0') +
      'h' +
      minPart.toString().padStart(2, '0') +
      'm'
    );
  }

  static firstDayOfWeek(date: Date): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() - date1.getDay());
    date1.setHours(0, 0, 0, 0); // Ensure time is set to midnight
    return date1;
  }

  static lastDayOfWeek(date: Date): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() + (6 - date1.getDay()));
    date1.setHours(23, 59, 59, 999); // Ensure time is set to just before midnight
    return date1;
  }

  static generateWeeks(startDate: Date, endDate: Date): Date[] {
    console.log('generateWeeks in', startDate, endDate);
    const start = DateUtil.firstDayOfWeek(startDate);
    const end = DateUtil.firstDayOfWeek(endDate);
    console.log('generateWeeks out', start, end);

    const weeks: Date[] = [];
    const currentWeek = new Date(start);
    // targetEndDate is 10 weeks after the endDate
    const targetEndDate = DateUtil.addWeeks(endDate, 10);
    while (currentWeek <= targetEndDate) {
      weeks.push(new Date(currentWeek));
      currentWeek.setDate(currentWeek.getDate() + 7);
    }
    return weeks;
  }

  static getDatePart(date: Date) {
    const date1: Date = new Date(date);
    date1.setHours(0, 0, 0, 0);
    return date1;
  }

  static getYMD(date: Date, tz?: string): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (!tz) {
      const month = date.getMonth() + 1; //months from 1-12
      const day = date.getDate();
      const year = date.getFullYear();
      return year + '/' + month + '/' + day;
    } else {
      const date1 = new Date(date.toLocaleString('en-US', { timeZone: tz }));
      const month = date1.getMonth() + 1; //months from 1-12
      const day = date1.getDate();
      const year = date1.getFullYear();
      return year + '/' + month + '/' + day;
    }
  }

  static getYMDDashes(date: Date, tz?: string): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (!tz) {
      const month = date.getMonth() + 1; //months from 1-12
      const day = date.getDate();
      const year = date.getFullYear();
      return year + '-' + month + '-' + day;
    } else {
      const date1 = new Date(date.toLocaleString('en-US', { timeZone: tz }));
      const month = date1.getMonth() + 1; //months from 1-12
      const day = date1.getDate();
      const year = date1.getFullYear();
      return year + '-' + month + '-' + day;
    }
  }

  static lastFullWeek(): { start: Date; end: Date } {
    const today = new Date();
    const day = today.getDay();
    const prevSunday = new Date(today);
    prevSunday.setDate(today.getDate() - day);
    const prevSaturday = new Date(today);
    prevSaturday.setDate(today.getDate() - day + 6);
    return { start: prevSunday, end: prevSaturday };
  }

  static lastSunday(): Date {
    // If we are sunday, return the previous sunday otherwise return the previous sunday
    const today = new Date();
    const day = today.getDay();
    const prevSunday = new Date(today);
    prevSunday.setDate(today.getDate() - day);
    return prevSunday;
  }

  static nextWeek(date: Date): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() + 7);
    return date1;
  }

  static addDays(date: Date, count: number): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() + count);
    return date1;
  }

  static addWeeks(date: Date, count: number): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() + 7 * count);
    return date1;
  }

  static addMonths(date: Date, count: number): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() + 31 * count);
    return date1;
  }

  static prevWeek(date: Date): Date {
    const date1 = new Date(date);
    date1.setDate(date1.getDate() - 7);
    return date1;
  }

  static overlaps(
    range1: { start: Date; end: Date },
    range2: { start: Date; end: Date },
  ): boolean {
    return range1.start < range2.end && range1.end > range2.start;
    // return (range1.start <= range2.end) && (range1.end >= range2.start);
  }

  // static addMinutes(endDate: Date, minutes: number) {
  //   const retDate = new Date(new Date(endDate).getTime() + minutes * 60000);
  //   return retDate;
  // }

  static isAvailableInTimeSlot(
    expandedschedulerData: any[],
    startDate: Date,
    endDate: Date,
  ) {
    // disable old time slots

    const now = new Date();
    now.setHours(now.getHours() + DateUtil.LEAD_TIME);
    if (endDate < now) {
      return false;
    }

    const apptsAtDate: any[] = DateUtil.apptsBtDates(
      expandedschedulerData,
      startDate,
      endDate,
    );
    if (apptsAtDate.length !== 0) {
      return false;
    }

    return true;
  }

  static apptsBtDates(apptArray: any[], start: Date, end: Date) {
    return apptArray.filter((appt) => {
      if (start >= appt.startDate && end <= appt.endDate) {
        return true;
      } else {
        return false;
      }
    });
  }

  static apptsAroundDate(apptArray: any[], start: Date) {
    return apptArray.filter((appt) =>
      DateUtil.aroundDay(appt.startDate, start),
    );
  }

  static aroundDay(d1: Date, d2: Date) {
    const d3 = new Date(d2);
    d3.setDate(new Date(d2).getDate() - 1);

    return (
      (d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()) ||
      (d1.getFullYear() === d3.getFullYear() &&
        d1.getMonth() === d3.getMonth() &&
        d1.getDate() === d3.getDate())
    );
  }

  static apptsOverlaps(
    apptArray: any[],
    start: Date,
    end: Date,
    appointment: AppointmentModel,
  ): any[] {
    const overlapArray: any[] = [];

    for (let i = 0; i < apptArray.length; i++) {
      const appt: AppointmentModel = apptArray[i];
      if (
        DateUtil.overlaps(
          { start: appt.startDate, end: appt.endDate },
          { start: start, end: end },
        )
      ) {
        overlapArray.push(appt);
      }
    }

    return overlapArray;
  }

  static apptsIncludes(apptArray: any[], start: Date, end: Date): any[] {
    const includesArray: any[] = [];
    for (let i = 0; i < apptArray.length; i++) {
      const appt: AppointmentModel = apptArray[i];
      const range1 = { start: appt.startDate, end: appt.endDate };
      const range2 = { start: start, end: end };
      if (DateUtil.within(range1, range2)) {
        includesArray.push(appt);
      }
    }
    return includesArray;
  }

  static within(
    host: { start: Date; end: Date },
    client: { start: Date; end: Date },
  ): boolean {
    if (host.start <= client.start && host.end >= client.end) {
      return true;
    } else {
      return false;
    }
  }

  static within24hours(d1: Date): boolean {
    const today = new Date();
    const date1 = new Date(d1);
    return Math.abs(today.getTime() - date1.getTime()) / 36e5 <= 24;
  }

  static within48hours(d1: Date): boolean {
    const today = new Date();
    const date1 = new Date(d1);
    return Math.abs(today.getTime() - date1.getTime()) / 36e5 <= 48;
  }

  static within2hours(d1: Date): boolean {
    const today = new Date();
    const date1 = new Date(d1);
    return Math.abs(today.getTime() - date1.getTime()) / 36e5 <= 2;
  }

  static in3hrs() {
    let date = new Date();
    date.setMinutes(0, 0, 0);
    date = DateUtil.addTime(date, 3, 0, 0);
    return date;
  }

  static diffDays(dt2: Date, dt1: Date) {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff / (60 * 60 * 24)));
  }

  static diffMinutes(dt2: Date, dt1: Date) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  static diffMinutesNotAbs(dt2: Date, dt1: Date) {
    // Convert both dates to UTC
    const endDateUTC = new Date(
      dt2.toUTCString());
    const startDateUTC = new Date(
      dt1.toUTCString(),
    );

    // Calculate the difference in minutes
    const diff = (endDateUTC.getTime() - startDateUTC.getTime()) / 1000 / 60;

    return Math.round(diff);
  }

  static getTimeWithOffset(date: Date, timezoneOffset: number): Date {
    // Create a new Date object that represents the same time as the
    // provided date, but with the specified timezone offset applied.
    // Return the adjusted date.
    return new Date(date.getTime() + timezoneOffset * 60 * 60 * 1000);
  }

  static addFractionalHours(date: Date, hours: number) {
    console.log('addFractionalHours', date, hours);
    const millisecondsToAdd =
      PricingUtil.roundToTwoDecimals(hours) * 60 * 60 * 1000;
    const adjustedDate = new Date(date.getTime() + millisecondsToAdd);
    console.log('addFractionalHours', adjustedDate);
    // Return the adjusted date.
    return adjustedDate;
  }

  static addTime(
    date: Date,
    hours: number,
    minutes: number,
    seconds: number,
  ): Date {
    // Create a new Date object that represents the same time as the
    // provided date, but with the specified hours, minutes, and seconds added.
    const adjustedDate = new Date(date);
    adjustedDate.setHours(date.getHours() + hours);
    adjustedDate.setMinutes(date.getMinutes() + minutes);
    adjustedDate.setSeconds(date.getSeconds() + seconds);

    // Return the adjusted date.
    return adjustedDate;
  }

  static formatTime(date: Date, useAmPm: boolean, tz: string): string {
    // Choose the appropriate format string based on the useAmPm parameter.
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const format = useAmPm ? 'h:mm:ss a' : 'HH:mm:ss';

    // Use the toLocaleTimeString() method to format the time in the
    // specified format and the default locale (i.e., the user's language and region).
    // Return the formatted time string.
    if (!tz) {
      return date.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: useAmPm,
      });
    } else {
      return date.toLocaleTimeString('en-US', {
        timeZone: tz,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: useAmPm,
      });
    }
  }

  static formatDate(date: Date, small = false): string {
    // small is Month Day
    // !small is Month Day, Year
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const format = small ? 'MMM d' : 'MMM d, yyyy';
    return small
      ? date.toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
        })
      : date.toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
  }

  static formatDateTime(date: Date, useAmPm: boolean, tz: string): string {
    // Choose the appropriate format string based on the useAmPm parameter.
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const format = useAmPm ? 'h:mm:ss a' : 'HH:mm:ss';

    // Use the toLocaleTimeString() method to format the time in the
    // specified format and the default locale (i.e., the user's language and region).
    // Return the formatted time string.
    if (!tz) {
      return (
        date.toLocaleDateString() +
        ' ' +
        date.toLocaleTimeString(undefined, {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: useAmPm,
        })
      );
    } else {
      return (
        date.toLocaleDateString() +
        ' ' +
        date.toLocaleTimeString('en-US', {
          timeZone: tz,
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: useAmPm,
        })
      );
    }
  }

  static hoursMinutesToDecimalHours(hours: number, minutes: number): number {
    return hours + minutes / 60;
  }

  static overlappingTime(
    set1: WeeklyAvailabilitiesModel,
    set2: WeeklyAvailabilitiesModel,
  ): boolean {
    const startDayValueSet1 = DateUtil.getDayValue(set1.day);
    const startDayValueSet2 = DateUtil.getDayValue(set2.day);

    const endDayValueSet1 = DateUtil.getDayValue(set1.endDay);

    // Check if the set1 is over a day
    if (endDayValueSet1 !== startDayValueSet1) {
      // check if the set2 day is the same as the set1 end day
      if (endDayValueSet1 === startDayValueSet2) {
        // check if the set2 time is before the set1 end time
        if (set2.startTime < set1.endTime) {
          return true;
        }
      } else {
        return false;
      }
    }

    const startTime1 = DateUtil.getDayValue(set1.day) + ':' + set1.startTime;
    const endTime1 =
      DateUtil.getDayValue(set1.endDay) === 0 &&
      DateUtil.getDayValue(set1.day) !== 0
        ? 7
        : DateUtil.getDayValue(set1.endDay) + ':' + set1.endTime;
    const startTime2 = DateUtil.getDayValue(set2.day) + ':' + set2.startTime;
    const endTime2 =
      DateUtil.getDayValue(set2.endDay) === 0 &&
      DateUtil.getDayValue(set2.day) !== 0
        ? 7
        : DateUtil.getDayValue(set2.endDay) + ':' + set2.endTime;

    // the end hour can be smaller if after midnight
    return (
      (startTime1 >= startTime2 && startTime1 <= endTime2) ||
      (endTime1 >= startTime2 && endTime1 <= endTime2) ||
      (startTime1 <= startTime2 && endTime1 >= endTime2)
    );
  }

  static addMinutes(latestEndTime: string, number: number) {
    // Time is in the format HH:mm
    const time = latestEndTime.split(':');
    let hours = parseInt(time[0], 10);
    let minutes = parseInt(time[1], 10);
    minutes += number;
    if (minutes >= 60) {
      hours += 1;
      minutes -= 60;
    }
    if (hours >= 24) {
      hours -= 24;
    }
    // pad with leading zero if needed
    const hoursStr = hours < 10 ? '0' + hours : hours.toString();
    const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    return hoursStr + ':' + minutesStr;
  }

  static addHours(nextStartTime: string, number: number) {
    // Time is in the format HH:mm
    const time = nextStartTime.split(':');
    let hours = parseInt(time[0], 10);
    hours += number;
    if (hours >= 24) {
      hours -= 24;
    }
    const hoursStr = hours < 10 ? '0' + hours : hours.toString();

    return hoursStr + ':' + time[1];
  }

  static nextDay(day: DaysEnum) {
    switch (day) {
      case DaysEnum.SUN:
        return DaysEnum.MON;
      case DaysEnum.MON:
        return DaysEnum.TUE;
      case DaysEnum.TUE:
        return DaysEnum.WED;
      case DaysEnum.WED:
        return DaysEnum.THU;
      case DaysEnum.THU:
        return DaysEnum.FRI;
      case DaysEnum.FRI:
        return DaysEnum.SAT;
      case DaysEnum.SAT:
        return DaysEnum.SUN;
    }
  }

  static getDayValue(day: DaysEnum) {
    switch (day) {
      case DaysEnum.SUN:
        return 0;
      case DaysEnum.MON:
        return 1;
      case DaysEnum.TUE:
        return 2;
      case DaysEnum.WED:
        return 3;
      case DaysEnum.THU:
        return 4;
      case DaysEnum.FRI:
        return 5;
      case DaysEnum.SAT:
        return 6;
    }
  }

  static processAvailability(weeklyAvailability: WeeklyAvailabilitiesModel) {
    if (weeklyAvailability.startTime > weeklyAvailability.endTime) {
      weeklyAvailability.searchStartTime = `00:${weeklyAvailability.startTime}`;
      weeklyAvailability.searchEndTime = `01:${weeklyAvailability.endTime}`;
    } else {
      weeklyAvailability.searchStartTime = `00:${weeklyAvailability.startTime}`;
      weeklyAvailability.searchEndTime = `00:${weeklyAvailability.endTime}`;
    }
    return weeklyAvailability;
  }

  static decimalHoursToHoursMinutes(hours: number): {
    hours: number;
    minutes: number;
  } {
    const h = Math.floor(hours);
    const m = Math.round((hours - h) * 60);
    return { hours: h, minutes: m };
  }

  static decimalHoursToHoursMinutesStr(hours: number) {
    const time = DateUtil.decimalHoursToHoursMinutes(hours);
    return time.hours + ':' + time.minutes.toString().padStart(2, '0') + 'h';
  }

  static lastDayOfYear(year: number) {
    const date1 = new Date(`${year}-12-31`);
    return date1;
  }

  static firstDayOfYear(year: number) {
    const date1 = new Date(`${year}-01-01`);
    return date1;
  }

  static firstDayOfMonth(year: number, month: number) {
    const date1 = new Date(`${year}-${month}-01`);
    return date1;
  }

  static lastDayOfMonth(year: number, month: number) {
    const nextMonthDate = new Date(year, month + 1, 1);

    // Subtract 1 day from the next month's first day to get the last day of the current month
    const lastDayOfMonth = new Date(nextMonthDate.getTime() - 1);

    // Return the day component of the last day
    return lastDayOfMonth.getDate();
  }

  static beginingtOfDay(date: Date) {
    date.setHours(0, 0, 0, 0);
  }

  static endOfDay(date: Date) {
    date.setHours(23, 59, 59, 999);
  }

  static getPeriodStartDate(
    startDate: Date,
    targetDate: Date,
    length: number,
  ): Date {
    // Calculate the number of days between the start and target dates
    const daysBetween = Math.ceil(
      (targetDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
    );

    // Calculate the number of periods that fit between the start and target dates
    const periodsBetween = Math.floor(daysBetween / length);

    // Calculate the start date of the last period that fits between the start and target dates
    const lastPeriodStartDate = new Date(
      startDate.getTime() + periodsBetween * length * 24 * 60 * 60 * 1000,
    );

    // Otherwise, return the start date of the last period
    return lastPeriodStartDate;
  }
}
