import { EventEmitter, Injectable } from "@angular/core";
import { registerNotificationService } from "./notify";

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  private _notification: EventEmitter<NotificationMessage> = new EventEmitter<NotificationMessage>();
  public getNotification() {
    console.log('getNotification');
    return this._notification;
  }

  constructor() {
    registerNotificationService(this);
  }

  public emit(message: string, type: string, delay: number) {
    console.log('emit', {message, type});
    this._notification.emit({message, type, delay});
  }
}

export class NotificationMessage {
  message = "";
  type = "";
  delay= 1000;
}
