import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'c4g-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
})
export class RefreshComponent {
  @Input() color = 'primary';

  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  doRefresh() {
    this.refresh.emit();
  }
}

