import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { AuthenticationService } from '../../../../services/authentication-service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLangService } from '../../../../services/current-lang.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [MenuService],
})
export class HorizontalMenuComponent implements OnInit {
  static loadedMenuItems: Array<Menu> = [];
  user: any = null;

  public menuItems: Array<Menu> = [];

  constructor(
    public menuService: MenuService,
    public auth: AuthenticationService,
    private translate: TranslateService,
    private currentLangService: CurrentLangService
  ) {}

  async ngOnInit() {
    await this.auth.currentUser();

    // this.auth.userData.subscribe((userData) => {
      this.user = this.auth.user;
    // });
    this.menuItems = this.menuService.getHorizontalMenuItems();
  }
}
