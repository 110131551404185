import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterNeighborhoodsPipe } from './filter-neighborhoods';
import { FilterStreetsPipe } from './filter-streets.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FilterByIdPipe, FilterNeighborhoodsPipe, FilterStreetsPipe],
  exports: [FilterByIdPipe, FilterNeighborhoodsPipe, FilterStreetsPipe],
})
export class PipesModule {}
