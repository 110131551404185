import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CareGiverModel } from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-care-giver-basic-info-dialog',
  templateUrl: './care-giver-basic-info-dialog.component.html',
  styleUrls: ['./care-giver-basic-info-dialog.component.scss'],
})
export class CareGiverBasicInfoDialogComponent implements OnInit {
  careGiver: CareGiverModel;
  firstFormGroup!: FormGroup;

  lang = 'en';

  constructor(
    public dialogRef: MatDialogRef<CareGiverBasicInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.careGiver = JSON.parse(JSON.stringify(data.careGiver));
    this.lang = data.lang;
  }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({});
  }

  save() {
    this.careGiver.residence = this.firstFormGroup.controls['Residence'].value;
    this.careGiver.caregiverType = this.firstFormGroup.controls['CaregiverType'].value;

    this.careGiver.Name.First = this.firstFormGroup.controls['First'].value;
    this.careGiver.Name.Last = this.firstFormGroup.controls['Last'].value;
    this.careGiver.Phone = this.firstFormGroup.controls['Phone'].value;

    this.careGiver.Name.FirstAndLast = `${this.firstFormGroup.controls['First'].value} ${this.firstFormGroup.controls['Last'].value}`;
    this.careGiver.AreasServedRateAndConditions.CGHourlyRate = this.firstFormGroup.controls['CGHourlyRate'].value;

    // IMPORTANT: RATES
    this.careGiver.calculatedRate =
      this.careGiver.AreasServedRateAndConditions.CGHourlyRate + 5;

    this.careGiver.Address.Street = this.firstFormGroup.controls['Line1'].value;
    this.careGiver.Address.Line3 = this.firstFormGroup.controls['Line2'].value;
    this.careGiver.Address.Latitude = this.firstFormGroup.controls['Latitude'].value;
    this.careGiver.Address.Longitude = this.firstFormGroup.controls['Longitude'].value;

    this.careGiver.Address.PostalCode = this.firstFormGroup.controls['PostalCode'].value;
    this.careGiver.Address.City = this.firstFormGroup.controls['City'].value;
    this.careGiver.Address.Country = this.firstFormGroup.controls['Country'].value;
    this.careGiver.Address.State = this.firstFormGroup.controls['Province'].value;
    this.careGiver.Address.timeZone = this.firstFormGroup.controls['timeZone'].value;
    this.careGiver.Address.CityStatePostalCode = `${this.careGiver.Address.City} ${this.careGiver.Address.PostalCode}`;
    this.dialogRef.close(this.careGiver);
  }
}
