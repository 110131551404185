export enum Sentiment {
  POSITIVE = 'POSITIVE',
  NEUTRAL = 'NEUTRAL',
  NEGATIVE = 'NEGATIVE'
}

export enum CompatibilityLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export interface AiTraitModel {
  _id: string;
  fr: {
    trait: string;
    description: string;
  };
  en: {
    trait: string;
    description: string;
  };
  sentiment: Sentiment;

  // Overrides
  sentimentCg?: Sentiment;
  sentimentLo?: Sentiment;
  sentimentCs?: Sentiment;
}

export interface AiCompatibilityMatrixElementModel {
  _id: string;
  cgTraitId: string;
  csTraitId: string;
  compatibility: CompatibilityLevel;
}
