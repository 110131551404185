import { Component, OnDestroy, OnInit } from "@angular/core";
import { MeetService } from "../../services/meet/meet.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { MeetModel, MeetRequestModel, MeetTypesModel } from "@nx-c4g/c4g-models";
import { notify } from "@nx-c4g/c4g-ui";

@Component({
  selector: 'nx-c4g-meet-request',
  templateUrl: './meet-request.component.html',
  styleUrls: ['./meet-request.component.scss'],
})
export class MeetRequestComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription;
  meetRequest: MeetRequestModel;
  selectedMeet: MeetModel;
   noDatesChoice = false
   declineChoice = false;

  constructor(
    private meetService: MeetService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(async params => {
      const id = params["id"];
      this.meetRequest = await this.meetService.getCGMeetRequest(id);
      this.meetRequest.timeSlots.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
      console.log('this.meetRequest', this.meetRequest);
      if (this.meetRequest.selectedMeet) {
        this.selectedMeet = this.meetRequest.selectedMeet;
      }
      if (this.meetRequest.noDates) {
        this.noDatesChoice = true;
      }
      if (this.meetRequest.declined) {
        this.declineChoice = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  async noDates() {
    this.selectedMeet = null;
    this.noDatesChoice = true;
    this.meetRequest.noDates = true;
    this.meetRequest = await this.meetService.setMeetRequestResponse(this.meetRequest);
    notify(this.translateService.instant('meet.responseSent'), 'info', 5000);

  }

  async decline() {
    this.declineChoice = true;
    this.selectedMeet = null;
    this.meetRequest.declined = true;
    this.meetRequest = await this.meetService.setMeetRequestResponse(this.meetRequest);

    notify(this.translateService.instant('meet.responseSent'), 'info', 5000);

  }

  async selected(meet: MeetModel) {
    this.selectedMeet = meet;
    this.meetRequest.selectedMeet = meet;
    this.meetRequest = await this.meetService.setMeetRequestResponse(this.meetRequest);

    notify(this.translateService.instant('meet.selected'), 'info', 5000);

  }
}
