import {Component, OnInit, ViewChild, HostListener} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Settings, AppSettings} from '../app.settings';
import {ExtensionsService} from "../services/extensions/extensions.service";
import {PusherService} from "../services/pusher/pusher.service";
import {AuthenticationService} from "../services/authentication-service/authentication.service";
import {AddressModel, CareGiverModel, GeneralUtil} from "@nx-c4g/c4g-models";
import {CaregiverProfileService} from "../services/profile/caregiver-profile.service";
import { Observable, shareReplay } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map } from "rxjs/operators";


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) sidenav: any;
  public toolbarTypes = [1, 2];
  public toolbarTypeOption: number;
  public headerTypes = ['default', 'image', 'carousel'];
  public headerTypeOption: string;
  public searchPanelVariants = [1, 2, 3];
  public searchPanelVariantOption: number;
  public headerFixed = false;
  public showBackToTop = false;
  public scrolledCount = 0;

  private cg: CareGiverModel;

  public settings: Settings;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public appSettings: AppSettings,
    public router: Router,
    private extensionsService: ExtensionsService,
    private pusherService: PusherService,
    private route: ActivatedRoute,
    public auth: AuthenticationService,
    private careGiverService: CaregiverProfileService
  ) {
    this.settings = this.appSettings.settings;
  }

  initChannel(userId: string) {
    const channel = this.pusherService.getChannel(userId);

    channel.bind('extension-change', (data) => {
      console.log('extension-change');
      this.extensionsService.getExtensionsOrLoad(userId, true).then((extensions) => {
        console.log('Extensions', extensions);
      });
    });
  }

  async ngOnInit() {
    this.toolbarTypeOption = this.settings.toolbar;
    this.headerTypeOption = this.settings.header;
    this.searchPanelVariantOption = this.settings.searchPanelVariant;
    await this.auth.currentUser();

    // this.auth.userData.subscribe(async (userData) => {
      if (this.auth.user) {
        const currentRoute = this.router.routerState.snapshot.url;
        if (!currentRoute.endsWith('me') && !currentRoute.endsWith('error-page')) {
          console.log('CHECKING CAREGIVER PROFILE', currentRoute, this.auth.user.uid);
          this.cg = await this.careGiverService.getProfile(this.auth.user.uid);
          if (!GeneralUtil.checkAddress(this.cg.Address)) {
            this.router.navigate(['/care-givers/me']);
          }
        }
        this.router.events.subscribe(async event => {
          if (event instanceof NavigationEnd) {
            if (!event.url.endsWith('me') && !currentRoute.endsWith('error-page')) {
              console.log('CHECKING CAREGIVER PROFILE', currentRoute, this.auth.user.uid);
              this.cg = await this.careGiverService.getProfile(this.auth.user.uid);
              if (!GeneralUtil.checkAddress(this.cg.Address))  {
                this.router.navigate(['/care-givers/me']);
              }
            }
          }
        });
        this.initChannel(this.auth.user.uid);
      }
    // });

  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public chooseToolbarType() {
    this.settings.toolbar = this.toolbarTypeOption;
    window.scrollTo(0, 0);
  }

  public chooseHeaderType() {
    this.settings.header = this.headerTypeOption;
    window.scrollTo(0, 0);
    this.router.navigate(['/']);
  }

  public chooseSearchPanelVariant() {
    this.settings.searchPanelVariant = this.searchPanelVariantOption;
  }

  @HostListener('window:scroll') onWindowScroll() {
    const scrollTop = Math.max(
      window.scrollY,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);

    if (this.settings.stickyMenuToolbar) {
      let top_toolbar = document.getElementById('top-toolbar');
      if (top_toolbar) {
        if (scrollTop >= top_toolbar.clientHeight) {
          this.settings.mainToolbarFixed = true;
        } else {
          this.settings.mainToolbarFixed = false;
        }
      }
    }

    let load_more = document.getElementById('load-more');
    if (load_more) {
      if (window.innerHeight > load_more.getBoundingClientRect().top + 120) {
        if (!this.settings.loadMore.complete) {
          if (this.settings.loadMore.start) {
            if (this.scrolledCount < this.settings.loadMore.step) {
              this.scrolledCount++;
              if (!this.settings.loadMore.load) {
                this.settings.loadMore.load = true;
              }
            } else {
              this.settings.loadMore.start = false;
              this.scrolledCount = 0;
            }
          }
        }
      }
    }
  }

  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }

  ngAfterViewInit() {
    document.getElementById('preloader')?.classList.add('hide');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
        this.settings.mainToolbarFixed = false;
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    });
  }

  isInViewport(elementId: string) {
    const element = document.getElementById(elementId);
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

}
