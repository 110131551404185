import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { CurrentLangService } from '../../../services/current-lang.service';
import { HttpClient } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { CapacitorHttp } from '@capacitor/core';
import { VERSION } from '../../../../version';
import { AppointmentExtensionModel, CGTeamsModel, CareGiverModel } from '@nx-c4g/c4g-models';
import { ExtensionsService } from '../../../services/extensions/extensions.service';
import { AuthenticationService } from '../../../services/authentication-service/authentication.service';
import { CareTeamExpandedService } from '../../../services/care-team/care-team-expanded.service';
import { CaregiverProfileService } from '../../../services/profile/caregiver-profile.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public lat = 40.678178;
  public lng = -73.944158;
  public zoom = 12;
  version: string;
  today = new Date();
  extensions: AppointmentExtensionModel[];
  public careTeams: CGTeamsModel[];



  public mapStyles: any = [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#000000',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8b9198',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#323336',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#414954',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#2e2f31',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#7a7c80',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#242427',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#202022',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#393a3f',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#202022',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#393a3f',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#202022',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#202124',
        },
      ],
    },
  ];
  public feedbackForm: FormGroup;
  public subscribeForm: FormGroup;
  constructor(
    public formBuilder: FormBuilder,
    private httpClient: HttpClient,
    public auth: AuthenticationService,
    public lang: CurrentLangService,
    private extensionsService: ExtensionsService,
    private caregiverProfileService: CaregiverProfileService,
    private careTeamxpandedService: CareTeamExpandedService,
  ) {
    this.version = VERSION;
  }

  get authenticated(): Observable<any> {
    return this.auth.isLoggedIn();
  };

  calcCols(): number {
    // 5 on mobile
    // 7 on tablet and greater
    return this.isMobile() ? 5 : 6;
  }

  isMobile() {
    return window.innerWidth < 500;
  }

  cgProfile: CareGiverModel;
  private subCareTeamExpandedService;
  private subCareTeamExpandedServiceCache;

  async ngOnInit() {
    this.feedbackForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
      message: ['', Validators.required],
    });
    this.subscribeForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    });
    await this.auth.currentUser();
    if (this.auth.user) {
      // this.authenticated = true;
      this.extensionsService.extensions.subscribe((extensions) => {
        this.extensions = extensions;
        if (this.extensions.length === 0) {
          this.extensions = null;
        }
      });

      this.extensionsService.getExtensionsOrLoad(this.auth.user.uid).then(() => {
        console.log('Extensions loaded');
      });

      this.cgProfile = await this.caregiverProfileService.getProfile(this.auth.user.uid);
      this.careTeams = await this.careTeamxpandedService.reloadCB(
        this.cgProfile,
        true
      );

      this.subCareTeamExpandedService =
      this.careTeamxpandedService.changed.subscribe(async () => {
        console.log('ExtensionsComponent careTeamxpandedService.changed');
        this.careTeams = await this.careTeamxpandedService.reloadCB(
        this.cgProfile,
        true
      );
      });

      this.subCareTeamExpandedServiceCache =
      this.careTeamxpandedService.cacheChanged.subscribe(async () => {
        console.log('ExtensionsComponent careTeamxpandedService.cacheChanged');
        this.careTeams = await this.careTeamxpandedService.reloadCB(
          this.cgProfile,
          true
        );
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subCareTeamExpandedService) {
      this.subCareTeamExpandedService.unsubscribe();
    }

    if (this.subCareTeamExpandedServiceCache) {
      this.subCareTeamExpandedServiceCache.unsubscribe();
    }
  }

  get invitationCount() {
    if (!this.careTeams) {
      return 0;
    }
    return this.careTeams.filter((ct) => ct.status === 'invited').length;
  }

  public onFeedbackFormSubmit(values: Object): void {
    if (this.feedbackForm.valid) {
    }
  }

  public onSubscribeFormSubmit(values: Object): void {
    if (this.subscribeForm.valid) {
    }
  }

  tos() {
    if (this.lang.currentLang === 'en') {
      window.open('https://care4giver.com/tos/');
    } else {
      window.open('https://care4giver.com/conditions-utilisation/?lang=fr');
    }
  }
}
