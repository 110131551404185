import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  AreasServedRateAndConditionsModel,
  CareGiverModel, ServicesSkillsQualificationsList,
  ServicesSkillsQualificationsModel,
} from '@nx-c4g/c4g-models';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {CurrentLangService} from "../../services/current-lang.service";

@Component({
  selector: 'app-services-skills-and-qualifications',
  templateUrl: './services-skills-and-qualifications.component.html',
  styleUrls: ['./services-skills-and-qualifications.component.scss'],
})
export class ServicesSkillsAndQualificationsComponent
  implements OnInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;
  @Input() careGiver: CareGiverModel;
  @Output() careGiverChange = new EventEmitter<CareGiverModel>();

  onChange$: any;
  isSubmitTwo = false;
  serviceSkills: ServicesSkillsQualificationsModel;
  areasServed: AreasServedRateAndConditionsModel;

  reference: FormControl;
  backgroundChecked: FormControl;
  backgroundCheckNumber: FormControl;
  backgroundCheckDate: FormControl;


  constructor(
    public lang: CurrentLangService
  ) {
  }

  ngOnInit(): void {


    if (!this.careGiver.AreasServedRateAndConditions) {
      this.careGiver.AreasServedRateAndConditions = new AreasServedRateAndConditionsModel();
    }
    if (!this.careGiver.ServicesSkillsQualifications) {
      this.careGiver.ServicesSkillsQualifications = new ServicesSkillsQualificationsModel();
    }

    if (!this.careGiver.AreasServedRateAndConditions.CGSpecialInterests) {
      this.careGiver.AreasServedRateAndConditions.CGSpecialInterests = [];
    }
    if (!this.careGiver.AreasServedRateAndConditions.CGPetsCannotEnter) {
      this.careGiver.AreasServedRateAndConditions.CGPetsCannotEnter = [];
    }
    this.areasServed = this.careGiver.AreasServedRateAndConditions;
    this.serviceSkills = this.careGiver.ServicesSkillsQualifications;

    this.parentFormGroup.addControl(
      'HowManySeniorsHaveYouCaredForInTheLast24Months',
      new FormControl('None')
    );
    this.parentFormGroup.addControl(
      'DoYouHaveAHealthcareOrSocialCertification',
      new FormControl('')
    );
    this.parentFormGroup.addControl(
      'HealthcareAttendantNo',
      new FormControl('')
    );
    this.parentFormGroup.addControl(
      'OtherCertificationNo',
      new FormControl('')
    );
    this.parentFormGroup.addControl(
      'MedicationAdministrationservices',
      new FormControl('')
    );
    this.parentFormGroup.addControl(
      'PlanToOfferHygieneServices',
      new FormControl('')
    );
    this.parentFormGroup.addControl('OtherCertification', new FormControl(''));

    this.reference = new FormControl('');

    this.backgroundChecked = new FormControl(false);
    this.backgroundCheckNumber = new FormControl('');
    this.backgroundCheckDate = new FormControl('');


    this.parentFormGroup.addControl('Reference', this.reference);
    this.parentFormGroup.addControl(
      'hadBackgroundCheckDone',
      this.backgroundChecked
    );
    this.parentFormGroup.addControl(
      'backgroundFileNumber',
      this.backgroundCheckNumber
    );
    this.parentFormGroup.addControl(
      'backgroundCheckDate',
      this.backgroundCheckDate
    );

    this.initValues();
  }

  initValues() {
    if (this.careGiver) {
      this.parentFormGroup.patchValue(
        this.careGiver.ServicesSkillsQualifications
      );
      this.parentFormGroup.patchValue(this.careGiver);
      this.parentFormGroup.patchValue({
        MedicationAdministrationservices: this.careGiver
          .ServicesSkillsQualifications.medicationAdministrationservices
          ? 'Yes'
          : 'No',
        PlanToOfferHygieneServices: this.careGiver.ServicesSkillsQualifications
          .planToOfferHygieneServices
          ? 'Yes'
          : 'No',
      });
      this.serviceSkills = this.careGiver.ServicesSkillsQualifications;

      this.areasServed = this.careGiver.AreasServedRateAndConditions;
    }
    this.onChanges();
  }

  updateValue() {
    this.careGiver.HowManySeniorsHaveYouCaredForInTheLast24Months = this.parentFormGroup.controls.HowManySeniorsHaveYouCaredForInTheLast24Months.value;
    this.careGiver.DoYouHaveAHealthcareOrSocialCertification = this.parentFormGroup.controls.DoYouHaveAHealthcareOrSocialCertification.value;
    this.careGiver.HealthcareAttendantNo = this.parentFormGroup.controls.HealthcareAttendantNo.value;
    this.careGiver.OtherCertificationNo = this.parentFormGroup.controls.OtherCertificationNo.value;
    this.careGiver.ServicesSkillsQualifications.medicationAdministrationservices = this.parentFormGroup.controls.MedicationAdministrationservices.value;
    this.careGiver.ServicesSkillsQualifications.planToOfferHygieneServices = this.parentFormGroup.controls.PlanToOfferHygieneServices.value;
    this.careGiver.OtherCertification = this.parentFormGroup.controls.OtherCertification.value;

    this.careGiver.Reference = this.parentFormGroup.controls.Reference.value;

    this.careGiver.hadBackgroundCheckDone = this.parentFormGroup.controls.hadBackgroundCheckDone.value;
    this.careGiver.backgroundFileNumber = this.parentFormGroup.controls.backgroundFileNumber.value;

    this.careGiverChange.emit(this.careGiver);
  }

  private onChanges() {
    this.onChange$ = this.parentFormGroup.valueChanges.subscribe((val) => {
      this.updateValue();
    });
  }

  ngOnDestroy(): void {
    if (this.onChange$) {
      this.onChange$.unsubscribe();
    }
  }

  offerAdminServices(event) {
    this.serviceSkills.medicationAdministrationservices = event.value == 'Yes';
  }

  offerHygieneServices(event) {
    this.serviceSkills.planToOfferHygieneServices = event.value == 'Yes';
  }

  dateChange(
    change: string,
    $event: MatDatepickerInputEvent<unknown, unknown | null>
  ) {
    this.careGiver.backgroundCheckDate = new Date($event.value.toString());
  }

  languageSelected(event) {
    if (!this.careGiver.LanguagesSpoken) this.careGiver.LanguagesSpoken = [];
    if (event.checked) {
      this.careGiver.LanguagesSpoken.push(event.source.value);
    } else {
      this.careGiver.LanguagesSpoken = this.careGiver.LanguagesSpoken.filter(
        (l) => l != event.source.value
      );
    }
    console.log(this.careGiver.LanguagesSpoken);
  }

  get languages() {
    return ServicesSkillsQualificationsList.languages;
  }




  qualificationsOtherSelected(event) {
    if (!this.careGiver.ServicesSkillsQualifications.qualificationsOther) this.careGiver.ServicesSkillsQualifications.qualificationsOther = [];
    if (event.checked) {
      this.careGiver.ServicesSkillsQualifications.qualificationsOther.push(event.source.value);
    } else {
      this.careGiver.ServicesSkillsQualifications.qualificationsOther = this.careGiver.ServicesSkillsQualifications.qualificationsOther.filter(
        (l) => l != event.source.value
      );
    }
    console.log(this.careGiver.ServicesSkillsQualifications.qualificationsOther);
  }

  get qualificationsOther() {
    return ServicesSkillsQualificationsList.qualificationsOther;
  }

  servicesSelected(event) {
    if (!this.careGiver.ServicesSkillsQualifications.ServicesSkills) this.careGiver.ServicesSkillsQualifications.ServicesSkills = [];
    if (event.checked) {
      this.careGiver.ServicesSkillsQualifications.ServicesSkills.push(event.source.value);
    } else {
      this.careGiver.ServicesSkillsQualifications.ServicesSkills = this.careGiver.ServicesSkillsQualifications.ServicesSkills.filter(
        (l) => l != event.source.value
      );
    }
    console.log(this.careGiver.ServicesSkillsQualifications.ServicesSkills);
  }

  get services() {
    return ServicesSkillsQualificationsList.services;
  }

  skillsSelected(event) {
    if (!this.careGiver.ServicesSkillsQualifications.ServicesSkills) this.careGiver.ServicesSkillsQualifications.ServicesSkills = [];
    if (event.checked) {
      this.careGiver.ServicesSkillsQualifications.ServicesSkills.push(event.source.value);
    } else {
      this.careGiver.ServicesSkillsQualifications.ServicesSkills = this.careGiver.ServicesSkillsQualifications.ServicesSkills.filter(
        (l) => l != event.source.value
      );
    }
    console.log(this.careGiver.ServicesSkillsQualifications.ServicesSkills);
  }

  get skills() {
    return ServicesSkillsQualificationsList.skills;
  }



  getQualificationOtherSelected(value: string) {
    return this.serviceSkills.qualificationsOther?.includes(value);
  }

  getServiceSelected(value: string) {
    return this.serviceSkills.ServicesSkills?.includes(value);
  }

  getSkillSelected(value: string) {
    return this.serviceSkills.ServicesSkills?.includes(value);
  }

  getLanguageSelected(value: string) {
    return this.careGiver.LanguagesSpoken?.includes(value);
  }
}
