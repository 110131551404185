export class TaxeModel {
  code = '';
  name = '';
  type = '';
  tax = 0;
}

export enum taxesTypes {
  'GST' = 'GST',
  'PST' = 'PST',
  'HST' = 'HST',
  'QST' = 'QST',
}
