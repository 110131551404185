import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCropperDialog } from '../dialogs/image-cropper/image-cropper-dialog.component';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss'],
})
export class PhotoEditorComponent implements OnInit {
  @Input() photo: string;
  @Output() photoChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  handleFileInput($event: Event) {
    const dialogRef = this.dialog.open(ImageCropperDialog, {
      disableClose: true,
      width: '100%',
      data: {
        event: $event,
      },
    });
    dialogRef.afterClosed().subscribe((base64: ImgCropperEvent) => {
      if (base64) {
        this.photo = base64.dataURL;
        this.photoChange.emit(base64.dataURL);
      }
    });
  }
}
