<h1 mat-dialog-title>Select Availability Range</h1>
<div mat-dialog-content fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="row">
  <mat-form-field appearance="outline"[floatLabel]="'always'"  class="mat-select form-field">
    <mat-label class="floating-label">{{
      'Availabilities.day' | translate
      }}</mat-label>
    <mat-select [(ngModel)]="weeklyAvailability.day"
    >
      <mat-option *ngFor="let item of getDaysEnum()" [value]="item">
        {{item | translate}}
      </mat-option>
    </mat-select>

  </mat-form-field>

  <mat-form-field appearance="outline"  [floatLabel]="'always'" class="form-field">
    <mat-label class="floating-label">{{
      'Availabilities.start' | translate
      }}</mat-label>
    <input
      #startTime="ngModel"
      matInput
      [(ngModel)]="weeklyAvailability.startTime"
      [ngModelOptions]="{updateOn: 'blur'}" c4gTimeValidator
    />
    <mat-error *ngIf="startTime.invalid">{{'Availabilities.invalidTime' | translate}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline"  [floatLabel]="'always'" class="form-field">
    <mat-label class="floating-label">{{
      'Availabilities.end' | translate
      }}</mat-label>
    <input
      #endTime="ngModel"
      matInput
      [(ngModel)]="weeklyAvailability.endTime"
      [ngModelOptions]="{updateOn: 'blur'}" c4gTimeValidator
    />
    <mat-error *ngIf="endTime.invalid">{{'Availabilities.invalidTime' | translate}}</mat-error>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <mat-error *ngIf="!validForm()">{{'Availabilities.allTimeError' | translate}}</mat-error>

  <button mat-button mat-dialog-close="" (click)="reject()">Cancel</button>
  <button mat-raised-button color="primary" mat-dialog-close=""
          (click)="accept()"
          [disabled]="!validForm()"
  >
    Save
  </button>
</div>
