import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollPositionService {
  pageSize = 12;
  page = 1;
  hasCGPosition = false;

  constructor() {}

  saveCGPosition(page) {
    this.page = page;
    this.hasCGPosition = true;
  }

  clearCGPositon() {
    this.hasCGPosition = false;
  }

  getCGPosition() {
    return this.page;
  }
}
