export enum CsOperationTypes {
  emtDeposit = 'transactionTypes.emtDeposit',
  ccDeposit = 'transactionTypes.ccDeposit',
  reserved = 'transactionTypes.reserved',
  adjustmentUnworked = 'transactionTypes.adjustmentUnworked',
  adjustmentRate = 'transactionTypes.adjustmentRate',
  adjustmentAdminTime = 'transactionTypes.adjustmentAdminTime',
  adjustmentApptEdit = 'transactionTypes.adjustmentApptEdit',
  payment = 'transactionTypes.payment',
  withdrawal = 'transactionTypes.withdrawal',
  refund = 'transactionTypes.refund',
  extensionRefund = 'transactionTypes.extensionRefund',
  autoRefund = 'transactionTypes.autoRefund',
  otPayment = 'transactionTypes.otPayment',
  refundUnworked = 'transactionTypes.refundUnworked',
  marginSet = 'transactionTypes.marginSet',
  marginAdjustment = 'transactionTypes.marginAdjustment',
  penalty = 'transactionTypes.penalty',
  extension = 'transactionTypes.extension',

  PaymentAdjustmentOvertime = 'transactionTypes.PaymentAdjustmentOvertime',
  PaymentAdjustmentBonus = 'transactionTypes.PaymentAdjustmentBonus',
  PaymentAdjustmentRefund = 'transactionTypes.PaymentAdjustmentRefund',
  PaymentAdjustmentCharge = 'transactionTypes.PaymentAdjustmentCharge',
  PaymentAdjustmentOvertimeRefund = 'transactionTypes.PaymentAdjustmentOvertimeRefund',
  PaymentAdjustmentBonusRefund = 'transactionTypes.PaymentAdjustmentBonusRefund',
  PaymentAdjustmentRefundCancelled = 'transactionTypes.PaymentAdjustmentRefundCancelled',
  PaymentAdjustmentChargeRefund = 'transactionTypes.PaymentAdjustmentChargeRefund',

}
