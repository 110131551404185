import {CareGiverModel} from "./careGiverModel";

export enum CareTeamStatus {
  invited = 'invited',
  confirmed = 'confirmed',
  declined = 'declined',
}

export enum CareTeamTiers {
  careTeam,
  substitutes,
  active,
  blacklisted,
  forInterview,
}

export class CareTeamModel {
  _id: string;
  team: CareTeamMember[];
}

export class CareTeamLogModel {
  _id: {id: string, date: Date};
  cgId: string;
  cgName: string;
  action: CareTeamLogAction;
}

export enum CareTeamLogAction {
  invited = 'CareTeamLogAction.invited',
  confirmed = 'CareTeamLogAction.confirmed',
  accepted = 'CareTeamLogAction.accepted',
  declined = 'CareTeamLogAction.declined',
  removed = 'CareTeamLogAction.removed',
  left = 'CareTeamLogAction.left',
}

export class CareTeamMember {
  id: string;
  name: string;
  tier: CareTeamTiers;
  notes: string;
  status: CareTeamStatus;
  careGiver?: CareGiverModel;
  patients: CareTeamPatient[] = [];
  disabled? = false;
}

export class CareTeamPatient {
  id: string;
  name: string;
}
