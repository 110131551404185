import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'nx-c4g-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.css'],
})
export class IncomingCallComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<IncomingCallComponent>) {}

  ngOnInit(): void {}

  accept() {
    this.dialogRef.close(true);
  }

  reject() {
    this.dialogRef.close(false);
  }
}
