<div fxLayout="column">
  <div class="outline" [class]="errorClass()">
    <c4g-address-display class="full-width" [processedAddress]="processedAddress" [canEdit]="true"
                         (editAddress)="openDialog(placeDialog)"></c4g-address-display>
  </div>
</div>

<ng-template #placeDialog>
  <mat-card fxLayout="column" class="full-width">
    <c4g-address-display class="full-width" [processedAddress]="tempAddress" [canEdit]="false"></c4g-address-display>

    <div fxLayout="row wrap" fxLayoutGap="8px">
      <mat-form-field class="example-full-width">
        <mat-label>{{"forms.searchCity" | translate}}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]="citySearch"
               name="name" ngDefaultControl
               [matAutocomplete]="city">
        <mat-autocomplete autoActiveFirstOption #city="matAutocomplete"
                          name="name" ngDefaultControl
                          [(ngModel)]="selectedCity" (optionSelected)="onCitySearchSelect($event)">
          <mat-option *ngFor="let city of filteredCities()" [value]="city">
            {{city.name | uppercase}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{"forms.country" | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedCountry" (selectionChange)="onCountrySelect($event.value)">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country.name | uppercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="selectedCountry">
        <mat-label>{{"forms.state" | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedState" (selectionChange)="onStateSelect($event.value)">
          <mat-option *ngFor="let state of selectedCountry.states" [value]="state">
            {{ state.name | uppercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="selectedState">
        <mat-label>{{"forms.city" | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedCity" (selectionChange)="onCitySelect($event.value)">
          <mat-option *ngFor="let city of selectedState.cities" [value]="city">
            {{ city.name | uppercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{"forms.address" | translate}}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]="tempAddress.Street"
               (ngModelChange)="onStreetChange($event)"
               name="name" ngDefaultControl>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{"forms.address2" | translate}}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]="tempAddress.Line3"
               name="name" ngDefaultControl>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{"forms.postalCode" | translate}}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]="tempAddress.PostalCode"
               (ngModelChange)="onPostalCodeChange($event)"
               name="name" ngDefaultControl>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="locate(tempAddress)">
        {{"buttons.locate" | translate}}
      </button>
      <p class="label">{{"forms.locate" | translate}}</p>
      <div *ngIf="geoEnabled" #mapContainer class="map-container">
        <mat-spinner *ngIf="!mapLoaded"></mat-spinner>
      </div>
      <div *ngIf="!geoEnabled" class="map-container center-error" fxLayout="column">You must enable Geo Location to use
        this
        application. The application will NEVER track you, we just need proper coordinates to provide the services at the
        right location. <br><br><b>Please Enable Geo Location and REFRESH the application.</b>
      </div>
    </div>
    <mat-card-footer fxLayoutGap="8px" class="mt-3">
      <button mat-raised-button color="primary" (click)="save()"
              [disabled]="hasErrors(tempAddress)"
      >
        {{"buttons.save" | translate}}
      </button>
      <button mat-raised-button color="warn" (click)="cancel()">{{"buttons.cancel" | translate}}</button>
    </mat-card-footer>
  </mat-card>

</ng-template>


