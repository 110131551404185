import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TestDataServiceService {
  constructor(private http: HttpClient) {}

  async testDataService(): Promise<boolean> {
    try {
      const testResult = await firstValueFrom(
        this.http.get(environment.api, {responseType: 'text'}),
      );
      console.log(`testDataService, ${testResult}`)
      if (testResult && testResult.startsWith('Environment')) {
        console.log('Test passed', testResult)
        return true;
      } else {
        console.log('Test failed', testResult)
        return false;
      }
    } catch (e) {
      console.log(`testDataService, ${e['message']}`)
      return false;
    }

  }
}
