import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateUtil } from '@nx-c4g/c4g-models';

@Component({
  selector: 'nx-c4g-weeks-navigator',
  templateUrl: './weeks-navigator.component.html',
  styleUrls: ['./weeks-navigator.component.scss'],
})
export class WeeksNavigatorComponent implements OnInit {
  // @Input() weeks!: Date[];
  @Output() onCurrentWeekChanged: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() currentWeek: Date = null;
  @Input() futureOnly = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.currentWeek) {
      this.setCurrentWeek(new Date());
    } else {
      this.setCurrentWeek(this.currentWeek);
    }
  }

  setCurrentWeek(date: Date) {
    this.currentWeek = DateUtil.firstDayOfWeek(date);
    this.onCurrentWeekChanged.emit(this.currentWeek);
  }

  firstDay(date: Date): Date {
    return DateUtil.firstDayOfWeek(date);
  }

  lastDay(date: Date): Date {
    return DateUtil.lastDayOfWeek(date);
  }

  prev() {
    this.currentWeek = DateUtil.prevWeek(this.currentWeek);
    this.onCurrentWeekChanged.emit(this.currentWeek);
  }

  next() {
    this.currentWeek = DateUtil.nextWeek(this.currentWeek);
    this.onCurrentWeekChanged.emit(this.currentWeek);
  }

  hasPrevWeek(): boolean {
    if (this.futureOnly) {
      return this.currentWeek > DateUtil.firstDayOfWeek(DateUtil.addDays(new Date(), 7));
    } else {
      return true;
    }
  }

  hasNextWeek(): boolean {
    return true;
  }
}
