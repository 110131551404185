<div style="background-color: whitesmoke">
  <div class="lo-card">

    <div class="main-layout">
      <div class="lo-header">
        <mat-card-title *ngIf="!isResidence">{{ name }}</mat-card-title>
        <mat-card-title *ngIf="isResidence">{{
          info.LegalBusinessName
          }}</mat-card-title>

        <mat-card-subtitle>
          <mat-icon>place</mat-icon>
          <a class="address" [href]="mapsrc" target="_blank">{{ info.Address?.Street }}
            , {{ info.Address?.City }}          </a>
        </mat-card-subtitle
        >
      </div>

      <div class="main-layout-content">
        <div class="card-content">
          <c4g-lo-need
            [jsonElement]="needs"
            [notes]="notes"
            [typeOfCareAndNotes]="lo.TypeOfCareAndNotes"
            [assessment]="lo.careNeedsAssessmentModel"
            *ngIf="!isResidence"
          ></c4g-lo-need>
          <ng-container *ngIf="isResidence">
            <div>{{ info.AccountingContactName }}</div>
            <div>{{ info.AccountingContactPhone }}</div>
            <div>{{ info.AccountingContactEmail }}</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="left">
        <button mat-button (click)="closeDialog()">Close</button>
      </div>
      <div class="right" *ngIf="loaded && bookingId">
        <button mat-button color="primary" (click)="accept()">Accept</button>
        <button mat-button color="warn" (click)="reject()">Reject</button>
      </div>
    </div>
  </div>
</div>
