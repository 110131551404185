import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CaregiverProfileService } from '../../services/profile/caregiver-profile.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { tap } from 'rxjs/operators';
import {
  AppointmentModel,
  AppointmentStatusModel,
  BookingUtil,
  DateUtil,
} from '@nx-c4g/c4g-models';
import { Geolocation, PermissionStatus } from '@capacitor/geolocation';
import { MapsLoaderService, notify } from '@nx-c4g/c4g-ui';
import { BookingService } from '../../services/booking/booking.service';

@Component({
  selector: 'nx-c4g-check-ins',
  templateUrl: './check-in-out.component.html',
  styleUrls: ['./check-in-out.component.css'],
})
export class CheckInOutComponent implements OnInit {
  @ViewChild('googleMap') public agmMap: google.maps.Map;

  darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
  isDarkMode = this.darkModeQuery.matches;
  mapOptions: google.maps.MapOptions = {
    clickableIcons: false,
    disableDefaultUI: true,
    controlSize: null,
    disableDoubleClickZoom: true,
    draggable: false,
    fullscreenControl: false,
    panControl: false,
    center: { lat: 40, lng: -20 },
    zoom: 4,
    styles: this.isDarkMode
      ? [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#242f3e' }],
          },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ]
      : [],
  };
  address: string;
  private geoCoder;

  schedule: AppointmentModel[] = [];
  geoEnable = true;
  selectedDate = new Date();
  mapInit = false;
  marker: google.maps.Marker;

  constructor(
    private caregiverProfileService: CaregiverProfileService,
    private authenticationService: AuthenticationService,
    private mapsLoaderService: MapsLoaderService,

    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.mapsLoaderService
      .load()
      .then(async () => {
        this.mapInit = true;
        console.log('Google Maps API loaded successfully');
      })
      .catch((err) => {
        this.mapInit = false;
        console.error('Failed to load Google Maps API', err);
      });
    this.reload();
  }

  private async setCurrentLocation() {
    let perm = await Geolocation.checkPermissions();
    console.log('Perm', perm);
    // if (perm !== PermissionStatus['granted']) {
    //   await Geolocation.requestPermissions();
    // }

    const position = await Geolocation.getCurrentPosition({
      timeout: 10000,
      enableHighAccuracy: true,
      maximumAge: 10000,
    });
    this.mapOptions.center.lat = position.coords.latitude;
    this.mapOptions.center.lng = position.coords.longitude;
    this.mapOptions.zoom = 18;
    this.getAddress(this.mapOptions.center.lat, this.mapOptions.center.lng);
    this.geoEnable = true;
    console.log('Set Current Position', this.mapOptions.center);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.mapOptions.zoom = 16;
            this.address = results[0].formatted_address;
            this.geoEnable = true;
          } else {
            this.geoEnable = false;
          }
        } else {
          this.geoEnable = false;
        }
      },
    );
  }

  reload() {
    this.authenticationService
      .isLoggedIn()
      .pipe(
        tap((user) => {
          this.caregiverProfileService
            .getAvailability(
              user.uid,
              DateUtil.addDays(this.selectedDate, -1),
              DateUtil.addDays(this.selectedDate, 1),
            )
            .subscribe((avail) => {
              this.schedule =
                avail && avail.length > 0
                  ? BookingUtil.horaireToAppointements(avail)
                  : [];
              this.schedule = this.schedule.filter(
                (appt) =>
                  (BookingUtil.isAtDate(appt.startDate, this.selectedDate) ||
                    BookingUtil.isAtDate(appt.endDate, this.selectedDate)) &&
                  appt.status === AppointmentStatusModel.careBookEntryBooked,
              );
              console.log('schedule', this.schedule);
              // for (let appt of this.schedule) {
              //   this.bookingService
              //     .getBooking(appt._id)
              //     .then((booking: BookingModel[]) => {
              //       console.log('Booking', booking);
              //       appt.checkInOut = booking[0].appointments[0].checkInOut;
              //     });
              // }
            });
        }),
      )
      .subscribe();
  }

  mapReady(map: google.maps.Map) {
    console.log('google.maps init');
    this.geoEnable = true;

    this.geoCoder = new google.maps.Geocoder();
    this.setCurrentLocation().then(
      (r) => {
        console.log('google.maps setCurrentLocation', this.mapOptions.center);
        if (!this.marker) {
          this.marker = new google.maps.Marker({
            position: this.mapOptions.center,
            map: map,
            title: 'Got you!',
          });
        } else {
          this.marker.setPosition(this.mapOptions.center);
        }
        map.panTo(this.mapOptions.center);
        map.setZoom(this.mapOptions.zoom);
        this.mapInit = true;
        this.cd.detectChanges();
      },
      (error) => {
        this.geoEnable = false;
      },
    );
  }
}
