import { CareTeamMember, CareTeamTiers } from "../CareTeamModel";

export interface MeetModel {
  _id: string;
  type: MeetTypesModel;
  startDate: Date;
  endDate: Date;
  careGiverId?: string;
  caregiverName?: string;
  careSeekerId?: string;
  careseekerName?: string;
  tier?: CareTeamTiers;
}

export interface MeetRequestModel {
  _id: string;
  csId?: string;
  csName: string;
  fav: CareTeamMember;
  timeSlots?: MeetModel[];
  selectedMeet?: MeetModel;
  noDates?: boolean;
  declined?: boolean;
}

export enum MeetTypesModel {
  available = 'available', // This is an available time slot
  pending = 'pending', // This is a pending time slot
  booked = 'booked', // This is a booked time slot
  completed = 'completed', // This is a completed time slot

}
