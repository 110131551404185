import { NotifyService } from "./notify.service";

let _notificationService: NotifyService;

export function registerNotificationService(service: NotifyService) {
  _notificationService = service;
}

export function notify(message: string, type: 'info' | 'warning' | 'error' = 'info', delay = 2000) {
  _notificationService.emit(message, type, delay);
}
