import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'c4g-chip',
  templateUrl: './c4g-chip.component.html',
  styleUrls: ['./c4g-chip.component.scss'],
})
export class C4gChipComponent {
  @Input() canDelete = false;
  @Output() delete = new EventEmitter<any>();

  constructor() {}


  onDelete() {
    this.delete.emit();
  }
}
