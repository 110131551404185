<span fxLayout="row" class="address-row">
  <span fxLayout="column">
    <span fxLayout="row">
      <span [className]="addressError(this.processedAddress?.Street)">{{genAddressLine1()}}</span>,&nbsp;
      <span *ngIf="this.processedAddress?.Line3">{{processedAddress.Line3}},&nbsp;</span>
      <span [className]="addressError(this.processedAddress?.City)">{{genAddressCity()}}</span>
    </span>
    <span fxLayout="row wrap">
      <span
        [className]="addressError(this.processedAddress?.stateOrProvinceCode)">{{genAddressStateOrProvince()}}
        ({{genAddressStateOrProvinceCode()}})</span>,&nbsp;
      <span [className]="addressError(this.processedAddress?.countryCode)">{{genAddressCountry()}}
        ({{genAddressCountryCode()}})</span>,&nbsp;
      <span
        [className]="postalCodeError(this.processedAddress?.PostalCode, this.processedAddress?.countryCode)">{{genAddressPostalCode()}}</span>
    </span>
    <span fxLayout="row wrap" class="mini">
      <span [className]="addressError(this.processedAddress?.Latitude?.toString())">{{genAddressLat()}}</span>,&nbsp;
      <span [className]="addressError(this.processedAddress?.Longitude?.toString())">{{genAddressLng()}}</span>,&nbsp;
      <span [className]="tzError(this.processedAddress?.timeZone)">TZ: {{genAddressTz()}}</span>
    </span>
  </span>
  <button
    (click)="emitEditAddress()"
    *ngIf="canEdit"
    mat-icon-button
    matSuffix
    type="button"
    class="text-muted"
  >
  <mat-icon>edit</mat-icon>
  </button>
</span>


