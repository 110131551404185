import {Type} from "class-transformer";
import {NameModel} from "./NameModel";
import {AddressModel} from "./AddressModel";
import {PleaseUploadARecentPhotoOfYourselfEntityModel} from "./PleaseUploadARecentPhotoOfYourselfEntityModel";
import {
  AreasServedRateAndConditionsModel,
  AreasServedRateAndConditionsSmallModel
} from "./AreasServedRateAndConditionsModel";
import {ServicesSkillsQualificationsModel} from "./ServicesSkillsQualificationsModel";
import {LocationModel} from "./LocationModel";
import {DistanceModel} from "./DistanceModel";
import {AppointmentModel} from "./appointmentModel";
import { LoRates } from "./CgRates";

export enum CareGiverTypeEnum {
  'RN' = 'RN',
  'LPN' = 'LPN',
  'PAB' = 'PAB',
  'PSW' = 'PSW',
  'APRN' = 'APRN',
  'OTHER' = 'OTHER',
}

export enum ApprovalEnum {
  'PENDING' = 'PENDING',
  'APPROVED' = 'APPROVED',
  'REJECTED' = 'REJECTED',
}

export enum DaysEnum {
  'SUN' = 'days.sunday',
  'MON' = 'days.monday',
  'TUE' = 'days.tuesday',
  'WED' = 'days.wednesday',
  'THU' = 'days.thursday',
  'FRI' = 'days.friday',
  'SAT' = 'days.saturday',
}


export class CareGiverExportModel {
  _id: string;
  Address: AddressModel = new AddressModel();
  AreasServedRateAndConditions: AreasServedRateAndConditionsSmallModel;
  Email: string;
  Phone: string;
  Name: NameModel;
  adminNotes: string;
  caregiverType?: CareGiverTypeEnum;
  MedicationAdministrationservices: string;
  PlanToOfferHygieneServices: string;
  SpecialInterests: string;
  Skills: string;
  Languages: string;
  marketingProfile: string;
  availabilitiesUpdatedTimestamp: Date;
}

export class WeeklyAvailabilitiesRestModel {
  weeklyAvailabilities: WeeklyAvailabilitiesModel[];
  weeklyAvailabilitiesTimeZone: string;
}

export class WeeklyAvailabilitiesModel {
  day: DaysEnum;
  startTime: string;
  searchStartTime: string;

  endTime: string;
  searchEndTime: string;

  endDay: DaysEnum;
}

export class CareGiverModel {


  lastMinuteCancellationCount = 0;
  // AreYouVaccinatedCovid19: boolean;
  // Doses: number;
  Reference: string;
  _id: string;
  Name: NameModel;
  Phone: string;
  Email: string;
  Address: AddressModel = new AddressModel();
  PleaseUploadARecentPhotoOfYourself?:
    | PleaseUploadARecentPhotoOfYourselfEntityModel[]
    | null;
  // CGCertifications?: (string)[] | null;
  OtherCertification: string;
  CGNurseCertificateNo: string;
  HealthcareAttendantNo: string;
  RnNo: string;
  CPROthertCertification?: null;
  LanguagesSpoken: string[] = [];
  AreasServedRateAndConditions: AreasServedRateAndConditionsModel;
  ServicesSkillsQualifications: ServicesSkillsQualificationsModel;
  DoYouHaveAHealthcareOrSocialCertification: string;
  HowManySeniorsHaveYouCaredForInTheLast24Months: string;
  Userid: string;
  UserCode: string;
  OtherCertificationNo: string;
  Provca: string;
  OtherLanguages: string;
  Id: string;
  location: LocationModel;
  distance: DistanceModel;
  calculatedRate: number;
  pabResidenceRate: number;
  aprnResidenceRate: number;
  loRates: LoRates[] = [];

  pswResidenceRate: number;
  lpnResidenceRate: number;
  rnResidenceRate: number;
  federalTaxNumber: string;
  provincialTaxNumber: string;
  adminNotes: string;

  // ICONS
  sterlingBackgroundCheck?: boolean;
  hadBackgroundCheckDone?: boolean;
  @Type(() => Date)
  backgroundCheckDate?: Date;
  backgroundFileNumber?: string;

  hasCPR = false;
  hasFirstAid = false;
  // END ICONS

  residence?: boolean;
  residenceApproved?: ApprovalEnum;
  caregiverType?: CareGiverTypeEnum;

  avgRating: number;
  ratingCount: number;
  ratings: CareGiverRating[];
  devices: string[] = [];
  lastUpdated: Date = new Date();

  marketingProfile: string;

  // Managing Weekly Availabilities
  weeklyAvailabilities: WeeklyAvailabilitiesModel[] = [];
  weeklyAvailabilitiesTimeZone: string;
  availabilitiesUpdatedTimestamp: Date;
  disabled?: boolean = false;

  rateChangeLog: RateChangeLog[] = [];
  loRateChangeLog: LoRateChangeLog[] = [];

  constructor() {
    this.Name = new NameModel();
    this.Address = new AddressModel();
    this.AreasServedRateAndConditions = new AreasServedRateAndConditionsModel();
    this.ServicesSkillsQualifications = new ServicesSkillsQualificationsModel();
    this.location = new LocationModel();
    this.distance = new DistanceModel();
  }
}

export interface LoRateChangeLog {
  date: Date;
  previousLoRate?: LoRates; // Null for new LoRate
  newLoRate: LoRates;
}
export interface RateChangeLog {
  date: Date;
  previousRate?: number; // Null for new Rate
  newRate: number;
}

export class CareGiverRating {
  careSeekerName: string;
  careSeekerId: string;
  overall: number;
  reliability: number;
  quality: number;
  interactions: number;
  @Type(() => Date)
  createDate: Date;
  comment: string;
  appointmentId: string;
}

export class CgAvailabilitiesModel {
  _id: string;
  id: string;
  @Type(() => AppointmentModel)
  schedule: AppointmentModel[] = [];
}

export class CgInWorld {
  count: number;
  zipZone: string;
  countryCode: string;

  coordinates?: {
    lat: number;
    lng: number;
  };
}
