<div class="theme-container">
  <div class="container-panel">
    <h3>{{"meet.invitation" | translate}}</h3>
    <ng-container *ngIf="meetRequest">
      <h4>{{"meet.from" | translate}}: {{meetRequest.csName}}</h4>
      <ng-container *ngIf="meetRequest.timeSlots && !selectedMeet && !noDatesChoice && !declineChoice">
        <ng-container *ngFor="let avail of meetRequest.timeSlots">
          <nx-c4g-meet-appointment-summary-template
            [appt]="avail"
            (selectAppt)="selected(avail)"
            [showButton]="true"
          >
          </nx-c4g-meet-appointment-summary-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedMeet">
        <h5>{{"meet.selection" | translate}}</h5>
        <ng-container *ngIf="!noDatesChoice && !declineChoice">
          <nx-c4g-meet-appointment-summary-template [appt]="selectedMeet" [showButton]="false">
          </nx-c4g-meet-appointment-summary-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="noDatesChoice">
        <h6 class="warning">{{"meet.no-date" | translate}}</h6>
      </ng-container>
      <ng-container *ngIf="declineChoice">
        <h6 class="warning">{{"meet.declined" | translate}}</h6>
      </ng-container>
    </ng-container>

    <div *ngIf="!selectedMeet && !noDatesChoice && !declineChoice" fxLayout="row" fxLayoutGap="12px" class="mt-3">
      <button mat-raised-button color="accent" (click)="noDates()">{{"meet.no-dates" | translate}}</button>
      <button mat-raised-button color="warn" (click)="decline()">{{"meet.decline" | translate}}</button>
    </div>
  </div>
</div>
