<footer>
  <mat-grid-list [cols]="calcCols()" *ngIf="authenticated | async"
                 rowHeight="58px">
    <mat-grid-tile routerLinkActive="horizontal-active-link"
                   [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/']">
      <div fxLayout="column" class="mt-3"
           fxLayoutAlign="center center">
        <mat-icon class="example-tab-icon">home</mat-icon>
        <div class="nav-label">{{ 'Menu.Home' | translate }}</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile routerLinkActive="horizontal-active-link"
                   [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/teams']">
      <div fxLayout="column" class="mt-3"
           fxLayoutAlign="center center">
        <mat-icon class="example-tab-icon">diversity_1</mat-icon>
        <div class="nav-label">{{ 'Menu.TeamsShort' | translate }}</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile routerLinkActive="horizontal-active-link"
                   [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/extensions']">
      <div fxLayout="column" class="mt-3"
           fxLayoutAlign="center center">
        <mat-icon class="example-tab-icon" [matBadge]="extensions?.length + invitationCount" matBadgeColor="warn" [matBadgeHidden]="extensions?.length + invitationCount === 0">rsvp</mat-icon>
        <div class="nav-label">{{ 'Menu.invitationsShort' | translate }}</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile routerLinkActive="horizontal-active-link"
                   [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/care-givers/me']">
      <div fxLayout="column" class="mt-3"
           fxLayoutAlign="center center">
        <mat-icon class="example-tab-icon">person</mat-icon>
        <div class="nav-label">{{ 'Menu.Profile' | translate }}</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile routerLinkActive="horizontal-active-link"
                   [routerLink]="['/agenda-view']"
                   [routerLinkActiveOptions]="{ exact: true }">
      <div fxLayout="column" class="mt-3"
           fxLayoutAlign="center center">
        <mat-icon class="example-tab-icon">calendar_today</mat-icon>
        <div class="nav-label">{{ 'Menu.Agenda' | translate }}</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="!isMobile()"
                   routerLinkActive="horizontal-active-link"
                   [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/availabilities']">
      <div fxLayout="column" class="mt-3"
           fxLayoutAlign="center center">
        <mat-icon class="example-tab-icon">browse_gallery</mat-icon>
        <div class="nav-label">{{ 'Menu.Availabilities' | translate }}</div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       class="copyright pr-3 pl-3">
    <div [class.xs]="'mt-1'">Copyright © {{ today | date: 'yyy' }} All Rights Reserved</div>
    <a (click)="tos()"
       [class.xs]="'mt-1'">{{"tos" | translate}}</a>
    <div>{{version}}</div>
  </div>
</footer>
