import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  AddressModel,
  AreasServedRateAndConditionsModel,
  CareGiverModel,
  CareGiverTypeEnum,
  NameModel,
  ServicesSkillsQualificationsList
} from "@nx-c4g/c4g-models";
import { debounceTime } from "rxjs";

@Component({
  selector: 'c4g-caregiver-basic-info',
  templateUrl: './caregiver-basic-info.component.html',
  styleUrls: ['./caregiver-basic-info.component.scss'],
})
export class CaregiverBasicInfoComponent implements OnInit, OnDestroy {
  @Input() careGiver: CareGiverModel | undefined;
  @Output() careGiverChange = new EventEmitter<CareGiverModel>();
  @Input() parentFormGroup: FormGroup | undefined;
  @Input() lang: string | undefined;

  onChange$: any;


  get qualificationsStandard() {
    return ServicesSkillsQualificationsList.qualificationsStandard;
  }

  ngOnInit() {
    this.parentFormGroup?.addControl(
      'CaregiverType',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'Residence',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'First',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'Last',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'Phone',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'CGHourlyRate',
      new FormControl(0, Validators.required)
    );
    this.parentFormGroup?.addControl('calculatedRate', new FormControl(0));
    this.parentFormGroup?.addControl(
      'City',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'PostalCode',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'Country',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl(
      'Line1',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl('Line2', new FormControl(''));
    this.parentFormGroup?.addControl(
      'timeZone',
      new FormControl('', Validators.required)
    );
    this.parentFormGroup?.addControl('Longitude', new FormControl(''));
    this.parentFormGroup?.addControl('Latitude', new FormControl(''));
    this.parentFormGroup?.addControl(
      'Province',
      new FormControl('', Validators.required)
    );

    this.initValues();
    // this.onChanges();

    this.parentFormGroup?.controls['PostalCode'].valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      const uppercaseValue = value.toUpperCase();
      console.log('PostalCode changed: ', value);
      if (uppercaseValue !== value) {
        this.parentFormGroup?.controls['PostalCode'].patchValue(value.toUpperCase());
      }
    });
    console.log('CaregiverBasicInfoComponent: ngOnInit');
  }

  careGiverNameInit(): NameModel {
    return {
      First: '',
      FirstAndLast: '',
      Last: '',
    };
  }

  careGiverRateInit(): AreasServedRateAndConditionsModel {
    return {
      CGHourlyRate: 15,
      CGSmokersHouse: '',
      CGTrainedOutOfBed: '',
      CGBio: '',
      ServiceRadius: 25,
      KmMiles: 'Km',
      ZonesYouCannotServe: false,
      ShortestBookingYouAreWillingToAccept: '',
      OtherInterests: '',
      CGSpecialInterests: [],
      CGPetsCannotEnter: [],
    };
  }

  careGiverAddressInit(): AddressModel {
    return new AddressModel();
  }

  initValues() {
    if (this.careGiver) {
      if (!this.careGiver.Name) {
        this.careGiver.Name = this.careGiverNameInit();
      }
      if (!this.careGiver.AreasServedRateAndConditions) {
        this.careGiver.AreasServedRateAndConditions = this.careGiverRateInit();
      }
      if (!this.careGiver.residence) {
        this.careGiver.residence = false;
      }
      if (!this.careGiver.caregiverType) {
        this.careGiver.caregiverType = CareGiverTypeEnum.OTHER;
      }

      this.parentFormGroup?.controls['CaregiverType'].setValue(
        this.careGiver.caregiverType
      );
      this.parentFormGroup?.controls['Residence'].setValue(
        this.careGiver.residence
      );

      this.parentFormGroup?.controls['First'].setValue(this.careGiver.Name?.First);
      this.parentFormGroup?.controls['Last'].setValue(this.careGiver.Name?.Last);
      this.parentFormGroup?.controls['Phone'].setValue(this.careGiver.Phone);
      this.parentFormGroup?.controls['CGHourlyRate'].setValue(
        this.careGiver.AreasServedRateAndConditions?.CGHourlyRate
      );
      this.parentFormGroup?.controls['calculatedRate'].setValue(
        this.careGiver.calculatedRate
      );

      if (!this.careGiver.Address) {
        this.careGiver.Address = this.careGiverAddressInit();
      }
      this.parentFormGroup?.controls['City'].setValue(this.careGiver.Address?.City);
      this.parentFormGroup?.controls['PostalCode'].patchValue(
        this.careGiver.Address?.PostalCode
      );
      this.parentFormGroup?.controls['Country'].setValue(
        this.careGiver.Address?.Country
      );
      this.parentFormGroup?.controls['Line1'].setValue(
        this.careGiver.Address?.Street
      );
      this.parentFormGroup?.controls['Line2'].setValue(
        this.careGiver.Address?.Line3
      );
      this.parentFormGroup?.controls['Province'].setValue(
        this.careGiver.Address?.State
      );
      this.parentFormGroup?.controls['Longitude'].setValue(
        this.careGiver.Address?.Longitude
      );
      this.parentFormGroup?.controls['Latitude'].setValue(
        this.careGiver.Address?.Latitude
      );
      this.parentFormGroup?.controls['timeZone'].setValue(
        this.careGiver.Address?.timeZone
      );
    }
  }

  async customAddress($event: AddressModel) {

    this.parentFormGroup?.controls['City'].setValue($event.City);
    this.parentFormGroup?.controls['PostalCode'].patchValue($event.PostalCode);
    this.parentFormGroup?.controls['Country'].setValue($event.Country);
    this.parentFormGroup?.controls['Line1'].setValue($event.Street);
    this.parentFormGroup?.controls['Line2'].setValue($event.Line3);
    this.parentFormGroup?.controls['Province'].setValue($event.State);
    this.parentFormGroup?.controls['Longitude'].setValue($event.Longitude);
    this.parentFormGroup?.controls['Latitude'].setValue($event.Latitude);
    this.parentFormGroup?.controls['timeZone'].setValue($event.timeZone);
    this.careGiver.Address = $event;

  }

  get CGHourlyRate() {
    return this.parentFormGroup?.get('CGHourlyRate');
  }

  updateValue() {
    if (this.careGiver) {
      console.log('CaregiverBasicInfoComponent: updateValue', this.careGiver.Address);
      this.careGiver.residence = this.parentFormGroup?.controls['Residence'].value;
      this.careGiver.caregiverType = this.parentFormGroup?.controls['CaregiverType'].value;

      this.careGiver.Name.First = this.parentFormGroup?.controls['First'].value;
      this.careGiver.Name.Last = this.parentFormGroup?.controls['Last'].value;
      this.careGiver.Phone = this.parentFormGroup?.controls['Phone'].value;

      this.careGiver.Name.FirstAndLast = `${this.parentFormGroup?.controls['First'].value} ${this.parentFormGroup?.controls['Last'].value}`;
      this.careGiver.AreasServedRateAndConditions.CGHourlyRate = this.parentFormGroup?.controls['CGHourlyRate'].value;

      // IMPORTANT: RATES
      this.careGiver.calculatedRate =
        this.careGiver.AreasServedRateAndConditions.CGHourlyRate + 5;

      this.careGiver.Address.Street = this.parentFormGroup?.controls['Line1'].value;
      this.careGiver.Address.Line3 = this.parentFormGroup?.controls['Line2'].value;
      this.careGiver.Address.Latitude = this.parentFormGroup?.controls['Latitude'].value;
      this.careGiver.Address.Longitude = this.parentFormGroup?.controls['Longitude'].value;

      this.careGiver.Address.PostalCode = this.parentFormGroup?.controls['PostalCode'].value;
      this.careGiver.Address.City = this.parentFormGroup?.controls['City'].value;
      this.careGiver.Address.Country = this.parentFormGroup?.controls['Country'].value;
      this.careGiver.Address.State = this.parentFormGroup?.controls['Province'].value;
      this.careGiver.Address.timeZone = this.parentFormGroup?.controls['timeZone'].value;
      this.careGiver.Address.CityStatePostalCode = `${this.careGiver.Address.City} ${this.careGiver.Address.PostalCode}`;
      this.careGiverChange.emit(this.careGiver);
    }
  }

  // private onChanges() {
  //   this.onChange$ = this.parentFormGroup?.valueChanges.subscribe((val) => {
  //     this.updateValue();
  //   });
  // }

  ngOnDestroy(): void {
    if (this.onChange$) {
      this.onChange$.unsubscribe();
    }
  }

  // IMPORTANT: RATES

  getCalculatedRate(value: any) {
    return value + 5;
  }
}
