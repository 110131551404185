<div class="photo-wrapper" fxLayout="column">
  <h2>{{ 'photoEditor.heading' | translate }}</h2>
  <img
    [src]="photo ? photo : '../../../assets/images/avatars/avatar.png'"
    (click)="hiddenfileinput.click()"
  />
  <div class="form-group">
    <input
      style="display: none"
      type="file"
      id="file"
      accept=".png,.jpg"
      (change)="handleFileInput($event)"
      #hiddenfileinput
    />
  </div>
</div>
