import { Component, Input } from "@angular/core";
import { GeneralUtil, LovedOnePassportModel, LovedOnePersonalInfoModel } from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-loved-one-info',
  templateUrl: './loved-one-info.component.html',
  styleUrls: ['./loved-one-info.component.css'],
})
export class LovedOneInfoComponent{
  @Input() data!: LovedOnePassportModel;
  @Input() hidePersonalInfo = false;

  getName(lo: LovedOnePersonalInfoModel) {
    if (lo) {
      // console.log('lo', this.data);
      return GeneralUtil.stripLastName(lo.Name);
    } else {
      return '';
    }
  }
}
