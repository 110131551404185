<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>{{ text }}</mat-card-title>
    <mat-card-subtitle>{{
      (appt.startDate | date: 'shortTime') +
      ' - ' +
      (appt.endDate | date: 'shortTime')
      }}
      <button mat-raised-button
              *ngIf="(appt.status === 'careBookEntryUnfulfilled' || appt.status === 'careBookEntryBooked' || appt.status === 'careBookEntryFulfilled') && canViewCareBook && isMine"
              (click)="onViewCareBook.emit({cs: appt.careseeker, lo: appt.lovedOnesPassports[0].Id})">
        View CareBook
      </button>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column"
         *ngIf="appt">
      <div fxLayout="column">
        <div class="cg-name"
             *ngIf="appt.status === 'careBookEntryBooked' || appt.status === 'careBookEntryFulfilled'">
          <b>{{"caregiver" | translate}}: {{ getStrippedNameString(appt.caregiverName) }}</b></div>
        <div *ngIf="isMine"
             class="lo-list ml-3">
          <div *ngFor="let lo of appt.lovedOnesPassports">
            <div class="lo-name">
              <a (click)="onViewLovedOne.emit({lo: appt.lovedOnesPassports[0]})">patient:
                {{ getStrippedName(lo.LovedOnePersonalInfo.Name) }}</a>
            </div>
            <div class="lo-name">location: {{ lo.LovedOnePersonalInfo.Address.Street }}<span *ngIf="lo.LovedOnePersonalInfo.Address.Line3">, {{lo.LovedOnePersonalInfo.Address.Line3}}</span>
              , {{ lo.LovedOnePersonalInfo.Address.City }}</div>
          </div>
        </div>
        <div class="cg-name"
             *ngIf="
              careSeeker &&
              careSeeker.partnerId &&
              careSeeker.partnerId !== '' &&
              appt.text !== 'Booked'
            ">
          <ng-container *ngIf="appt.text !== 'Fulfilled'">
            <ng-container *ngFor="let member of appt.punctualTeam">
              <div>{{ resolveCt(member) }}</div>
            </ng-container>
          </ng-container>
        </div>
      </div>


      <div fxLayout="row"
           *ngIf="isMine"
           class="mt-3 ml-3">
        <div class="pill in"
             *ngIf="appt.checkInOut && appt.checkInOut.checkIn">{{appt.checkInOut.checkIn.time | date : 'shortTime'}}
        </div>
        <div class="pill out"
             *ngIf="appt.checkInOut && appt.checkInOut.checkOut">{{appt.checkInOut.checkOut.time | date : 'shortTime'}}
        </div>
      </div>

      <!-- If there is a note in the CheckInOut, show in as innerHTML -->
      <div *ngIf="appt.communications && appt.communications?.length > 0"
           class="mt-3 ml-3">
        <div>Note:</div> <span [innerHTML]=""></span>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="column"
         class="full-width">
      <mat-divider class="mt-3"
                   *ngIf="contentRef.children.length"></mat-divider>
      <div *ngIf="cantDeleteReason && cantDeleteReason !== ''"
           class="cant-delete mt-3 mr-3 ml-3"
           fxLayout="row"
           fxLayoutAlign="center center">
        <div class="info-icon">
          <mat-icon class="mr-3">info</mat-icon>
        </div>
        {{cantDeleteReason | translate}}
      </div>
      <div *ngIf="appt.extensionRequest"
           class="center">
        {{"bookingEx.requested" | translate}} {{appt.extensionRequest.hours}} hrs
      </div>
      <div class="mt-3 full-width"
           #contentRef>
        <ng-content></ng-content>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
