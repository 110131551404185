import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  AppointmentExtensionResult,
  AppointmentModel,
  AppointmentStatusModel,
  AppointmentTypesModel,
  BookingUtil,
  CareBookModel,
  CareGiverModel,
  CareSeekerModel,
  DateUtil,
  GeneralUtil,
  LovedOnePassportModel,
  LovedOnePersonalInfoModel,
} from '@nx-c4g/c4g-models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AgendaService } from './agenda.service';
import { LovedOnePopupComponent, notify } from '@nx-c4g/c4g-ui';
import { AgendaExtensionComponent } from './agenda-extension/agenda-extension.component';
import { ENVSETTINGS } from './environment';
import { MatExpansionPanel } from '@angular/material/expansion';
import { of } from 'rxjs';

@Component({
  selector: 'nx-c4g-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnChanges {
  @Input() dataSource!: AppointmentModel[];
  @Input() isMine = false;
  @Input() canViewCareBook = true;
  @Input() mode!: 'cg' | 'cs';
  @Input() notes!: string;
  @Input() careSeeker: CareSeekerModel | undefined;
  @Input() careGiver: CareGiverModel | undefined;
  @Input() balance: number;
  @Input() isAdmin = false;
  @Input() careBook: CareBookModel | undefined;
  @Output() onNewAppointment: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDuplicateWeek: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditAppointment: EventEmitter<AppointmentModel> =
    new EventEmitter<AppointmentModel>();
  @Output() weekChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() noteAdded = new EventEmitter<{note: string, apptId: string}>();

  // View all children with id #matExpansion
  panels: MatExpansionPanel[] = [];

  loading = true;

  dataMap: Map<string, { date: Date; appts: AppointmentModel[] }> = new Map<
    string,
    { date: Date; appts: AppointmentModel[] }
  >();
  currentWeek: Date = new Date();

  dialogRef: MatDialogRef<any> | undefined;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private agendaService: AgendaService,
    private cd: ChangeDetectorRef,
    @Inject(ENVSETTINGS) private environment: any,
  ) {}

  addedExpansionPanel($event: MatExpansionPanel) {
    this.panels.push($event);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['dataSource']) {
      if (this.dataSource) {
        await this.remap();
      }
    }
    if (changes['panels']) {
      console.log('panels', this.panels);
    }
  }

  collapseAllNotes() {
    console.log('collapseAllNotes', this.panels.length);
    this.panels.forEach((panel) => {
      panel.close();
    });
  }

  async remap() {
    this.loading = true;
    console.log('remap');
    this.dataMap.clear();
    console.log(this.dataSource);
    this.dataSource.sort(
      (a, b) => a.startDate.getTime() - b.startDate.getTime(),
    );
    this.dataMap = this.groupByDate(this.dataSource);

    this.loading = false;
  }

  groupByDate(
    ds: AppointmentModel[],
  ): Map<string, { date: Date; appts: AppointmentModel[] }> {
    const groups: Map<string, { date: Date; appts: AppointmentModel[] }> =
      new Map<string, { date: Date; appts: AppointmentModel[] }>();
    for (const appt of ds) {
      const key = DateUtil.getYMD(appt.startDate);
      if (!groups.has(key)) {
        groups.set(key, {
          date: DateUtil.getDatePart(appt.startDate),
          appts: [],
        });
      }
      let groudAppts: AppointmentModel[] = [];
      if (groups.get(key)?.appts) {
        groudAppts = groups.get(key)!.appts;
        groudAppts.push(appt);
      }
      groups.set(key, {
        date: DateUtil.getDatePart(appt.startDate),
        appts: groudAppts,
      });
    }

    return groups;
  }

  open(templateAgenda: TemplateRef<any>, appt: AppointmentModel) {
    this.dialogRef = this.dialog.open(templateAgenda, {
      id: 'appointment-template',
      width: '500px',
      data: appt,
    });
  }

  viewCareBook($event: { cs: string; lo: string }) {
    this.close();
    this.router.navigateByUrl(`care-book/go/${$event.cs}/${$event.lo}`);
  }

  close() {
    this.dialogRef?.close();
  }

  async deleteBooking(appt: AppointmentModel) {
    if (this.mode === 'cg') {
      await this.deleteCGBooking(appt);
    }
    if (this.mode === 'cs') {
      await this.deleteCSBooking(appt);
    }
  }

  private async deleteCGBooking(appt: AppointmentModel) {
    console.log('deleteCGBooking', appt);
    let result;
    switch (appt.status) {
      case AppointmentStatusModel.careBookEntryBooked:
      case AppointmentStatusModel.careBookEntryFulfilled:
        result = await this.agendaService.cancelCGBooking(appt);
        if (result) {
          notify('Cancelled');
        } else {
          notify('Cannot cancel', 'error');
        }
        break;
    }

    console.log('deleteCGBooking', result);
  }

  private async deleteCSBooking(appt: AppointmentModel) {
    if (
      DateUtil.within24hours(new Date(appt.startDate)) &&
      this.environment.penaltyEnabled
    ) {
      const cResult = confirm(this.translate.instant('24hPenalty'));
      if (!cResult) {
        return;
      }
    }
    console.log('deleteCSBooking', appt);
    let result;
    switch (appt.status) {
      case AppointmentStatusModel.careBookEntryBooked:
      case AppointmentStatusModel.careBookEntryFulfilled:
        result = await this.agendaService.cancelCSBooking(appt);
        if (result) {
          notify('Cancelled');
        } else {
          notify('Cannot cancel', 'error');
        }
        break;
    }

    console.log('deleteCSBooking', result);
  }

  getBackgroundColor(appt: AppointmentModel) {
    if (appt.type === AppointmentTypesModel.blocked) {
      return 'rgba(0,0,0,0.4)';
    }
    switch (appt.status) {
      case AppointmentStatusModel.careBookEntryFulfilled:
        return 'rgba(63, 191, 127,0.7)';
      case AppointmentStatusModel.careBookEntryBooked:
        return 'rgba(213,141,31,0.7)';
      case AppointmentStatusModel.careBookEntryUnfulfilled:
        if (DateUtil.isPast(appt.startDate)) {
          return 'rgb(103,103,103)';
        } else {
          if (DateUtil.within48hours(appt.startDate)) {
            return 'rgb(255,45,0)';
          } else {
            return 'rgba(191,127,63,0.7)';
          }
        }
      default:
        return 'rgba(255,255,255,0.7)';
    }
  }

  getColor(appt: AppointmentModel) {
    if (appt.type === AppointmentTypesModel.blocked) {
      return 'rgba(255,255,255,0.4)';
    }
    switch (appt.status) {
      case AppointmentStatusModel.careBookEntryFulfilled:
        return 'rgb(255,255,255)';
      case AppointmentStatusModel.careBookEntryBooked:
        return 'rgba(28,28,28,0.7)';
      case AppointmentStatusModel.careBookEntryUnfulfilled:
        return 'rgb(255,255,255)';
      default:
        return 'rgba(0,0,0,0.7)';
    }
  }

  apptTypeToString(
    type: AppointmentTypesModel,
    status: AppointmentStatusModel,
    date: Date,
  ) {
    const lang = this.translate.currentLang;

    return GeneralUtil.apptTypeToString(type, status, date, lang);
  }

  async deleteGroupedBooking(appt: AppointmentModel) {
    await this.deleteCSBooking(appt);
  }

  dateFromString(apptKey: string): Date {
    return new Date(apptKey);
  }

  cantDeleteReason(appt: AppointmentModel) {
    if (!this.isMine && appt.careseeker !== this.careSeeker?._id) {
      return 'deleteReason.notYours';
    }

    if (this.mode === 'cs' && appt.type === AppointmentTypesModel.blocked) {
      // CS cannot have Blocked Type
      return 'deleteReason.notYours';
    }
    return BookingUtil.cantDeleteReason(appt);
  }

  filteredMap(
    dataMap: Map<string, { date: Date; appts: AppointmentModel[] }>,
    currentWeek: Date,
  ) {
    return [...dataMap.values()].filter(
      (item) =>
        item.date.getTime() >= currentWeek.getTime() &&
        item.date.getTime() < DateUtil.nextWeek(currentWeek).getTime(),
    );
  }

  onCurrentWeekChanged($event: Date) {
    this.currentWeek = $event;
    this.weekChanged.emit(this.currentWeek);
  }

  canDelete(appt: AppointmentModel) {
    return BookingUtil.canDelete(appt) && this.isItMine(appt);
  }

  onNewAppointmentClick() {
    console.log('onNewAppointmentClick');
    this.onNewAppointment.emit();
  }

  async removeBlock(appt: AppointmentModel) {
    const result = await this.agendaService.removeBlock(appt);
    if (result) {
      notify('Removed');
    } else {
      notify('Cannot remove', 'error');
    }
  }

  async acceptBooking(appt: AppointmentModel) {
    // If the appointment is from a carebook, check that the CareGiver calculated rate is less than the appt max rate
    if (appt.careBookId && appt.careBookId !== '') {
      if (
        !this.careGiver ||
        !this.careGiver.calculatedRate ||
        this.careGiver.calculatedRate === 0
      ) {
        notify(this.translate.instant('REFRESH'), 'error');
        return;
      }
      if (this.careGiver && this.careGiver.calculatedRate > appt.maxRate) {
        notify(this.translate.instant('CG_RATE_TOO_HIGH'), 'error');
        return;
      }
    }
    const result = await this.agendaService.acceptBooking(appt);
    if (result) {
      notify('Accepted');
    } else {
      notify('Cannot accept', 'error');
    }
  }

  async rejectBooking(appt: AppointmentModel) {
    const result = await this.agendaService.rejectBooking(appt);
    if (result) {
      notify('Rejected');
    } else {
      notify('Cannot reject', 'error');
    }
  }

  isHis(appt: AppointmentModel) {
    if (!this.isMine && this.careSeeker) {
      if (appt.careseeker === this.careSeeker._id) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  async cancelCbBooking(appt: AppointmentModel) {
    if (
      DateUtil.within24hours(new Date(appt.startDate)) &&
      this.environment.penaltyEnabled
    ) {
      const cResult = confirm(this.translate.instant('24hPenalty'));
      if (!cResult) {
        return;
      }
    }
    const result = await this.agendaService.cancelCarebookBooking(
      appt.careBookId,
      appt,
      false,
    );
    if (result) {
      notify('Cancelled');
    } else {
      notify('Cannot cancel', 'error');
    }
  }

  async cancelCbBookingByCg(appt: AppointmentModel) {
    const result = await this.agendaService.cancelCarebookBookingbyCG(
      appt.careBookId,
      appt,
    );
    if (result) {
      notify('Cancelled');
    }
  }

  async clearCbBooking(appt: AppointmentModel) {
    if (
      DateUtil.within24hours(new Date(appt.startDate)) &&
      this.environment.penaltyEnabled
    ) {
      const cResult = confirm(this.translate.instant('24hPenalty'));
      if (!cResult) {
        return;
      }
    }
    const result = await this.agendaService.cancelCarebookBooking(
      appt.careBookId,
      appt,
      true,
    );
    if (result) {
      notify('Removed');
    } else {
      notify('Cannot remove', 'error');
    }
  }

  viewLovedOne($event: { lo: LovedOnePassportModel }) {
    this.popLo($event.lo);
  }

  getName(lo: LovedOnePersonalInfoModel) {
    return GeneralUtil.stripLastName(lo.Name);
  }

  popLo(lovedOnesPassport: LovedOnePassportModel) {
    console.log('vewing LO', lovedOnesPassport);
    const dialogRef = this.dialog.open(LovedOnePopupComponent, {
      panelClass: 'lo-popup-dialog-container',

      data: {
        name: this.getName(lovedOnesPassport.LovedOnePersonalInfo),
        notes: lovedOnesPassport.notes,
        address: lovedOnesPassport.LovedOnePersonalInfo.Address,
        photo: lovedOnesPassport.LovedOnePersonalInfo
          .UploadARecentPhotoOfTheLovedOne[0]
          ? lovedOnesPassport.LovedOnePersonalInfo
              .UploadARecentPhotoOfTheLovedOne[0].File
          : '',
        info: lovedOnesPassport.LovedOnePersonalInfo,
        needs: lovedOnesPassport.NeedsAndLevelOfAutonomy,
        lo: lovedOnesPassport,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`popLo Dialog result: ${result}`);
    });
  }

  async extend(appt: AppointmentModel) {
    const balance =
      this.mode === 'cs'
        ? (await this.agendaService.getBalance(this.careSeeker)).balance
        : this.balance;
    const dialogRef = this.dialog.open(AgendaExtensionComponent, {
      data: {
        appt: appt,
        mode: this.mode,
        cg: this.careGiver,
        cs: this.careSeeker,
        balance: balance,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('result', result);
      if (result.result === AppointmentExtensionResult.added) {
        this.dataSource.find((a) => a._id === result.appt._id).endDate =
          result.appt.endDate;
        await this.remap();
      }
      if (
        result.result === AppointmentExtensionResult.requestSentToCareseeker
      ) {
        const item = this.dataSource.find((a) => a._id === result.appt._id);
        if (item) {
          console.warn('setting appointment extensionRequest', result.appt._id);
          item.extensionRequest = result.appt.extensionRequest;
          this.cd.detectChanges();
        } else {
          console.warn('Cannot find appointment', result.appt._id);
        }
      }
      console.log(`AgendaExtensionComponent Dialog result: ${result}`);
      this.close();
    });
  }

  canExtend(appt: AppointmentModel): boolean {
    // We cannot extend in the following rules:
    // 1. the appointment end is in the past for more than 8 hours
    // 2. the appointment mode is CS is not mine

    const end = DateUtil.addTime(new Date(appt.endDate), 8, 0, 0);
    if (DateUtil.isPast(end)) {
      return false;
    }
    if (this.mode === 'cs' && this.careSeeker._id !== appt.careseeker) {
      return false;
    }
    if (appt.checkInOut && appt.checkInOut.checkOut) {
      return false;
    }

    if (appt.extensionRequest) {
      // Already an Extension Requested
      return false;
    }
    return true;
  }

  private getBalance() {
    if (this.careSeeker) {
      return this.balance;
    } else {
      return 0;
    }
  }

  isItMine(appt: AppointmentModel) {
    if (this.mode === 'cs' && this.careSeeker._id !== appt.careseeker) {
      return false;
    }
    return true;
  }

  viewExtensionRequests(appt: AppointmentModel) {
    this.dialog.closeAll();
    this.router.navigateByUrl(`extensions`);
  }

  checkAddress() {
    if (this.mode === 'cs') {
      if (!this.careSeeker) {
        return false;
      }
      const csOk = GeneralUtil.checkAddress(
        this.careSeeker.CareSeekerInformation.Address,
      );
      return csOk;
    } else {
      return true;
    }
  }

  editAppointment(appt: AppointmentModel) {
    this.onEditAppointment.emit(appt);
    this.dialogRef.close();
  }

  canEdit(appt: AppointmentModel) {
    if (this.mode === 'cs' && this.careBook && !appt.checkInOut?.checkOut) {
      return true;
    }
    return false;
  }

  duplicateWeek() {
    this.onDuplicateWeek.emit(this.currentWeek);
  }
}
