import { Injectable } from '@angular/core';
import { CareTeamMember, CareTeamModel } from '@nx-c4g/c4g-models';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {firstValueFrom, lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class CareTeamService {
  careTeam: CareTeamModel = null;

  constructor(private http: HttpClient) {}

  async getFavorites(csId: string): Promise<any> {
    const ct = await firstValueFrom(this.http
      .get(environment.api + 'care-team/team/' + csId));
    this.careTeam = ct as CareTeamModel;
    return ct as CareTeamModel;

  }

  async isInvited(csId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .get<boolean>(environment.api + 'care-team/invited/' + csId)
        .toPromise()
        .then((res: boolean) => {
          resolve(res);
        });
    });
  }

  async acceptInvitation(csId: any) {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .put<boolean>(environment.api + 'care-team/invited/accept/' + csId, {})
        .toPromise()
        .then((res: boolean) => {
          resolve(res);
        });
    });
  }

  async declineInvitation(csId: any) {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .put<boolean>(environment.api + 'care-team/invited/decline/' + csId, {})
        .toPromise()
        .then((res: boolean) => {
          resolve(res);
        });
    });
  }

  async leaveTeam(csId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .put<boolean>(environment.api + 'care-team/invited/leave/' + csId, {})
        .toPromise()
        .then((res: boolean) => {
          resolve(res);
        });
    });
  }

  async hasResponded(csId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .get<boolean>(environment.api + 'care-team/responded/' + csId)
        .toPromise()
        .then((res: boolean) => {
          resolve(res);
        });
    });
  }
}
