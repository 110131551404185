import {
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {AvailabilityValidator} from './availability.validator';
import {
  AppointmentModel,
  AppointmentTypesModel,
  CareGiverModel, DateUtil, DurationModel,
} from '@nx-c4g/c4g-models';
import { Guid } from '@nx-c4g/c4g-models'

import {
  MatButtonToggle,
  MatButtonToggleChange,
} from '@angular/material/button-toggle';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatStepper} from '@angular/material/stepper';
import {notify} from "@nx-c4g/c4g-ui";

@Component({
  selector: 'app-booking-dialog',
  templateUrl: './booking-dialog.component.html',
  styleUrls: ['./booking-dialog.component.scss'],
})
export class BookingDialogComponent implements OnInit {
  @ViewChild('sunday') sunday: MatButtonToggle;
  @ViewChild('monday') monday: MatButtonToggle;
  @ViewChild('tuesday') tuesday: MatButtonToggle;
  @ViewChild('wednesday') wednesday: MatButtonToggle;
  @ViewChild('thursday') thursday: MatButtonToggle;
  @ViewChild('friday') friday: MatButtonToggle;
  @ViewChild('saturday') saturday: MatButtonToggle;
  @ViewChild('stepper') stepper: MatStepper;

  public schedulerData: any = [];
  public careGiver: CareGiverModel;

  public weeks: number[] = [1, 2, 3, 4, 5, 6];

  public bookingMode = 'one';
  durationCtrl: FormControl;

  bookingFormGroup: FormGroup;

  private availabilityValidator: AvailabilityValidator;
  private daysAreSet = false;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.availabilityValidator = new AvailabilityValidator();
    this.availabilityValidator.setAppointmentData(data.schedulerData);
    this.schedulerData = data.schedulerData;
    this.careGiver = data.careGiver;

  }


  initDays() {
    const startDate = new Date();
    const dayOfWeek = new Date(startDate).getDay();
    console.log('dayOfWeek', dayOfWeek);
    // set the day for the date
    this.lockDay(dayOfWeek);
    this.bookingFormGroup.controls.days.setValue([dayOfWeek.toString()]);
  }

  ngOnInit() {
    const durationInit: DurationModel = new DurationModel();
    durationInit.start = DateUtil.in3hrs();
    durationInit.end = DateUtil.addTime(durationInit.start, 3, 0, 0);
    durationInit.duration = 3 * 60;

    this.durationCtrl = new FormControl(durationInit);

    this.bookingFormGroup = this.fb.group(
      {
        weeks: new FormControl(1, Validators.required),
        days: new FormControl([], Validators.required),
        durationCtrl: this.durationCtrl
      },
      {
        validators: [this.availabilityValidator.onlyIfAvailable()],
        updateOn: 'blur',
      }
    );

    this.initBooking();

  }

  private initBooking() {
    this.bookingFormGroup.controls.weeks.setValue(1);
    this.bookingFormGroup.controls.days.setValue([new Date().getDay()]);
  }

  constructSingleAppoinment(
    startEnd: DurationModel,
  ): AppointmentModel {
    const appt: AppointmentModel = new AppointmentModel();

    appt.resource = '';
    appt.startDate = startEnd.start;
    appt.endDate = startEnd.end;
    appt.text = 'Blocked';
    appt.hours = startEnd.duration;
    appt.type = AppointmentTypesModel.blocked;
    return appt;
  }

  constructAppoinmentsArray(
    bookingData: any,
    startEnd: DurationModel,
  ): AppointmentModel[] {
    const appts: AppointmentModel[] = [];
    // Get end date
    // Number of weeks * 7 days
    const days: number = +this.bookingFormGroup.controls.weeks.value * 7;
    const endDate = new Date(startEnd.start.getTime());
    endDate.setDate(startEnd.start.getDate() + days - 1);

    const daysArrayS: string[] = this.bookingFormGroup.controls.days.value;
    const daysArray: number[] = daysArrayS.map((day) => +day);

    for (
      let d = new Date(startEnd.start);
      d < endDate;
      d.setDate(d.getDate() + 1)
    ) {
      if (daysArray.includes(d.getDay())) {

        const apptStartEnd: DurationModel = this.genStartEnd(d, this.bookingFormGroup);

        console.log(apptStartEnd);
        const appt: AppointmentModel = this.constructSingleAppoinment(
          apptStartEnd,
        );
        appts.push(appt);
      }
    }
    return appts;
  }

  genStartEnd(
    date: Date,
    formGroup: FormGroup
  ): DurationModel | null {
    const startDate = new Date(date);
    const durationModel: DurationModel = formGroup.controls.durationCtrl.value;
    console.log('genStartEnd', durationModel);

    startDate.setHours(durationModel.start.getHours());
    startDate.setMinutes(durationModel.start.getMinutes());

    const timeOnLocation = durationModel.duration;
    const endDate = new Date(
      startDate.getTime() + 60 * 1000 * (timeOnLocation)
    );

    return {start: startDate, end: endDate, duration: timeOnLocation, address: durationModel.address};
  }

  constructAppointmentData(
    bookingData: any,
  ): AppointmentModel[] {
    const durationModel: DurationModel = this.bookingFormGroup.controls.durationCtrl.value;

    if (!durationModel) {
      return [];
    }

    let appts: AppointmentModel[] = [];
    if (this.bookingMode === 'one') {
      const appt: AppointmentModel = this.constructSingleAppoinment(
        durationModel,
      );
      appts.push(appt);
    } else {
      appts = this.constructAppoinmentsArray(
        bookingData,
        durationModel,
      );
    }

    return appts;
  }

  bookingModeChange() {
    console.log('bookingMode', this.bookingMode);
    if (this.bookingMode === 'many') {
      const durationModel: DurationModel = this.bookingFormGroup.controls.durationCtrl.value;
      const dayOfWeek = durationModel.start.getDay();
      console.log('dayOfWeek', dayOfWeek);
      // set the day for the date
      this.lockDay(dayOfWeek);
      this.bookingFormGroup.controls.days.setValue([dayOfWeek.toString()]);
    }
  }

  daysChange($event: MatButtonToggleChange) {
    console.log('daysChange', $event);
  }

  durationChanged(durationModel: DurationModel) {
    console.log('durationChanged', durationModel);
    if (this.bookingMode === 'many') {
      const dayOfWeek = durationModel.start.getDay();
      console.log('dayOfWeek', dayOfWeek);
      // set the day for the date
      this.lockDay(dayOfWeek);
      this.bookingFormGroup.controls.days.setValue([dayOfWeek.toString()]);
    }
  }

  private lockDay(day: number) {
    if (!this.sunday) {
      return;
    }
    this.sunday.disabled = false;
    this.monday.disabled = false;
    this.tuesday.disabled = false;
    this.wednesday.disabled = false;
    this.thursday.disabled = false;
    this.friday.disabled = false;
    this.saturday.disabled = false;
    switch (day) {
      case 0:
        this.sunday.disabled = true;
        break;
      case 1:
        this.monday.disabled = true;
        break;
      case 2:
        this.tuesday.disabled = true;
        break;
      case 3:
        this.wednesday.disabled = true;
        break;
      case 4:
        this.thursday.disabled = true;
        break;
      case 5:
        this.friday.disabled = true;
        break;
      case 6:
        this.saturday.disabled = true;
        break;
    }
  }

  onStepChange($event: StepperSelectionEvent) {
    console.log('onStepChange', $event);
    if ($event.selectedIndex === 1) {
      if (!this.daysAreSet) {
        this.initDays();
        this.daysAreSet = true;
      }
    }
  }

  save() {
    const appts: AppointmentModel[] = this.constructAppointmentData(
      this.bookingFormGroup.value,
    );
    // Add recurring validation
    for (let appt of appts) {
      if (!this.availabilityValidator.availableInTimeSlot(appt)) {
        notify('One of your block time, is overlapping an existing appointment');
        return;
      }
    }
    this.dialogRef.close(appts);

  }

}
