import {Type} from "class-transformer";

export class CgvisitsModel {
  visitCount = 0;
  visits: VisitModel[] = [];
}

export class VisitModel {
  visitor = '';
  @Type(() => Date)
  time?: Date;
}
