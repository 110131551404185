import {LovedOnePassportModel} from "./LovedOnePersonalInfoModel";
import {NameModel} from "./NameModel";
import {AddressModel} from "./AddressModel";

export class CareSeekerModel {
  _id: string;
  admin: boolean;
  Email: string;
  lovedOnesPassports: LovedOnePassportModel[] = [];
  // LovedOnePersonalInfo: any;
  CareSeekerInformation: CareSeekerInformationModel = new CareSeekerInformationModel();
  stripe_account: string;
  client_secret: string;
  type: CareSeekerTypeModel;
  credit: number;
  partnerId: string;
  lastUpdated: Date = new Date();
  marketingProfile: string;
  disabled = false;
  deleted = false;
  devices: string[] = [];
}

export class CareSeekerInformationModel {
  Name: NameModel = new NameModel();
  ResidenceName: string;
  CellPhone: string;
  Address: AddressModel = new AddressModel();
}

export enum CareSeekerTypeModel {
  individual = 'individual',
  residence = 'residence',
  partner = 'partner',
}
