
import {Type} from "class-transformer";
import {AppointmentModel} from "./appointmentModel";
import { TaskModel } from "./taskModel";

export class CareBookModel {
  _id: string;
  name: string;
  isSubsidized: boolean;
  @Type(() => AppointmentModel)
  // appointments: AppointmentModel[];
  care: TaskModel;
  notes: CareBookNote[] = [];
}

export interface CareBookNote {
  _id: string;
  note: string;
  createdAt: Date;
  updatedAt: Date;
}
