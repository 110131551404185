import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppointmentModel, BookingUtil, CareSeekerModel } from "@nx-c4g/c4g-models";

@Injectable({
  providedIn: 'root',
})
export class CareseekerProfileService {
  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
  ) {}

  documentToDomainObject = (_) => {
    // const object = _.payload.doc.data();
    // object.id = _.payload.doc.id;
    return _;
  };

  async getProfile(id): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.http.get<CareSeekerModel[]>(environment.api + 'care-seeker' + '/' + id).subscribe(
          (profiles: CareSeekerModel[]) => {
            if (
              profiles &&
              typeof profiles !== 'undefined' &&
              profiles?.length > 0
            ) {
              const csProfile = profiles[0];
              resolve(csProfile);
            } else {
              reject();
            }
          },
          (error) => {
            reject();
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // Important: DISABLED, Will be set by the Booking Service instead
  // addLovedOnesSchedule(appointmentData: any) {
  //   appointmentData.lovedOnes.forEach(lo => {
  //     const sId = appointmentData.careseeker + lo;
  //     appointmentData.status = AppointmentTypes.booking;
  //
  //     this.getSchedule(sId).then(loSchedule => {
  //       const loadedSchedule = loSchedule && loSchedule.schedule ? loSchedule.schedule : [];
  //       const schedule = this.appointmentHelperService.horaireToAppointements(loadedSchedule);
  //       schedule.push(appointmentData);
  //       this.setSchedule(sId, schedule);
  //     });
  //   });
  // }


  async findByPartnerId(reference: string): Promise<CareSeekerModel> {
    return new Promise((resolve, reject) => {
      return this.http
        .get<CareSeekerModel>(
          environment.api + 'care-seeker' + '/partner/' + reference
        )
        .subscribe((cs) => {
          resolve(cs);
        });
    });
  }
}
