import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AreasServedRateAndConditionsModel,
  CareGiverModel,
} from '@nx-c4g/c4g-models';

@Component({
  selector: 'app-area-and-conditions',
  templateUrl: './area-and-conditions.component.html',
  styleUrls: ['./area-and-conditions.component.scss'],
})
export class AreaAndConditionsComponent implements OnInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;
  @Input() careGiver: CareGiverModel;
  @Output() careGiverChange = new EventEmitter<CareGiverModel>();

  onChange$: any;
  areasServed: AreasServedRateAndConditionsModel;

  constructor() {}

  ngOnInit(): void {
    if (!this.careGiver.AreasServedRateAndConditions) {
      this.careGiver.AreasServedRateAndConditions = new AreasServedRateAndConditionsModel();
    }

    this.parentFormGroup.addControl(
      'ServiceRadius',
      new FormControl(30, Validators.required)
    );
    this.parentFormGroup.addControl(
      'KmMiles',
      new FormControl('Km', Validators.required)
    );
    this.parentFormGroup.addControl(
      'ZonesYouCannotServe',
      new FormControl('No')
    );
    this.parentFormGroup.addControl(
      'ShortestBookingYouAreWillingToAccept',
      new FormControl('180', Validators.required)
    );
    this.parentFormGroup.addControl('CGSmokersHouse', new FormControl('Yes'));
    this.parentFormGroup.addControl('CGTrainedOutOfBed', new FormControl('No'));
    this.parentFormGroup.addControl('CGBio', new FormControl(''));

    this.initValues();
  }

  initValues() {
    this.parentFormGroup.patchValue(
      this.careGiver.AreasServedRateAndConditions
    );
    this.areasServed = this.careGiver.AreasServedRateAndConditions;
    this.areasServed.ServiceRadius = 30;
    this.areasServed.KmMiles = 'Km';
    this.areasServed.ShortestBookingYouAreWillingToAccept = '180';
    this.areasServed.CGSmokersHouse = 'Yes';
    this.areasServed.CGTrainedOutOfBed = 'No';

    this.onChanges();
  }

  updateValue() {
    this.areasServed.ServiceRadius = this.parentFormGroup.controls.ServiceRadius.value;
    this.areasServed.KmMiles = this.parentFormGroup.controls.KmMiles.value;
    this.areasServed.ShortestBookingYouAreWillingToAccept = this.parentFormGroup.controls.ShortestBookingYouAreWillingToAccept.value;
    this.areasServed.CGSmokersHouse = this.parentFormGroup.controls.CGSmokersHouse.value;
    this.areasServed.CGTrainedOutOfBed = this.parentFormGroup.controls.CGTrainedOutOfBed.value;
    this.areasServed.CGBio = this.parentFormGroup.controls.CGBio.value;
    this.careGiverChange.emit(this.careGiver);
  }

  private onChanges() {
    this.onChange$ = this.parentFormGroup.valueChanges.subscribe((val) => {
      this.updateValue();
    });
  }

  ngOnDestroy(): void {
    if (this.onChange$) {
      this.onChange$.unsubscribe();
    }
  }

  CGSpecialInterestsSelected(event) {
    if (event.checked) {
      this.areasServed.CGSpecialInterests.push(event.source.value);
    } else {
      this.areasServed.CGSpecialInterests = this.areasServed.CGSpecialInterests.filter(
        (s) => s != event.source.value
      );
    }
  }

  CGPetsSelected(event) {
    if (event.checked) {
      this.areasServed.CGPetsCannotEnter.push(event.source.value);
    } else {
      this.areasServed.CGPetsCannotEnter = this.areasServed.CGPetsCannotEnter.filter(
        (s) => s != event.source.value
      );
    }
  }
}
