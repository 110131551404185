import { Injectable } from '@angular/core';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

export interface GtmEvent {
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventValue?: number;
}

export const GTMEventRegistrationSuccess: GtmEvent = {
  eventCategory: 'CG-registration',
  eventAction: 'registration',
  eventLabel: 'success',
  eventValue: 1,
};
export const GTMEventRegistrationFailure: GtmEvent = {
  eventCategory: 'CG-registration',
  eventAction: 'registration',
  eventLabel: 'failure',
};
export const GTMEventloginSuccess: GtmEvent = {
  eventCategory: 'CG-login',
  eventAction: 'login',
  eventLabel: 'success',
  eventValue: 0,
};
export const GTMEventloginFailure: GtmEvent = {
  eventCategory: 'CG-login',
  eventAction: 'login',
  eventLabel: 'failure',
};
export const GTMEventlogout: GtmEvent = {
  eventCategory: 'CG-logout',
  eventAction: 'logout',
  eventLabel: 'success',
};

export const GTMEventNewBooking: GtmEvent = {
  eventCategory: 'CG-new-booking',
  eventAction: 'add',
  eventLabel: 'add',
  eventValue: 1,
};
export const GTMEventCancelAppointment: GtmEvent = {
  eventCategory: 'CG-cancel-appointment',
  eventAction: 'cancel',
  eventLabel: 'cancel',
};
export const GTMEventCancelAppointmentByCG: GtmEvent = {
  eventCategory: 'CG-cancel-appointment',
  eventAction: 'cancel-by-cg',
  eventLabel: 'cancel-by-cg',
};
export const GTMEventAcceptBooking: GtmEvent = {
  eventCategory: 'CG-acceptBooking',
  eventAction: 'accept',
  eventLabel: 'accept',
  eventValue: 5,
};
export const GTMEventRejectBooking: GtmEvent = {
  eventCategory: 'CG-rejectBooking',
  eventAction: 'reject',
  eventLabel: 'reject',
};

export const GTMEventGetProfile: GtmEvent = {
  eventCategory: 'CG-getProfile',
  eventAction: 'get',
  eventLabel: 'get',
};
export const GTMEventSetAvailability: GtmEvent = {
  eventCategory: 'CG-setAvailability',
  eventAction: 'set',
  eventLabel: 'set',
  eventValue: 1,
};
export const GTMEventChangePhoto: GtmEvent = {
  eventCategory: 'CG-changePhoto',
  eventAction: 'set',
  eventLabel: 'set',
  eventValue: 1,
};
export const GTMEventChangedProfile: GtmEvent = {
  eventCategory: 'CG-changedProfile',
  eventAction: 'set',
  eventLabel: 'set',
  eventValue: 1,
};

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  pushTag(item: GtmEvent): void {
    gtag('event', item.eventAction, {
      event_category: item.eventCategory,
      event_label: item.eventLabel,
      value: item.eventValue ? item.eventValue : 0,
    });
  }
}
