<div class="theme-container">
  <c4g-caregiver-basic-info
    [(careGiver)]="careGiver"
    [parentFormGroup]="firstFormGroup"
    [lang]="lang"
  ></c4g-caregiver-basic-info>
  <button
    mat-raised-button
    color="primary"
    (click)="save()"
    [disabled]="firstFormGroup.invalid"
  >
    Save
  </button>
</div>
