<div class="px-3">
  <div class="theme-container">
    <div *ngIf="geoEnable">
      <div class="mb-4">
        <h4>{{ 'where-you-are' | translate }}</h4>
        <div #googleMap class="google-map" *ngIf="mapInit">
          <google-map #googleMap [options]="mapOptions" (mapInitialized)="mapReady($event)" width="100%"
                      height="100%"></google-map>
        </div>
        <div class="loading" *ngIf="!mapInit">
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="selectedDate"
          [readonly]="true"
          (dateChange)="reload()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <h4>
        {{ 'your-appointments' | translate }}
        <c4g-refresh (refresh)="reload()" [color]="'primary'" class="refresh"></c4g-refresh>
      </h4>
      <nx-c4g-in-out-appointment
        *ngFor="let appt of schedule"
        [appointment]="appt"
      ></nx-c4g-in-out-appointment>

    </div>
    <div class="mb-2" *ngIf="!geoEnable">
      <h2 class="warning">
        You must enable Geo Location to use this application.
      </h2>
      <h4>
        Please note that we (only) capture your position when you check-in and
        check-out
      </h4>
    </div>
  </div>
</div>
