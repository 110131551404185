<form [formGroup]="parentFormGroup">
  <div class="d-distance mt-1">
    <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
      <mat-label>
        {{ 'Form3.HowFarAway' | translate }}
      </mat-label>
      <input
        matInput
        [formControlName]="'ServiceRadius'"
        required
        type="number"
      />
    </mat-form-field>
    <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
      <mat-label>{{ 'Form3.KmOrMiles' | translate }}</mat-label>
      <mat-select [formControlName]="'KmMiles'">
        <mat-option value="Km">{{ 'Form3.Km' | translate }}</mat-option>
        <mat-option value="Miles">{{ 'Form3.Miles' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="small mt-1 mb-3">
    {{ 'Form3.HowFarAway_Hint' | translate }}
  </div>

  <div class="row">
    <div class="col-md-8">
      <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
        <mat-label
        >{{ 'Form3.ShortestBookingYouAreWillingToAccept' | translate }}
        </mat-label>
        <mat-select [formControlName]="'ShortestBookingYouAreWillingToAccept'">
          <mat-option value="30">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.30' | translate
            }}
          </mat-option>
          <mat-option value="60">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.60' | translate
            }}
          </mat-option>
          <mat-option value="90">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.90' | translate
            }}
          </mat-option>
          <mat-option value="120">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.120' | translate
            }}
          </mat-option>
          <mat-option value="150">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.150' | translate
            }}
          </mat-option>
          <mat-option value="180">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.180' | translate
            }}
          </mat-option>
          <mat-option value="210">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.210' | translate
            }}
          </mat-option>
          <mat-option value="240">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.240' | translate
            }}
          </mat-option>
          <mat-option value="300">
            {{
            'Form3.ShortestBookingYouAreWillingToAccept_time.300' | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <h4>
      {{ 'Form3.CGSmokersHouse' | translate }}
    </h4>
    <mat-radio-group [formControlName]="'CGSmokersHouse'">
      <mat-radio-button value="Yes">{{
        'Form3.CGSmokersHouse_options.1' | translate
        }}</mat-radio-button>
      <mat-radio-button value="No">{{
        'Form3.CGSmokersHouse_options.2' | translate
        }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div>
    <h4>
      {{ 'Form3.CGTrainedOutOfBed' | translate }}
    </h4>
    <mat-radio-group [formControlName]="'CGTrainedOutOfBed'">
      <mat-radio-button value="Yes">
        {{ 'Form3.CGTrainedOutOfBed_options.1' | translate }}</mat-radio-button
      >
      <mat-radio-button value="No">
        {{ 'Form3.CGTrainedOutOfBed_options.2' | translate }}</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div>
    <h4>{{ 'Form3.specialInterest' | translate }}</h4>
    <div fxLayout="row wrap">
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Music"
        [checked]="areasServed.CGSpecialInterests?.includes('Music')"
      >{{ 'Form3.specialInterestOptions.1' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Walks"
        [checked]="areasServed.CGSpecialInterests?.includes('Walks')"
      >{{ 'Form3.specialInterestOptions.2' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Pets"
        [checked]="areasServed.CGSpecialInterests?.includes('Pets')"
      >{{ 'Form3.specialInterestOptions.3' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Card games"
        [checked]="areasServed.CGSpecialInterests?.includes('Card games')"
      >{{ 'Form3.specialInterestOptions.4' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Chess"
        [checked]="areasServed.CGSpecialInterests?.includes('Chess')"
      >{{ 'Form3.specialInterestOptions.5' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Books / Reading"
        [checked]="areasServed.CGSpecialInterests?.includes('Books / Reading')"
      >
        {{ 'Form3.specialInterestOptions.6' | translate }}
      </mat-checkbox>
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="Movies"
        [checked]="areasServed.CGSpecialInterests?.includes('Movies')"
      >
        {{ 'Form3.specialInterestOptions.7' | translate }}
      </mat-checkbox>
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGSpecialInterestsSelected($event)"
        value="TV Shows"
        [checked]="areasServed.CGSpecialInterests?.includes('TV Shows')"
      >
        {{ 'Form3.specialInterestOptions.8' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div>
    <h4>{{ 'Form3.cannotEnterHomeWith' | translate }}</h4>
    <div fxLayout="row wrap">
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGPetsSelected($event)"
        value="No issue entering a home with pets"
        [checked]="
          areasServed.CGPetsCannotEnter?.includes(
            'No issue entering a check-in-out with pets'
          )
        "
      >{{
        'Form3.cannotEnterHomeWith_options.No'
          | translate
        }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGPetsSelected($event)"
        value="Dog"
        [checked]="areasServed.CGPetsCannotEnter?.includes('Dog')"
      >{{
        'Form3.cannotEnterHomeWith_options.Dogs' | translate
        }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="25"
        fxFlex.lt-md="50"
        fxFlex.lt-sm="100"
        class="example-margin"
        (change)="CGPetsSelected($event)"
        value="Cat"
        [checked]="areasServed.CGPetsCannotEnter?.includes('Cat')"
      >{{
        'Form3.cannotEnterHomeWith_options.Cats' | translate
        }}</mat-checkbox
      >
    </div>
  </div>

  <h4>{{ 'Form3.Bio' | translate }}</h4>

  <mat-form-field
    style="margin-top: 1rem"
    [appearance]="'outline'"
    [floatLabel]="'always'"
  >
    <mat-label> {{ 'Form3.CGBio' | translate }} </mat-label>
    <textarea matInput [formControlName]="'CGBio'" rows="5"></textarea>
  </mat-form-field>
</form>
