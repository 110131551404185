export enum CurrentResidentialSettingEnum {
  alone = 'NeedsAssessment.CurrentResidentialSettingEnum.alone',
  spouseOtherA = 'NeedsAssessment.CurrentResidentialSettingEnum.spouseA',
  spouseOtherNA = 'NeedsAssessment.CurrentResidentialSettingEnum.spouseNA',
  spouseOtherSA = 'NeedsAssessment.CurrentResidentialSettingEnum.spouseSA',
  residenceNS = 'NeedsAssessment.CurrentResidentialSettingEnum.residenceNS',
  residenceSS = 'NeedsAssessment.CurrentResidentialSettingEnum.residenceSS',
  LTC = 'NeedsAssessment.CurrentResidentialSettingEnum.LTC'
}

export enum DiagnosticEnumWithPossible {
  No = 'NeedsAssessment.DiagnosticEnum.No',
  PossibleUndiagnosed = 'NeedsAssessment.DiagnosticEnum.PossibleUndiagnosed',
  Early = 'NeedsAssessment.DiagnosticEnum.Early',
  Mid = 'NeedsAssessment.DiagnosticEnum.Mid',
  Advanced = 'NeedsAssessment.DiagnosticEnum.Advanced',
}

export enum DiagnosticEnum {
  No = 'NeedsAssessment.DiagnosticEnum.No',
  Early = 'NeedsAssessment.DiagnosticEnum.Early',
  Mid = 'NeedsAssessment.DiagnosticEnum.Mid',
  Advanced = 'NeedsAssessment.DiagnosticEnum.Advanced',
}

export enum DiagnosticArthritisEnum {
  No = 'NeedsAssessment.DiagnosticEnum.No',
  Mild = 'NeedsAssessment.DiagnosticEnum.Mild',
  Chronic = 'NeedsAssessment.DiagnosticEnum.Chronic',
  OsteoArthritis = 'NeedsAssessment.DiagnosticEnum.OsteoArthritis',
}

export enum OtherMedicalConditionsEnum {
  Cancer = 'NeedsAssessment.OtherMedicalConditionsEnum.Cancer',
  COPD = 'NeedsAssessment.OtherMedicalConditionsEnum.COPD',
  BipolarDisorder = 'NeedsAssessment.OtherMedicalConditionsEnum.BipolarDisorder',
  Diabetes = 'NeedsAssessment.OtherMedicalConditionsEnum.Diabetes',
  LowBloodPressure = 'NeedsAssessment.OtherMedicalConditionsEnum.LowBloodPressure',
  HighBloodPressure = 'NeedsAssessment.OtherMedicalConditionsEnum.HighBloodPressure',
  HeartCondition = 'NeedsAssessment.OtherMedicalConditionsEnum.HeartCondition',
}

export enum MobilityIssuesEnum {
  None = 'NeedsAssessment.MobilityIssuesEnum.None',
  cane = 'NeedsAssessment.MobilityIssuesEnum.cane',
  walker = 'NeedsAssessment.MobilityIssuesEnum.walker',
  Wheelchair = 'NeedsAssessment.MobilityIssuesEnum.Wheelchair',
  immobile = 'NeedsAssessment.MobilityIssuesEnum.immobile',
}

export enum LiftingEnum {
  None = 'NeedsAssessment.LiftingEnum.None',
  chair = 'NeedsAssessment.LiftingEnum.chair',
  bed = 'NeedsAssessment.LiftingEnum.bed',
  AssistanceOne = 'NeedsAssessment.LiftingEnum.AssistanceOne',
  AssistanceTwo = 'NeedsAssessment.LiftingEnum.AssistanceTwo',
  Hoyerlift  = 'NeedsAssessment.LiftingEnum.Hoyerlift',
}

export enum VerbalEnum {
  ease = 'NeedsAssessment.Verbal.ease',
  Difficulty = 'NeedsAssessment.Verbal.Difficulty',
  Nonverbal = 'NeedsAssessment.Verbal.Nonverbal',
}

export enum HearingEnum {
  noAids = 'NeedsAssessment.Hearing.noAids',
  betterWithAids = 'NeedsAssessment.Hearing.betterWithAids',
  aidsLeft = 'NeedsAssessment.Hearing.aidsLeft',
  aidsRight = 'NeedsAssessment.Hearing.aidsRight',
  aidsBoth = 'NeedsAssessment.Hearing.aidsBoth',
}

export enum VisionEnum {
  noGlasses = 'NeedsAssessment.Vision.noGlasses',
  glassesMyopic = 'NeedsAssessment.Vision.glassesMyopic',
  glassesFar = 'NeedsAssessment.Vision.glassesFar',
  bifocal = 'NeedsAssessment.Vision.bifocal',
  macularDegeneration = 'NeedsAssessment.Vision.macularDegeneration',
  blind = 'NeedsAssessment.Vision.blind',
}

export enum ContinenceEnum {
  continent = 'NeedsAssessment.Continence.continent',
  semiIncontinent = 'NeedsAssessment.Continence.semiIncontinent',
  semiIncontinentDiaper = 'NeedsAssessment.Continence.semiIncontinentDiaper',
  incontinent = 'NeedsAssessment.Continence.incontinent',
}

export enum ConcernsEnum {
  Sundown = 'NeedsAssessment.Concerns.Sundown',
  AggressiveVerbal = 'NeedsAssessment.Concerns.AggressiveVerbal',
  AggressivePhysical = 'NeedsAssessment.Concerns.AggressivePhysical',
  Vertigo = 'NeedsAssessment.Concerns.Vertigo',
}

export enum AutonomyEnum {
  Alone = 'NeedsAssessment.Autonomy.Alone',
  Help = 'NeedsAssessment.Autonomy.Help',
  Dependent = 'NeedsAssessment.Autonomy.Dependent',
}
