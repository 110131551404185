import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CareBookModel } from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-care-book-tasks',
  templateUrl: './care-book-tasks.component.html',
  styleUrls: ['./care-book-tasks.component.scss'],
})
export class CareBookTasksComponent  {
  @Input() careBook!: CareBookModel;
  @Input() viewOnly = false;
  @Output()
  changed: EventEmitter<CareBookModel> = new EventEmitter<CareBookModel>();

  newTask = '';
  timeout: any = null;

  newTaskClicked() {
    console.log('Added task', this.newTask);
    this.careBook.care.tasks.push(this.newTask);
    this.newTask = '';
    this.changed.emit();
  }

  remove(task: string) {
    const index = this.careBook.care.tasks.indexOf(task, 0);
    if (index > -1) {
      this.careBook.care.tasks.splice(index, 1);
      this.changed.emit();
    }
  }

  onNotesChanged($event: KeyboardEvent) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.changed.emit();
    }, 1000);
  }
}
