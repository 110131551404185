export class Guid {
  static urlAlphabet =
    'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict'

  static nanoid(size = 18) {
    let id = ''
    // A compact alternative for `for (var i = 0; i < step; i++)`.
    let i = size
    while (i--) {
      // `| 0` is more compact and faster than `Math.floor()`.
      id += Guid.urlAlphabet[(Math.random() * 64) | 0]
    }
    return id
  }
}

