import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppService } from '../../../app.service';
import { AuthenticationService } from '../../../services/authentication-service/authentication.service';
import {AppointmentExtensionModel, CGTeamsModel, CareGiverModel} from "@nx-c4g/c4g-models";
import {ExtensionsService} from "../../../services/extensions/extensions.service";
import { CaregiverProfileService } from '../../../services/profile/caregiver-profile.service';
import { CareTeamExpandedService } from '../../../services/care-team/care-team-expanded.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
  styleUrls: ['./toolbar1.component.scss'],
})
export class Toolbar1Component implements OnInit, OnDestroy {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  extensions: AppointmentExtensionModel[];
  public careTeams: CGTeamsModel[];
  cgProfile: CareGiverModel;
  private subCareTeamExpandedService;
  private subCareTeamExpandedServiceCache;

  constructor(
    public appService: AppService,
    public auth: AuthenticationService,
    private extensionsService: ExtensionsService,
    private caregiverProfileService: CaregiverProfileService,
    private careTeamxpandedService: CareTeamExpandedService,
  ) {}

  async ngOnInit() {
    await this.auth.currentUser();
    // this.auth.userData.subscribe((userData) => {
    if (this.auth.user) {

      this.extensionsService.extensions.subscribe((extensions) => {
        this.extensions = extensions;
        console.log('ExtensionsService.extensions.subscribe', extensions);
        if (this.extensions.length === 0) {
          this.extensions = null;
        }
      });

      this.extensionsService.getExtensionsOrLoad(this.auth.user.uid).then(() => {
        console.log('Extensions loaded');
      });

      this.cgProfile = await this.caregiverProfileService.getProfile(this.auth.user.uid);
      this.careTeams = await this.careTeamxpandedService.reloadCB(
        this.cgProfile,
        true
      );

      this.subCareTeamExpandedService =
      this.careTeamxpandedService.changed.subscribe(async () => {
        console.log('ExtensionsComponent careTeamxpandedService.changed');
        this.careTeams = await this.careTeamxpandedService.reloadCB(
        this.cgProfile,
        true
      );
      });

      this.subCareTeamExpandedServiceCache =
      this.careTeamxpandedService.cacheChanged.subscribe(async () => {
        console.log('ExtensionsComponent careTeamxpandedService.cacheChanged');
        this.careTeams = await this.careTeamxpandedService.reloadCB(
          this.cgProfile,
          true
        );
      });
    }
    // });
  }

  ngOnDestroy(): void {
    if (this.subCareTeamExpandedService) {
      this.subCareTeamExpandedService.unsubscribe();
    }

    if (this.subCareTeamExpandedServiceCache) {
      this.subCareTeamExpandedServiceCache.unsubscribe();
    }
  }

  get invitationCount() {
    if (!this.careTeams) {
      return 0;
    }
    return this.careTeams.filter((ct) => ct.status === 'invited').length;
  }

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }
}
