<div fxLayout="row wrap">
  <mat-form-field [appearance]="'outline'" fxFlex="60" fxFlex.lt-sm="100">
    <mat-label  class="floating-label">New Log Entry</mat-label>
    <input
      matInput
      placeholder="Enter Log entry"
      [(ngModel)]="newTask"
      class="input-with-inline-button"
      (keyup.enter)="newTaskClicked()"
    />
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" fxFlex="20" fxFlex.lt-sm="100">
    <mat-label  class="floating-label">Priority</mat-label>
    <mat-select [(value)]="priority">
      <mat-option value="normal">normal</mat-option>
      <mat-option class="warning" value="warning">warning</mat-option>
      <mat-option class="critical" value="critical">critical</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="accent" (click)="newTaskClicked()">Add</button>
</div>

<div fxLayout="column" *ngIf="careLog">
  <h2 class="card-title">Log Entries</h2>
  <div *ngFor="let task of getEntries()" fxLayout="column">
    <div><b>{{ task.name }}</b> - {{ task.date | date: 'short' }}</div>
    <div [className]="task.priority">
      {{ task.entry }}
    </div>
  </div>
</div>
