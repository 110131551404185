<a mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
  <span class="uppercase flag-name">{{ flag.code }}</span>
  <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
</a>
<mat-menu
  #langMenu="matMenu"
  [overlapTrigger]="false"
  xPosition="before"
  class="app-menu-panel"
>
  <span (mouseleave)="langMenuTrigger.closeMenu()">
    <button mat-menu-item *ngFor="let flag of flags" (click)="changeLang(flag)">
      <span class="flag-name">{{ flag.name }}</span>
    </button>
  </span>
</mat-menu>
