<div fxLayout="row wrap" *ngIf="!viewOnly">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100" class="p-2">
    <mat-form-field [appearance]="'outline'" fxFlex="100">
      <mat-label>{{ 'tasks.New Task' | translate }}</mat-label>
      <input
        matInput
        placeholder="Enter task"
        [(ngModel)]="newTask"
        class="input-with-inline-button"
        (keyup.enter)="newTaskClicked()"
      />
      <button mat-button (click)="newTaskClicked()" class="inline-button">
        {{ 'tasks.Add' | translate }}
      </button>
    </mat-form-field>
  </div>
</div>
<div fxLayout="column" *ngIf="careBook">
  <h5>{{ 'tasks.Tasks' | translate }}</h5>
  <div class="card-tasks p-2">
      <div
        *ngFor="let task of careBook?.care?.tasks"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="item-row"
      >
        <div class="list-item">
          {{ task }}
        </div>
        <button
          *ngIf="!viewOnly"
          mat-icon-button
          (click)="remove(task)"
          class="list-button"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
  </div>
</div>
<div fxLayout="row wrap" *ngIf="careBook && !viewOnly">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100" class="p-2">
    <mat-form-field
      [appearance]="'outline'"
      [floatLabel]="'always'"
      fxFlex="100"
    >
      <mat-label>Notes</mat-label>
      <textarea
        matInput
        [(ngModel)]="careBook.care.notes"
        rows="4"
        class="text-area"
        (keyup)="onNotesChanged($event)"
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div fxLayout="row wrap" *ngIf="careBook && viewOnly">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100" class="p-2">
    <h5>Notes</h5>
    <div>{{ careBook.care.notes }}</div>
  </div>
</div>
