
export enum CheckInsTypes {
  in = 'in',
  out = 'out',
}

export interface CheckInsLedger {
  type: CheckInsTypes;
  checkIn: CheckInModel;
}

export interface CheckInOutModel {
  checkIn: CheckInModel;
  checkOut?: CheckInModel;
  totalHours?: number;
  totalHoursAsText?: string;
  totalDifferenceFromBookedTime?: number;

  // amountDue?: number; // Total without taxes
  // taxesDue?: TaxeModel[];
  // amountPaid?: number; // Paid, can't be modified (without taxes)
  // taxesPaid?: TaxeModel[]; // Paid taxes
  // amountCollected?: number; // Total collected from cS without taxes
  // taxesCollected?: TaxeModel[];
  // amountCharged?: number; // Total collected from cS without taxes
  // chargedTaxesCollected?: TaxeModel[];
}

export interface CheckInModel {
  appointmentId: string;
  careSeekerName: string;
  careSeekerId: string;
  careSeekerEmail?: string;
  careGiverName: string;
  careGiverId: string;
  careGiverEmail?: string;
  time?: Date;
  timeAsText?: string;
  isManualEntry?: boolean;
  manualEntryTime?: Date;
  isAdminEntry?: boolean;
  timeDifferenceFromPlannedTime?: number; // In Minutes
  timeDifferenceFromPlannedTimeTxt?: string;
  position?: {
    Latitude: number;
    Longitude: number;
  };
  distanceFromTarget?: number;
  farDistanceReasonProvided?: boolean;
  farDistanceReason?: string;
  earlyOrLate?: boolean;
  earlyOrLateReason?: string;
  careBookId?: string;
}

export interface CheckInNoteModel {
  appointmentId: string;
  careGiverId: string;
  careSeekerId: string;
  note: string;
}
