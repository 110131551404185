import {Injectable} from '@angular/core';
import {CheckInModel, CheckInOutModel} from '@nx-c4g/c4g-models';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {firstValueFrom} from "rxjs";
import {plainToInstance} from "class-transformer";

@Injectable({
  providedIn: 'root',
})
export class CheckInsService {
  constructor(private http: HttpClient) {
  }

  async in(checkInModel: CheckInModel): Promise<CheckInOutModel> {
    const retVal = await firstValueFrom(
      this.http
        .post(environment.api + 'check-ins/in', checkInModel)
    );
    console.log('IN', retVal);
    return <CheckInOutModel> retVal;
  }

  async out(checkInModel: CheckInModel): Promise<CheckInOutModel> {
    const retVal = await firstValueFrom(
      this.http
      .post(environment.api + 'check-ins/out', checkInModel)
    );
    console.log('OUT', retVal);
    return <CheckInOutModel> retVal;

  }
}
