import { NameModel } from '../NameModel';
import {
  DaysEnum,
  WeeklyAvailabilitiesModel,
} from '../careGiverModel';
import { AddressModel } from '../AddressModel';
import { AppointmentTypesModel, AppointmentStatusModel } from '../enums/AppointmentTypesModel';
import { DateUtil } from './date/date.util';

export class GeneralUtil {

  static apptTypeToString(type: AppointmentTypesModel, status: AppointmentStatusModel, date: Date, lang: string) {
    if (type === AppointmentTypesModel.blocked) {
      return lang === 'en' ? 'Blocked (Vacation or other)' : 'Bloqué (Vacances ou autre)';
    }
    switch (status) {
      case AppointmentStatusModel.cancelled:
        return lang === 'en' ? 'Cancelled' : 'Annulé';
      case AppointmentStatusModel.cancelledByCG:
        return lang === 'en'
          ? 'Cancelled by caregiver'
          : "Annulé par l'aide-soignant";
      case AppointmentStatusModel.careBookEntryBooked:
        return lang === 'en'
          ? 'reservation accepted'
          : 'Réservation acceptée';
      case AppointmentStatusModel.careBookEntryFulfilled:
        return lang === 'en'
          ? 'reservation completed'
          : 'Réservation complétée';
      // case AppointmentTypesModel.declined:
      //   return lang === 'en' ? 'Booking Declined' : 'Réservation déclinée';
      case AppointmentStatusModel.careBookEntryUnfulfilled:
        if (DateUtil.isPast(date)) {
          return lang === 'en'
            ? 'reservation unfulfilled'
            : 'Réservation non-complétée';
        } else {
          return lang === 'en'
            ? 'Waiting for caregiver'
            : "En attente d'un aide-soignant";
        }
      case AppointmentStatusModel.paidToCG:
        return lang === 'en' ? 'Paid to caregiver' : 'Paid to caregiver';
      case AppointmentStatusModel.none:
        return lang === 'en' ? 'None' : 'None';
    }
  }

  static isMobile(): boolean {
    return window.innerWidth < 768;
  }

  static convertLineBreaksToHTML(str: string): string {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }

  /**
   * It takes an object, converts it to JSON, then converts it back to an object
   * @param {T} obj - The object to be cloned.
   * @returns A deep clone of the object.
   */
  static deepClone<T>(obj: T): T {
    const clone = JSON.parse(JSON.stringify(obj));
    return clone;
  }

  static stripLastName(name: NameModel) {
    if (name.First && name.Last) {
      return name.First + ' ' + name.Last.substring(0, 1) + '.';
    } else {
      const names = name.FirstAndLast.split(' ');
      return names[0] + ' ' + names[1].substring(0, 1) + '.';
    }
  }

  static stripLastNameString(name: string) {
    const names = name.split(' ');
    return names[0] + ' ' + names[1].substring(0, 1) + '.';
  }

  static validatePhoneForE164(phoneNumber) {
    const regEx = /^\+[1-9]\d{10,14}$/;

    return regEx.test(phoneNumber);
  }

  static cleanPhoneNumber(Phone: string) {
    if (!Phone) {
      return null;
    }
    const newPhone = Phone.startsWith('+')
      ? `+${Phone.replace(/[^0-9]/g, '')}`
      : `+1${Phone.replace(/[^0-9]/g, '')}`;
    if (GeneralUtil.validatePhoneForE164(newPhone)) {
      return newPhone;
    } else {
      return null;
    }
  }

  static orderedDays: Record<DaysEnum, number> = {
    [DaysEnum.SUN]: 1,
    [DaysEnum.MON]: 2,
    [DaysEnum.TUE]: 3,
    [DaysEnum.WED]: 4,
    [DaysEnum.THU]: 5,
    [DaysEnum.FRI]: 6,
    [DaysEnum.SAT]: 7,
  };

  static sortedWeeklyAvailabilities(
    avails: WeeklyAvailabilitiesModel[],
  ): WeeklyAvailabilitiesModel[] {
    if (!avails) {
      return [];
    }

    return avails.sort((a, b) => {
      const dayDiff =
        GeneralUtil.orderedDays[a.day] - GeneralUtil.orderedDays[b.day];
      if (dayDiff !== 0) {
        return dayDiff;
      } else {
        return a.startTime.localeCompare(b.startTime);
      }
    });
  }

  static sortedDaysEnum(): DaysEnum[] {
    return Object.keys(GeneralUtil.orderedDays).sort(
      (a, b) => GeneralUtil.orderedDays[a] - GeneralUtil.orderedDays[b],
    ) as DaysEnum[];
  }

  static checkAddress(address: AddressModel) {
    if (
      !address ||
      !address.Street ||
      address.Street === '' ||
      !address.timeZone ||
      address.timeZone === '' ||
      !address.Country ||
      address.Country === '' ||
      !address.State ||
      address.State === '' ||
      !address.City ||
      address.City === '' ||
      !address.Longitude ||
      !address.Latitude ||
      !GeneralUtil.validatePostalCode(address.PostalCode, address.countryCode)
    ) {
      // console.log('Address is invalid', address);
      return false;
    } else {
      return true;
    }
  }

  static validatePostalCode(postalCode: string, countryCode: string): boolean {
    const usZipCodeRegExp = /^\d{5}(-\d{4})?$/;
    const caPostalCodeRegExp = /^([A-Za-z]\d[A-Za-z])(\s?\d[A-Za-z]\d)$/;

    let isValid = false;

    if (countryCode === 'US') {
      isValid = usZipCodeRegExp.test(postalCode);
    } else if (countryCode === 'CA') {
      isValid = caPostalCodeRegExp.test(postalCode);
    } else {
      console.log('Unsupported country code');
    }

    return isValid;
  }

  //********************************************************************************************
  // Address Management
  //********************************************************************************************
  static AddressManagement = class {
    public static async validateAddress(
      address: AddressModel,
    ): Promise<boolean> {
      if (
        !address ||
        !address.Country ||
        address.Country === '' ||
        !address.State ||
        address.State === '' ||
        !address.City ||
        address.City === '' ||
        !address.Longitude ||
        !address.Latitude ||
        !GeneralUtil.AddressManagement.supportedCountries(
          address.countryCode,
        ) ||
        !GeneralUtil.AddressManagement.validatePostalCode(
          address.PostalCode,
          address.countryCode,
        )
      ) {
        return false;
      } else {
        return true;
      }
    }

    static validatePostalCode(
      postalCode: string,
      countryCode: string,
    ): boolean {
      const usZipCodeRegExp = /^\d{5}(-\d{4})?$/;
      const caPostalCodeRegExp =
        /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])(\s?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/;
      const mxPostalCodeRegExp = /^\d{5}(-\d{3})?$/;

      let isValid = false;

      if (countryCode === 'US') {
        isValid = usZipCodeRegExp.test(postalCode);
      } else if (countryCode === 'CA') {
        isValid = caPostalCodeRegExp.test(postalCode);
      } else if (countryCode === 'MX') {
        isValid = mxPostalCodeRegExp.test(postalCode);
      } else {
        console.log('Unsupported country code');
      }

      return isValid;
    }

    static supportedCountries(countryCode: string) {
      switch (countryCode) {
        case 'CA':
        case 'US':
        case 'MX':
          return true;
        default:
          return false;
      }
    }

    static returnCountryCode(country: string): string {
      let countryCode = '';

      switch (country.toUpperCase().trim()) {
        case 'UNITED STATES':
        case 'US':
        case 'USA':
        case 'U.S.A.':
        case 'UNITED STATES OF AMERICA':
        case 'AMERICA':
          countryCode = 'US';
          break;
        case 'CANADA':
        case 'CA':
        case 'CAN':
        case 'CDN':
        case 'CDN.':
        case 'CANADIAN':
          countryCode = 'CA';
          break;
        case 'MEXICO':
        case 'MX':
        case 'MÉJICO':
        case 'MÉXICO':
          countryCode = 'MX';
          break;
        default:
          countryCode = null;
      }

      return countryCode;
    }
  };
  //********************************************************************************************
  // EOF Address Management
  //********************************************************************************************

  //********************************************************************************************
  // StringUtils
  //********************************************************************************************
  static StringUtils = class {
    static arrayToString(stringArray: string[]) {
      // return comma separated string
      let result = '';
      stringArray.forEach((element) => {
        result += element + ', ';
      });
      return result.substring(0, result.length - 2);
    }

    static stripAccents(str: string) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
  };
  //********************************************************************************************
  // EOF StringUtils
  //********************************************************************************************
}
