/// <reference types="@types/google.maps" />
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AddressModel, CityModel, CountryModel, GeneralUtil, GoogleAddressModel, StateModel } from "@nx-c4g/c4g-models";
import { MapsLoaderService } from "./maps-loader.service";
import { CountriesService } from "./countries.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "nxx-address-autocomplete",
  templateUrl: "./google-places.component.html",
  styleUrls: ["./google-places.component.scss"]
})
export class GooglePlacesComponent implements OnInit, OnChanges {
  @Input() initialAddress: AddressModel | undefined;
  @Output() setAddress: EventEmitter<AddressModel> = new EventEmitter();
  @ViewChild("addresstext") addresstext: any;
  @ViewChild("mapContainer", { static: false }) mapContainer: ElementRef;

  countries: CountryModel[];
  selectedCountry: CountryModel;
  selectedState: StateModel;
  selectedCity: CityModel;
  citySearch: any;

  autocompleteInput: string;
  private geocoder: google.maps.Geocoder;
  map: any;
  marker: any;
  apiLoaded = false;
  geoEnabled = true;
  tempAddress: AddressModel;
  processedAddress: AddressModel = {
    CityStatePostalCode: "",
    Clean: true,
    Type: "",
    Street: "",
    Line3: "",
    City: "",
    State: "",
    stateOrProvinceCode: "",
    PostalCode: "",
    Country: "",
    countryCode: "",
    Latitude: undefined,
    Longitude: undefined
  };
  processedPosition: google.maps.LatLng | undefined;

  dialogRef: MatDialogRef<any> | undefined;
  mapLoaded = false;

  constructor(
    private renderer: Renderer2,
    public dialog: MatDialog,
    private mapsLoaderService: MapsLoaderService,
    private ngZone: NgZone,
    private countriesService: CountriesService,
    private httpClient: HttpClient
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["initialAddress"]) {
      if (!this.initialAddress?.stateOrProvinceCode || this.initialAddress?.stateOrProvinceCode === "") {
        this.initialAddress.stateOrProvinceCode = this.initialAddress?.State;
      }
      this.processedAddress = this.initialAddress;
    }
  }


  async getCurrentLocation(): Promise<google.maps.LatLng> {
    return new Promise<google.maps.LatLng>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            this.geoEnabled = true;
            resolve(location);
          },
          (error) => {
            this.geoEnabled = false;
            reject(error);
          }
        );
      } else {
        this.geoEnabled = false;
        reject("Geolocation is not supported by this browser.");
      }
    });
  }

  async ngOnInit() {

    this.mapsLoaderService.load().then(async () => {
      this.apiLoaded = true;
      console.log("Google Maps API loaded successfully");

    }).catch((err) => {
      this.apiLoaded = false;
      console.error("Failed to load Google Maps API", err);
    });
    this.countries = await this.countriesService.getCountries();

  }

  async onMarkerDragEnd(): Promise<void> {
    console.log("onMarkerDragEnd");
    const location = this.marker.getPosition();
    this.processedPosition = location;
    this.tempAddress.Latitude = location.lat();
    this.tempAddress.Longitude = location.lng();
    await this.addressFromPosition(this.tempAddress);
  }

  addressFromPosition($event: AddressModel) {
    const position = new google.maps.LatLng($event.Latitude, $event.Longitude);
    this.geocoder.geocode({ location: position }, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const address = await this.invokeEvent(results[0]);
          console.log("Drag addressFromPosition", address);
          this.tempAddress.Street = address.Street;
          this.tempAddress.PostalCode = address.PostalCode;
          this.tempAddress.City = address.City;
          this.tempAddress.State = address.State;
          this.tempAddress.Country = address.Country;
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  async invokeEvent(place: google.maps.places.PlaceResult) {
    const googleAddress: GoogleAddressModel = {
      streetNumber: +place.address_components.filter((comp) =>
        comp.types.includes("street_number")
      )[0]?.short_name,
      route: place.address_components.filter((comp) =>
        comp.types.includes("route")
      )[0]?.long_name,
      locality: place.address_components.filter((comp) =>
        comp.types.includes("locality")
      )[0]?.long_name,
      administrativeAreaLevel1: place.address_components.filter((comp) =>
        comp.types.includes("administrative_area_level_1")
      )[0]?.long_name,
      administrativeAreaLevel1Short: place.address_components.filter((comp) =>
        comp.types.includes("administrative_area_level_1")
      )[0]?.short_name,
      country: place.address_components.filter((comp) =>
        comp.types.includes("country")
      )[0]?.long_name,
      countryCode: place.address_components.filter((comp) =>
        comp.types.includes("country")
      )[0]?.short_name,
      postalCode: place.address_components.filter((comp) =>
        comp.types.includes("postal_code")
      )[0]?.long_name,
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat()
    };

    console.log("googleAddress", googleAddress);
    const address: AddressModel = {
      CityStatePostalCode: `${googleAddress.locality}, ${googleAddress.administrativeAreaLevel1} {${googleAddress.postalCode})`,
      Clean: true,
      Type: "",
      countryCode: this.mapsLoaderService.countryNames[googleAddress.country],
      Street:
        googleAddress.streetNumber + " " + googleAddress.route,
      City: googleAddress.locality,
      Country: googleAddress.country,
      State: googleAddress.administrativeAreaLevel1,
      stateOrProvinceCode: googleAddress.administrativeAreaLevel1,
      PostalCode: googleAddress.postalCode,
      Latitude: googleAddress.latitude,
      Longitude: googleAddress.longitude
    };
    return address;
  }


  getAppearance(): MatFormFieldAppearance {
    return "outline";
  }

  openDialog(placeDialog: TemplateRef<any>) {

    const options = {
      componentRestrictions: { country: "ca" },
      // componentRestrictions: { country: ["ca", "us", "pr", "vi", "gu", "mp", "mx"] },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false
    };

    this.tempAddress = GeneralUtil.deepClone(this.processedAddress);
    this.dialogRef = this.dialog.open(placeDialog, {
      width: "500px",
      height: "95vh",
      disableClose: true
    });

    this.dialogRef.afterOpened().subscribe(async () => {
      this.citySearch = "";
      this.selectedCountry = this.countries.find((c) => c.iso2 === this.processedAddress.countryCode);
      this.selectedState = this.selectedCountry?.states.find((s) => s.state_code === this.processedAddress.stateOrProvinceCode);
      this.selectedCity = this.selectedState?.cities.find((c) => c.name === this.processedAddress.City);

      this.geocoder = new google.maps.Geocoder();

      if (this.processedAddress && this.processedAddress.Latitude && this.processedAddress.Longitude) {
        this.processedPosition = new google.maps.LatLng(this.processedAddress.Latitude, this.processedAddress.Longitude);
      }
      await this.setLocation();

      google.maps.event.addListener(this.marker, "dragend", () => {
        this.onMarkerDragEnd();
      });
    });

    this.dialogRef.afterClosed().subscribe(async () => {
      this.dialogRef = undefined;

    });
  }

  async closeDialog(address?: AddressModel) {
    if (address) {
      this.setAddress.emit(this.processedAddress);
    }
    this.dialogRef?.close();
  }

  addressError(addressPart: string | undefined): string {
    if (!addressPart || addressPart.length === 0 || addressPart === "0") {
      return "error";
    }
    return "";
  }

  addressPostalCodeError(addressPart: string | undefined, countryCode: string): string {
    if (!countryCode || countryCode.length === 0) {
      return "error";
    }
    if (!addressPart || addressPart.length === 0 || addressPart === "0" || !GeneralUtil.validatePostalCode(addressPart, countryCode)) {
      return "error";
    }
    return "";
  }

  hasErrors(address: AddressModel, partial = false): boolean {
    if (!partial) {
      return this.addressError(address.Street).length > 0 ||
        this.addressError(address.City).length > 0 ||
        this.addressError(address.State).length > 0 ||
        this.addressError(address.Country).length > 0 ||
        this.addressPostalCodeError(address.PostalCode, address.countryCode).length > 0 ||
        this.addressError(address.Latitude?.toString()).length > 0 ||
        this.addressError(address.Longitude?.toString()).length > 0 ||
        this.addressError(address.timeZone).length > 0;
    } else {
      return this.addressError(address.Street).length > 0 ||
        this.addressError(address.City).length > 0 ||
        this.addressError(address.State).length > 0 ||
        this.addressError(address.Country).length > 0 ||
        this.addressPostalCodeError(address.PostalCode, address.countryCode).length > 0 ||
        this.addressError(address.timeZone).length > 0;
    }
  }

  errorClass() {
    return this.addressError(this.processedAddress?.Street) || this.addressError(this.processedAddress?.City) ||
      this.addressError(this.processedAddress?.State) || this.addressError(this.processedAddress?.Country) ||
      this.addressPostalCodeError(this.processedAddress?.PostalCode, this.processedAddress?.countryCode) || this.addressError(this.processedAddress?.Latitude?.toString()) ||
      this.addressError(this.processedAddress?.Longitude?.toString()) ||
      this.addressError(this.processedAddress?.timeZone);
  }

  addressChanged($event: AddressModel) {
    this.geocoder.geocode({ address: `${$event.Street}, ${$event.City}, ${$event.State}, ${$event.countryCode}, ${$event.PostalCode}` }, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);
        this.marker.setPosition(results[0].geometry.location);
        this.marker.setVisible(true);
        this.processedAddress.Longitude = results[0].geometry.location.lng();
        this.processedAddress.Latitude = results[0].geometry.location.lat();
        this.processedPosition = results[0].geometry.location;

      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  onCountrySelect(value: CountryModel) {
    this.selectedCountry = value;
    this.selectedState = null;
    this.selectedCity = null;
    this.citySearch = null;
    this.tempAddress.Country = this.selectedCountry.name;
    this.tempAddress.countryCode = this.selectedCountry.iso2;
  }

  onStateSelect(value: StateModel) {
    this.selectedState = value;
    this.selectedCity = null;
    this.citySearch = null;
    this.tempAddress.State = this.selectedState.name;
    this.tempAddress.stateOrProvinceCode = this.selectedState.state_code;
  }

  async onCitySelect(value: CityModel) {
    this.selectedCity = value;
    this.citySearch = null;
    this.tempAddress.City = this.selectedCity.name;
    this.tempAddress.timeZone = await this.mapsLoaderService.timeZoneByCoordinates(this.selectedCity.latitude, this.selectedCity.longitude);
    this.tempAddress.Latitude = this.selectedCity.latitude;
    this.tempAddress.Longitude = this.selectedCity.longitude;
    await this.setLocation();
  }

  filteredCities(): CityModel[] {
    const matchingCities: CityModel[] = [];
    if (this.countries && this.citySearch && this.citySearch.length > 2 && this.countries.length) {
      for (const country of this.countries) {
        for (const state of country.states) {
          for (const city of state.cities) {
            if (GeneralUtil.StringUtils.stripAccents(city.name).toLowerCase().includes(GeneralUtil.StringUtils.stripAccents(this.citySearch).toLowerCase())) {
              city["country"] = country;
              city["state"] = state;
              matchingCities.push(city);
            }
          }
        }
      }

      return matchingCities;
    } else {
      return [];
    }

  }

  async onCitySearchSelect($event: { option: { value: CityModel; }; }) {
    if ($event && $event.option && $event.option.value && $event.option.value.name) {
      this.citySearch = $event.option.value.name;
      console.log("onCitySearchSelect", $event);
      this.selectedCountry = $event.option.value.country;
      this.selectedState = $event.option.value.state;
      this.selectedCity = $event.option.value;
      this.tempAddress.Country = this.selectedCountry.name;
      this.tempAddress.countryCode = this.selectedCountry.iso2;
      this.tempAddress.State = this.selectedState.name;
      this.tempAddress.stateOrProvinceCode = this.selectedState.state_code;
      this.tempAddress.City = this.selectedCity.name;
      this.tempAddress.timeZone = await this.mapsLoaderService.timeZoneByCoordinates(this.selectedCity.latitude, this.selectedCity.longitude);
      this.tempAddress.Latitude = this.selectedCity.latitude;
      this.tempAddress.Longitude = this.selectedCity.longitude;
      await this.setLocation();
    }
  }

  save() {
    this.processedAddress = GeneralUtil.deepClone(this.tempAddress);
    this.closeDialog(this.processedAddress);
  }

  cancel() {
    this.closeDialog();
  }

  onStreetChange($event: any) {
    this.tempAddress.Longitude = null;
    this.tempAddress.Latitude = null;
  }

  onPostalCodeChange($event: any) {
    // this.tempAddress.Longitude = null;
    // this.tempAddress.Latitude = null;
  }

  async locate(tempAddress: AddressModel) {
    this.geocoder.geocode({ address: `${tempAddress.Street}, ${tempAddress.City}, ${tempAddress.State}, ${tempAddress.countryCode}, ${tempAddress.PostalCode}` }, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);
        this.marker.setPosition(results[0].geometry.location);
        this.marker.setVisible(true);
        this.tempAddress.Longitude = results[0].geometry.location.lng();
        this.tempAddress.Latitude = results[0].geometry.location.lat();
        this.processedPosition = results[0].geometry.location;
        await this.setLocation();

      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  private async setLocation() {
    const location = (this.tempAddress && this.tempAddress.Latitude !== 0) ? this.getLatLng(this.tempAddress) : await this.getCurrentLocation();
    const mapOptions = {
      center: location,
      zoom: 18
    };
    this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      position: this.map.getCenter(),
      draggable: true
    });
    google.maps.event.addListener(this.marker, "dragend", () => {
      this.onMarkerDragEnd();
    });
    google.maps.event.addListener(this.map, "dblclick", (event: any) => {
      // Create a marker at the clicked position
      // Delete current marker
      this.marker.setMap(null);

      this.marker = new google.maps.Marker({
        position: event.latLng,
        map: this.map,
        title: "Double click marker"
      });
      this.onMarkerDragEnd();
      google.maps.event.addListener(this.marker, "dragend", () => {
        this.onMarkerDragEnd();
      });
    });
    this.mapLoaded = true;
  }

  private getLatLng(tempAddress: AddressModel): google.maps.LatLng {
    return new google.maps.LatLng(tempAddress.Latitude, tempAddress.Longitude);
  }
}
