import { AmountTx } from "./bank/AmountTx";

export enum CustomLineItemType {
  parking = "Parking",
  meal = "Meal",
  bonus = "Bonus",
  other = "Other",
}

export interface CgPaymentCustomLineItemModel {
  _id: string;
  cgId: string;
  csId: string;
  type: CustomLineItemType;
  desc: string;
  amount: AmountTx;
  created: Date;
  invoiced: boolean;
  paid: boolean;
}
