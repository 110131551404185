import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nx-c4g-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrls: ['./booking-item.component.css'],
})
export class BookingItemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
