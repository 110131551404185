import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { HttpClient } from '@angular/common/http';
import { AppointmentExtensionModel } from '@nx-c4g/c4g-models';
import { firstValueFrom, share, shareReplay } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Mutex } from 'async-mutex';
@Injectable({
  providedIn: 'root',
})
export class ExtensionsService {
  mutex = new Mutex();
  private _extensions: AppointmentExtensionModel[] = null;

  public extensions: EventEmitter<AppointmentExtensionModel[]> =
    new EventEmitter<AppointmentExtensionModel[]>();

  constructor(private auth: AuthenticationService, private http: HttpClient) {
    this.auth.initialized.subscribe((v) => {
      this.auth.isLoggedIn().subscribe((user) => {
        if (user) {
          this.getExtensionsOrLoad(this.auth.user.uid, true);
        }
      });
    });
  }

  async getHttp(): Promise<AppointmentExtensionModel[]> {
    await this.auth.waitForInit();

    return firstValueFrom<AppointmentExtensionModel[]>(
      this.http.get<AppointmentExtensionModel[]>(
        environment.api + 'extensions/cg'
      )
    );
  }

  async getExtensionsOrLoad(uid: string, forceReload = false) {
    await this.mutex.runExclusive(async () => {
      if (!this._extensions || forceReload) {
        this._extensions = await this.getHttp();
        console.log(
          `ExtensionsService._getExtensions(${uid})`,
          this._extensions
        );
        this.extensions.emit(this._extensions);
      }
    });
    return this._extensions;
  }

  async acceptExtension(extension: AppointmentExtensionModel) {
    return await firstValueFrom(
      this.http.put<{ ok: boolean; error: string }>(
        environment.api + 'extensions/cg/accept',
        extension
      )
    );
  }

  async declineExtension(extension: AppointmentExtensionModel) {
    return await firstValueFrom(
      this.http.put<{ ok: boolean; error: string }>(
        environment.api + 'extensions/cg/decline',
        extension
      )
    );
  }
}
