<div class="cc-img">
  <img src="/assets/images/cc.png" width="150" />
</div>

<div>
  <p>
    Please note that credit card information and the actual transactions are
    securely processed by Stripe. Care4Giver does not capture or maintain any
    credit card numbers on file.
  </p>
</div>

<div class="group">
  <label>
    <div #cardElement id="card-element" class="field"></div>
  </label>
</div>

<p>{{ cardErrors }}</p>

<button (click)="saveCard()">SAVE</button>
