<div class="notes"
     *ngIf="notes"
     fxLayout="row"
     [fxLayoutGap]="'8px'">
  <div class="info-icon">
    <mat-icon>info</mat-icon>
  </div>
  <div class="notes-content">{{notes}}</div>
</div>
<h2 *ngIf="isAdmin"
    class="admin-mode">ADMIN MODE</h2>
<div *ngIf="checkAddress()">
  <div class="navigator"
       fxLayout="row"
       fxLayout.lt-sm="column"
       fxLayoutAlign="space-between center">
    <nx-c4g-weeks-navigator [currentWeek]="currentWeek"
                            (onCurrentWeekChanged)="onCurrentWeekChanged($event)"></nx-c4g-weeks-navigator>
    <button *ngIf="mode === 'cg'"
            color="primary"
            (click)="onNewAppointmentClick()"
            mat-raised-button>
      {{ 'Availabilities.NEW' | translate }}
    </button>
    <button *ngIf="mode === 'cs' && careSeeker && careGiver"
            color="primary"
            (click)="onNewAppointmentClick()"
            mat-raised-button>
      {{ 'caregivercomponent.BOOK NOW' | translate }}
    </button>
    <button *ngIf="mode === 'cs' && careSeeker.partnerId && careSeeker.partnerId !== null"
            color="accent"
            (click)="collapseAllNotes()"
            mat-raised-button>
      Collapse All Notes
    </button>
    <button *ngIf="mode === 'cs' && careBook"
            color="primary"
            (click)="duplicateWeek()"
            mat-raised-button>
      {{ 'buttons.DuplicateWeek' | translate }}
    </button>
    <button *ngIf="mode === 'cs' && careSeeker && !careGiver"
            color="primary"
            (click)="onNewAppointmentClick()"
            mat-raised-button>
      {{ 'Availabilities.NEW' | translate }}
    </button>
  </div>
  <div class="agenda-container">
    <ng-container *ngIf="!loading">
      <div *ngFor="let apptKey of filteredMap(dataMap, currentWeek) | keyvalue">
        <div fxLayout="row">
          <div class="date-col"
               fxFlex="12"
               fxLayoutAlign="start start">
            {{apptKey.value['date'] | date : 'dd EEE'}}
          </div>
          <div fxLayout="column"
               fxFlex="88">
            <div *ngFor="let appt of apptKey.value['appts']">
              <ng-container *ngIf="appt.type !== 'travelTime'">
                <div [style.background-color]="getBackgroundColor(appt)"
                     [style.color]="getColor(appt)"
                     class="rounded-appt">
                  <nx-c4g-appointment-summary-template #summaryTemplate (click)="open(templateAgenda, appt)"
                                                       [appt]="$any(appt)"
                                                       [isMine]="isItMine(appt)"
                                                       [mode]="mode"
                                                       [careSeeker]="careSeeker"
                                                       (noteAdded)="noteAdded.emit($event)"
                                                       (addedExpansionPanel)="addedExpansionPanel($event)"
                                                       [text]="apptTypeToString(appt.type,appt.status, appt.startDate)">
                  </nx-c4g-appointment-summary-template>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="!checkAddress()">
  <mat-error>{{"BadAddress" | translate}}</mat-error>
</div>
<ng-template #templateAgenda
             let-appt>
  <nx-c4g-appointment-template (onViewCareBook)="viewCareBook($event)"
                               (onViewLovedOne)="viewLovedOne($event)"
                               [appt]="appt"
                               [canViewCareBook]="canViewCareBook"
                               [careSeeker]="careSeeker"
                               [ct]="null"
                               [isMine]="isHis(appt)"
                               [cantDeleteReason]="cantDeleteReason(appt)"
                               [text]="apptTypeToString(appt.type, appt.status, appt.startDate)">
    <div fxLayout="row wrap"
         class="agenda-buttons full-width"
         fxLayoutAlign="end">
      <button (click)="close();"
              mat-raised-button>{{ 'Action.Close' | translate }}
      </button>
      <button (click)="editAppointment(appt);"
              *ngIf="canEdit(appt)"
              mat-raised-button
              color="primary">{{ 'Action.Edit' | translate }}
      </button>
      <button (click)="extend(appt);"
              *ngIf="canExtend(appt)"
              mat-raised-button
              color="accent">{{ 'Action.Extend' | translate }}
      </button>
      <button (click)="viewExtensionRequests(appt);"
              *ngIf="appt.extensionRequest && mode !== appt.extensionRequest.mode"
              mat-raised-button
              color="accent">{{ 'Action.viewExtension' | translate }}
      </button>
      <ng-container [ngSwitch]="appt.status">
        <ng-container *ngSwitchCase="'blocked'">
          <button (click)="removeBlock(appt); close();"
                  color="warn"
                  *ngIf="isMine"
                  mat-raised-button>{{ 'Action.Remove' | translate }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'booking'">
          <ng-container *ngIf="mode === 'cg'">
            <button (click)="acceptBooking(appt); close();"
                    color="primary"
                    mat-raised-button>{{ 'Action.Accept' | translate }}
            </button>
            <button (click)="rejectBooking(appt); close();"
                    color="warn"
                    mat-raised-button>{{ 'Action.Reject' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="mode === 'cs' && isHis(appt)">
            <button (click)="deleteGroupedBooking(appt); close();"
                    color="warn"
                    mat-raised-button>{{ 'Action.Cancel' | translate }}
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'careBookEntryUnfulfilled'">
          <ng-container *ngIf="mode === 'cs'">
            <button (click)="cancelCbBooking(appt); close();"
                    color="warn"
                    mat-raised-button>{{ 'Action.Cancel' | translate }}
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'careBookEntryBooked' ">
          <ng-container *ngIf="mode === 'cs'">
            <button (click)="clearCbBooking(appt); close();"
                    color="warn"
                    *ngIf="canDelete(appt)"
                    mat-raised-button>{{ 'Action.Clear' | translate }}
            </button>
            <button (click)="cancelCbBooking(appt); close();"
                    color="warn"
                    *ngIf="canDelete(appt)"
                    mat-raised-button>{{ 'Action.Cancel' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="mode === 'cg'">
            <button (click)="cancelCbBookingByCg(appt); close();"
                    color="warn"
                    mat-raised-button>{{ 'Action.Cancel' | translate }}
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <button (click)="deleteBooking(appt); close();"
                  *ngIf="canDelete(appt)"
                  color="warn"
                  mat-raised-button>{{ 'Action.Cancel' | translate }}
          </button>
        </ng-container>
      </ng-container>

    </div>


  </nx-c4g-appointment-template>
</ng-template>
