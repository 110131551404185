import { auth } from 'firebase-admin';
import UserRecord = auth.UserRecord;

export class NewAuthUserModel {
  user: UserRecord;
  partnerId: string;
  resetLink: string;
}

export class NewCSPartnerModel {
  email: string;
}
