import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from './confirmed.validator';

@Component({
  selector: 'nx-c4g-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
})
export class EmailValidationComponent implements OnInit {
  public loginForm: FormGroup;
  public hide = true;

  mode: any;
  private oobCode: any;
  private apiKey: any;
  private lang: any;
  private source: string;

  constructor(
    public fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    public auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        passwordAgain: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
      },
      CustomValidators.mustMatch('password', 'passwordAgain')
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.mode = params.mode;
      this.oobCode = params.oobCode;
      this.apiKey = params.apiKey;
      this.lang = params.lang;
      this.source = params.source;
      if (this.mode === 'verifyEmail') {
        this.auth.handleVerifyEmail(
          this.mode,
          this.oobCode,
          this.apiKey,
          this.lang
        );
      }
    });
  }

  async cgLogin() {
    window.location.href = environment.cgLogin;
  }

  async csLogin() {
    window.location.href = environment.csLogin;
  }

  onSubmitChangePassword(value: any) {
    this.auth.handlePasswordChange(
      this.mode,
      this.oobCode,
      this.loginForm.controls.password.value
    );
    if (this.source === 'cs') {
      window.location.href = environment.csLogin;
    }
    this.mode = 'PasswordChanged';
  }
}
