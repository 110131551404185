import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'nx-c4g-why-early-or-late-dialog',
  templateUrl: './WhyEarlyOrLateDialog.component.html',
  styleUrl: './WhyEarlyOrLateDialog.component.scss',
})
export class WhyEarlyOrLateDialogComponent implements OnInit  {
  reason = '';

  constructor(public dialogRef: MatDialogRef<WhyEarlyOrLateDialogComponent>) {}

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
        if (event.key === "Escape") {
            this.onCancel();
        }
    });

    this.dialogRef.backdropClick().subscribe(event => {
        this.onCancel();
    });
}

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    this.dialogRef.close(this.reason);
  }
}
