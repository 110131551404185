export const environment = {
  dbVersion: 4,
  production: false,
  type: 'STAGING',
  firebaseConfig: {
    apiKey: 'AIzaSyBWhuqQvHEjNi2s07R7vfVjjcLCTGn_Nh8',
    authDomain: 'care4giver-app.firebaseapp.com',
    databaseURL: 'https://care4giver-app.firebaseio.com',
    projectId: 'care4giver-app',
    storageBucket: 'care4giver-app.appspot.com',
    messagingSenderId: '441623843943',
    appId: '1:441623843943:web:ee85c5019b708848efb0c2',
    measurementId: 'G-7BBLKT7Q5W',
  },
  fbCloud:
    'AAAAZtLVFGc:APA91bHLltpUNyXJK97Ef8A20NpMSC3rC--Z83-cexvan2BRHl7uvTc8TSA_HIaifncz-B5X_muYtyhAXtGyBVdivImPbvSpuvgg8AgHUXEMDbW4hFa0u74CRRWQ3YEDHjmT_N16jiIl',
  api: 'https://webhook-umcl27v3ta-ue.a.run.app/',
  client: 'https://caregivers.staging.care4giver.com/',
  csLogin: 'https://careseeker-app.firebaseapp.com/login',
  cgLogin: 'https://caregivers.staging.care4giver.com/login',
  cmstoken: 'af1b3966b757e3a6c29be9afa5ec583b2499d33c',
  stripe: 'pk_test_OocrQmulntS8pZwq7uNXVBpe00OpxKb2gr',
  pusher: {
    key: '5d447265bb5235cd1881',
    cluster: 'us2',
  },
};
