import { Menu } from './menu.model';

export const horizontalMenuItems = [
  new Menu(1, 'Menu.Home', '/', null, null, false, 0),
  new Menu(1, 'Menu.MyProfile', '/care-givers/me', null, null, false, 0),
  new Menu(
    1,
    'Menu.MyAvailabilities',
    '/care-givers/me/availabilities',
    null,
    null,
    false,
    0
  ),
];

export const verticalMenuItems = [
  new Menu(1, 'Menu.Home', '/', null, null, false, 0),
  new Menu(1, 'Menu.MyProfile', '/care-givers/me', null, null, false, 0),
  new Menu(
    1,
    'Menu.MyAvailabilities',
    '/care-givers/me/availabilities',
    null,
    null,
    false,
    0
  ),
];
