<ng-template #bsTemplate id="bsTemplate">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <ng-container [ngSwitch]="type">
      <div fxFlex="5">
      <mat-icon *ngSwitchCase="'error'">error</mat-icon>
      <mat-icon *ngSwitchCase="'warning'">warning</mat-icon>
      <mat-icon *ngSwitchDefault >info</mat-icon>
      </div>
    </ng-container>
    <h2 fxFlex="95" [innerHTML]="message"></h2>

  </div>
</ng-template>
