<mat-card class="property-item p-0"
          [class.full-width-page]="fullWidthPage"
          fxLayout="row wrap">
  <div fxFlex="100"
       fxShrink="4"
       [fxFlex.gt-sm]="viewType == 'list' ? 40 : 100"
       [fxFlex.sm]="viewType == 'list' ? 50 : 100"
       class="thumbnail-section">

    <div fxLayout="row"
         fxLayoutAlign="center center">
      <div class="mat-card-image w-100 m-0"
           fxLayoutAlign="center center">
        <a [routerLink]="['/care-givers', careGiver._id]">
          <img class="center-cropped"
               [src]="
              careGiver.PleaseUploadARecentPhotoOfYourself &&
              careGiver.PleaseUploadARecentPhotoOfYourself[0]
                ? careGiver.PleaseUploadARecentPhotoOfYourself[0].File
                : '/assets/images/avatars/avatar.png'
            " />
        </a>
        <div class="form-group">
          <input style="display: none"
                 type="file"
                 id="file"
                 accept="image/png, image/jpeg"
                 (change)="handleFileInput($event)"
                 #hiddenfileinput />
        </div>
        <div class="circle-icon">
          <mat-icon class="camera-icon"
                    (click)="hiddenfileinput.click()">camera_alt
          </mat-icon>
        </div>
      </div>
    </div>
    <div class="full-width" fxLayout="row" fxLayoutAlign="center">
      <button class=""
              [ngClass]="checkAddressClass()"
              mat-raised-button
              color="accent"
              (click)="editBasicInfo()">
        {{"editBasicInfo" | translate}}
      </button>
    </div>
  </div>
  <div fxFlex="100"
       [fxFlex.gt-sm]="viewType == 'list' ? 60 : 100"
       [fxFlex.sm]="viewType == 'list' ? 50 : 100"
       class="p-3 c4g-card-content">
    <div fxLayout="column"
         fxLayoutAlign="start stretch"
         class="h-100">
      <mat-card-content class="mb-0">
        <div fxLayout="row"
             fxLayoutAlign="space-between center">
          <ng-container>
            <h4 class="title">
              <a [routerLink]="['/care-givers', careGiver._id]">{{ getStrippedName(careGiver.Name) }}
                {{
                careGiver.caregiverType
                  ? '(' + careGiver.caregiverType + ')'
                  : ''
                }}</a>
            </h4>
          </ng-container>

          <ng-container>
            <p fxLayout="column"
               class="address">
              <span>{{ careGiver.Address?.City }},
                {{ careGiver.Address?.State }}</span>
              <mat-error *ngIf="!checkAddress()">{{"BadAddress" | translate}}</mat-error>
            </p>
          </ng-container>
        </div>
        <div class="w-100"
             fxLayout="row"
             fxLayoutAlign="center center">
          <div class="divider"></div>
        </div>
        <div *ngIf="canServe(); then serviceBlock; else noServiceBlock"></div>

        <ng-template #noServiceBlock>
          <h5 class="font-weight-bold warning">
            Sorry this service is not available at this location
          </h5>
        </ng-template>

        <ng-template #serviceBlock>
          <div fxLayoutAlign="end center" fxLayoutAlign.lt-sm="center center" class="mt-2"
               fxLayout="row wrap">
            <button class=""
                    mat-raised-button
                    color="accent"
                    (click)="editInfo()">
              {{"editEditServicesandSkills" | translate}}
            </button>
          </div>

          <h6>{{ 'CareGiverInfo.qualifications' | translate }}</h6>
          <div class="c4g-chip-list">
            <div *ngFor="
                let service of careGiver.ServicesSkillsQualifications.qualificationsOther
              ">
              <c4g-chip>{{ getQualificationsOther(service) }}</c4g-chip>
            </div>
          </div>
          <h6>{{ 'CareGiverInfo.services' | translate }}</h6>
          <div class="c4g-chip-list">
            <div *ngFor="
                let service of careGiver.ServicesSkillsQualifications.ServicesSkills
              ">
              <c4g-chip *ngIf="getService(service)">{{ getService(service) }}</c4g-chip>
            </div>
          </div>
          <h6>{{ 'CareGiverInfo.skills' | translate }}</h6>
          <div class="c4g-chip-list">
            <div *ngFor="
                let service of careGiver.ServicesSkillsQualifications.ServicesSkills
              ">
              <c4g-chip *ngIf="getSkill(service)">{{ getSkill(service) }}</c4g-chip>
            </div>
          </div>

          <div class="w-100 mt-3"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>
          <h5>{{ 'residence.label' | translate }}</h5>

          <div class="c4g-chip-list">
            <c4g-chip>{{ careGiver.caregiverType }}</c4g-chip>
            <c4g-chip *ngIf="careGiver.residence">{{
              'residence.residence' | translate
              }}</c4g-chip>
            <c4g-chip *ngIf="careGiver.residenceApproved">{{
              'residence.approved' | translate
              }}</c4g-chip>
            <div class="w-100 mt-3"
                 fxLayout="row"
                 fxLayoutAlign="center center">
              <div class="divider"></div>
            </div>
          </div>

          <div fxLayout="row"
               fxLayoutAlign="space-between">
            <ng-container>
              <div>
                <h6 class="price">
                  {{
                  careGiver.AreasServedRateAndConditions.CGHourlyRate
                    | currency: 'CAD':'symbol':'1.2'
                  }}
                  /h
                </h6>
              </div>
            </ng-container>
            <!--                    <app-rating [ratingsCount]="care-giver.ratingsCount" [ratingsValue]="care-giver.ratingsValue"></app-rating>-->
          </div>

          <div class="w-100"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <div *ngIf="careGiver.AreasServedRateAndConditions?.CGBio"
               class="pt-2 pb-2 small-bold">
            {{ careGiver.AreasServedRateAndConditions.CGBio }}
          </div>

          <div *ngIf="careGiver.AreasServedRateAndConditions?.CGBio"
               class="w-100"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <div class="c4g-chip-list mb-3 mt-3">
            <div *ngFor="
                let service of careGiver.LanguagesSpoken
              ">
              <c4g-chip>{{ getLanguages(service) }}</c4g-chip>
            </div>

          </div>
          <div class="w-100"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <!--          <h3-->
          <!--            *ngIf="careGiver.CGCertifications-->
          <!--          && careGiver.CGCertifications.length >0 ">-->
          <!--            Certifications-->
          <!--          </h3>-->
          <!--          <div class="c4g-chip-list">-->
          <!--            <div *ngFor="let cert of careGiver.CGCertifications">-->
          <!--              <c4g-chip>{{cert}}</c4g-chip>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="c4g-chip-list  mt-3">
            <div *ngIf="careGiver.RnNo">
              <c4g-chip>{{ 'RnNo' | translate }}: {{ careGiver.RnNo }}</c4g-chip>
            </div>
            <div *ngIf="careGiver.HealthcareAttendantNo">
              <c4g-chip>{{ 'PABNo' | translate }}:
                {{ careGiver.HealthcareAttendantNo }}</c4g-chip>
            </div>
          </div>

          <div class="w-100 mt-3"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <div>
            <h3 class="detail-title">
              trained to help an elder in and out of a bed or chair
            </h3>
            <div>
              {{ careGiver.AreasServedRateAndConditions.CGTrainedOutOfBed }}
            </div>
          </div>

          <div class="w-100 mt-3"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <div>
            <h3 class="detail-title">Special Interests:</h3>
            <div class="c4g-chip-list">
              <div *ngFor="
                  let interest of careGiver.AreasServedRateAndConditions
                    .CGSpecialInterests
                ">
                <c4g-chip>{{ interest }}</c4g-chip>
              </div>
            </div>
          </div>

          <div class="w-100 mt-3"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <div>
            <h3>Pets: Cannot enter a home with a:</h3>
            <div class="c4g-chip-list">
              <div *ngFor="
                  let pet of careGiver.AreasServedRateAndConditions
                    .CGPetsCannotEnter
                ">
                <c4g-chip>{{ pet }}</c4g-chip>
              </div>
            </div>
          </div>

          <div class="w-100 mt-3"
               fxLayout="row"
               fxLayoutAlign="center center">
            <div class="divider"></div>
          </div>

          <div>
            <h3 class="detail-title">Other Restrictions</h3>
            <div>
              {{ careGiver.AreasServedRateAndConditions.OtherRestrictions }}
            </div>
          </div>
        </ng-template>
        <!--          <div class="detail-item" fxFlex="100" fxFlex.gt-xs="50" fxLayout="row">-->
        <!--            <span class="detail-title">Pets: Cannot enter a check-in-out with a:</span>-->
        <!--            <div class="list" fxLayout="row wrap">-->
        <!--          								<span-->
        <!--                            *ngFor="let pet of careGiver.AreasServedRateAndConditions.CGPetsCannotEnter; let last=last"-->
        <!--                            [class.last]="last"-->
        <!--                            [class.list-item]="careGiver.AreasServedRateAndConditions.CGPetsCannotEnter.length > 1">{{pet}}-->
        <!--                          </span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="detail-item" fxFlex="100" fxFlex.gt-xs="50" fxLayout="row">-->
        <!--            <span class="detail-title">Smoker's House:</span>-->
        <!--            <span>{{careGiver.AreasServedRateAndConditions.CGSmokersHouse}}</span>-->
        <!--          </div>-->
      </mat-card-content>
      <div class="sticky-footer-helper"
           fxFlex="grow"></div>
    </div>
  </div>
</mat-card>

<!--    </mat-card>-->

<!--  </div>-->
<!--</section>-->
