import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoginDeeplinkService {
  constructor() {}

  get(): string {
    const lastURL = localStorage.getItem('lastURL');
    return lastURL;
  }

  set(url: string) {
    localStorage.setItem('lastURL', url);
  }
}
