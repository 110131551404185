<div
     class="multi">ID: {{appt._id}}

</div>

<div fxLayout="row"
     class="p-1"
     fxLayoutAlign="space-between">
  <div fxLayout="row"
       fxLayoutGap="8px">
    <div *ngIf="allRejected()">
      <mat-icon class="big-icon">do_not_disturb_on</mat-icon>
    </div>
    <div fxLayout="column">
      <div class="mr-4">
        <b>{{ text }}</b>
        <ng-container *ngIf="mode==='cs'">
          @switch(appt.visibility) {
          @case("1") { <span>({{"team.allFav" | translate}})</span> }
          @case("2") { <span>({{"team.allSub" | translate}})</span> }
          @case("3") { <span>({{"team.both" | translate}})</span> }
          @case("4") { <div class="inline cg-names">(<div class="inline"
                 [innerHTML]="getCGNames(appt)"></div>)</div> }
          }
        </ng-container>
      </div>

      <div class="dropdown-appointment-dates mr-4">
        {{
        (appt.startDate | date: sameDay(appt.startDate, appt.endDate) ? 'shortTime' : 'EEEE, h:mm a') +
        ' - ' +
        (appt.endDate | date: sameDay(appt.startDate, appt.endDate) ? 'shortTime' : 'EEEE, h:mm a')
        }}
      </div>
      <ng-container *ngIf="appt.remainingSubsidizedHours !== null && appt.remainingSubsidizedHours !== undefined">
        {{"appointment.subsidized" | translate}} : {{appt.remainingSubsidizedHours}}
      </ng-container>
    </div>
    <div fxLayout="column">
      <div *ngIf="mode==='cs' && appt.caregiverName"
           class="cg-name">
        <b>{{ getStrippedNameStr(appt.caregiverName) }}</b>
      </div>
      <div *ngIf="mode!=='cs' && isMine || !isMine && isHis(appt)"
           class="lo-list">
        <div *ngFor="let lo of appt.lovedOnesPassports">
          <span class="lo-name">patient: {{ getStrippedName(lo.LovedOnePersonalInfo.Name) }}
            .</span>
        </div>
      </div>

    </div>
  </div>
  <div fxLayout="column">
    <div class="pill in"
         *ngIf="appt.checkInOut && appt.checkInOut.checkIn">{{appt.checkInOut.checkIn.time | date : 'shortTime'}}</div>
    <div class="pill out"
         *ngIf="appt.checkInOut && appt.checkInOut.checkOut">{{appt.checkInOut.checkOut.time | date : 'shortTime'}}
    </div>
    <div class="pill error"
           *ngIf="shouldBeIn()">LATE! NOT CHECKED IN
      </div>
      <div class="pill error"
           *ngIf="shouldBeOut()">LATE! NOT CHECKED OUT
      </div>
      <div class="pill error-location"
           *ngIf="appt.checkInOut?.checkIn?.farDistanceReasonProvided">FAR CHECK-IN: Reason:
        {{ appt.checkInOut?.checkIn?.farDistanceReason }}
      </div>
      <div class="pill error"
           *ngIf="appt.checkInOut?.checkIn?.earlyOrLate">EARLY/LATE CHECK-IN: Reason:
        {{ appt.checkInOut?.checkIn?.earlyOrLateReason }}
      </div>
      <div class="pill error-location"
           *ngIf="appt.checkInOut?.checkOut?.farDistanceReasonProvided">FAR CHECK-OUT: Reason:
        {{ appt.checkInOut?.checkOut?.farDistanceReason }}
      </div>
      <div class="pill error"
           *ngIf="appt.checkInOut?.checkOut?.earlyOrLate">EARLY/LATE CHECK-OUT: Reason:
        {{ appt.checkInOut?.checkOut?.earlyOrLateReason }}
      </div>

  </div>

</div>

<div class="p-3"
     *ngIf="mode==='cs' && careSeeker.partnerId && careSeeker.partnerId !== null">
  <!-- Stop click propagation -->
  <c4g-communication-notes [appt]="appt"
                           (click)="stopPropagation($event)"
                           (addedExpansionPanel)="addedExpansionPanelHandler($event)"
                           (noteAdded)="noteAdded.emit($event)">
  </c4g-communication-notes>
</div>
