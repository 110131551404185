import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NotificationMessage, NotifyService } from "./notify.service";

@Component({
  selector: 'c4g-notify-drawer',
  templateUrl: './notify-drawer.component.html',
  styleUrls: ['./notify-drawer.component.scss'],

})
export class NotifyDrawerComponent implements OnInit, OnDestroy {
  @ViewChild('bsTemplate') bsTemplate = {} as TemplateRef<any>;
  subscription: any;
  public type = "";
  public message = "";

  constructor(
    private notify: NotifyService,
    private _bottomSheet: MatDialog
  ) {
    console.log('NotifyDrawerComponent Ctor');
  }

  ngOnInit(): void {
    this.subscription = this.notify.getNotification().subscribe((event: any) => this.handleEvent(event));
    console.log('ngOnInit', this.subscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  private handleEvent(event: NotificationMessage) {
    this.type = event.type;
    this.message = event.message;
    const ref = this._bottomSheet.open(this.bsTemplate, {
      panelClass: ['notify', `notify-${event.type}`],
      id: 'c4g-notify-drawer',
      hasBackdrop: true,
      position: {
        bottom: '10px'
      },
      width: '80vw',
    });
    setTimeout(()=>{ ref?.close() }, event.delay);
  }
}


