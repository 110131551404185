import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import { Directive, Input } from "@angular/core";

export function timeValidator(): ValidatorFn {
  return (control: AbstractControl):  ValidationErrors | null  => {
    const value = control.value;
    if (!value) {
      return null; // don't validate empty values
    }
    const timeRegex = /^(0?[1-9]|1[0-9]|2[0-3]):([0-5]\d)\s*(a|p)?\.?m?\.?$/i;
    const valid = timeRegex.test(value);
    return valid ? null : {'invalidTime': {value: value}};
  };
}

@Directive({
  selector: '[c4gTimeValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: TimeValidatorDirective, multi: true}]
})
export class TimeValidatorDirective implements Validator {
  @Input() value = '';

  validate(control: AbstractControl): ValidationErrors | null {
    let value: string = control.value;

    if (!value) {
      return null; // don't validate empty values
    }
    value = value.trim();
    if (value.toLowerCase().includes('am') || value.toLowerCase().includes('pm')) {
      const timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9]\s?(am|pm)$/i;
      const valid = timeRegex.test(value);
      return valid ? null : {'invalidTime': {value: control.value}};

    } else {
      const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      const valid = timeRegex.test(value);
      return valid ? null : {'invalidTime': {value: control.value}};

    }

  }
}
