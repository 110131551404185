import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {AppointmentModel, AppointmentStatusModel, AppointmentTypesModel, DurationModel, PricingUtil} from '@nx-c4g/c4g-models';
import {BookingUtil, DateUtil} from "@nx-c4g/c4g-models";

export class AvailabilityValidator {
  public schedulerData: any = [];



  public setAppointmentData(schedulerData: any[]) {
    this.schedulerData = schedulerData;
  }

  public onlyIfAvailable(): ValidatorFn {
    return (control: AbstractControl) => {
      const durationModel: DurationModel = control.get('durationCtrl').value;

      if (!durationModel) {
        return null;
      }

      if (BookingUtil.isPast(durationModel.start)) {
        return {available: true};
      }

      const appt: AppointmentModel = new AppointmentModel();
      appt.startDate = durationModel.start;
      appt.endDate = durationModel.end;
      appt.hours = PricingUtil.roundToTwoDecimals(durationModel.duration / 60);
      appt.text = '';
      appt.allDay = false;
      appt.description = '';
      appt.disabled = false;
      appt.type = AppointmentTypesModel.careBookEntry;
      appt.status = AppointmentStatusModel.careBookEntryUnfulfilled
      appt.address =  durationModel.address;
      appt.careseeker = null;
      appt.caregiver = null;
      appt.caregiverName = null;
      appt.careGiverEmail = '';
      appt.careSeekerEmail = '';
      appt.careseekerName = '';
      appt.lovedOnesPassports = [];
      // appt.lovedOnes = [];
      appt._id = '';
      appt.resource = '';
      if (!this.availableInTimeSlot(appt)) {
        console.log('NOT availableInTimeSlot');
        return {available: true};
      } else {
        return null;
      }
    };
  }

  availableInTimeSlot(scheduleData: any): boolean {
    // Check if appt in the past
    // Get appointments during that day
    const apptsAtDate: AppointmentModel[] = DateUtil.apptsAroundDate(
      this.schedulerData,
      scheduleData.startDate
    );
    // if (apptsAtDate.length === 0) {
    //   console.error('No availabilities for that date');
    //   return false;
    // }

    // Check if the appointment.ts overlaps other appointments.
    const apptsOverlaps: any[] = DateUtil.apptsOverlaps(
      apptsAtDate,
      scheduleData.startDate,
      scheduleData.endDate,
      scheduleData
    );

    if (apptsOverlaps.length > 0) {
        return false;
    }

    return true;
  }
}
