import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLangService } from './services/current-lang.service';
import { AuthenticationService } from './services/authentication-service/authentication.service';
import { App } from '@capacitor/app';
import { TestDataServiceService } from './services/test-data-service.service';
import { GeneralUtil } from '@nx-c4g/c4g-models';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;
// eslint-disable-next-line @typescript-eslint/ban-types
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'admin';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  goodDbVersion = false;

  isMobileApp = Capacitor.isNativePlatform();
  hasNotificationPermission = false;
  loadingNotif = true;
  loadingVersion = true;

  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private auth: AuthenticationService,
    private translate: TranslateService,
    private currentLangService: CurrentLangService,
    private testDataServiceService: TestDataServiceService,
  ) {
    // this.setupIdleTimeout();
    // check if mobile using capacitor
    if (this.isMobileApp) {
      console.log('is mobile app');
      this.setupNotificationsChecker();
    } else {
      console.log('is NOT mobile app');
    }

    this.appSettings.checkVersion().then((result) => {
      // check if mobile
      this.goodDbVersion = result;
      this.loadingVersion = false;
    });

    // every 10 minutes
    setInterval(() => {
      this.appSettings.checkVersion().then((result) => {
        // check if mobile
        this.goodDbVersion = result;
      });
    }, 600000);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        fbq('track', 'PageView');

        gtag('config', 'UA-187118822-1', {
          page_path: '/C4G-APP/' + event.urlAfterRedirects,
        });
      }
    });

    this.settings = this.appSettings.settings;
    translate.setDefaultLang('en');
    const lang = localStorage.getItem('lang');
    if (lang) {
      translate.use(JSON.parse(lang).code);
      currentLangService.use(JSON.parse(lang).code);
    } else {
      translate.use('en');
      currentLangService.use('en');
    }
  }

  needsNotifications(): boolean {
    return this.isMobileApp && !this.hasNotificationPermission;
  }

  openSettings() {
    NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.App,
    });
  }

  async setupNotificationsChecker() {
    setInterval(() => {
      this.checkPermissions();
    }, 5000);
  }

  async checkPermissions() {

    // using capacitor notification plugin
    let permStatus = await PushNotifications.checkPermissions();
    console.log('notification permission', permStatus.receive);
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
      this.hasNotificationPermission = permStatus.receive === 'granted';
      this.loadingNotif = false;
    }
    this.hasNotificationPermission = permStatus.receive === 'granted';
    this.loadingNotif = false;

  }

  async checkVersion() {
    return await this.appSettings.checkVersion();
  }

  ngAfterViewInit() {
    this.testDataServiceService.testDataService().then((result) => {
      if (!result) {
        alert(this.translate.instant('connection-error'));
        this.router.navigate(['/error-page']);
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    });
  }
}
