import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import {
  CareGiverModel,
  CareSeekerModel,
  CareTeamTiers, GeneralUtil, NameModel, ServicesSkillsQualificationsList,
} from '@nx-c4g/c4g-models';
import { AppSettings, Settings } from '../../app.settings';

import { AppService } from '../../app.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { CareseekerProfileService } from '../../services/profile/careseeker-profile.service';
import {CurrentLangService} from "../../services/current-lang.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-caregiver-item-detailed',
  templateUrl: './caregiver-item-detailed.component.html',
  styleUrls: ['./caregiver-item-detailed.component.scss'],
})
export class CaregiverItemDetailedComponent{
  @Input() careGiver: CareGiverModel;
  @Input() viewType = 'grid';
  @Input() viewColChanged = false;
  @Input() fullWidthPage = true;
  @Output() editBasicInfoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editInfoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editPhotoEvent: EventEmitter<any> = new EventEmitter<any>();

  public column = 4;
  public settings: Settings;
  // public csProfile: CareSeekerModel = null;

  constructor(
    public appSettings: AppSettings,
    public auth: AuthenticationService,
    private careseekerProfileService: CareseekerProfileService,
    public appService: AppService,
    private lang: CurrentLangService
  ) {
    this.settings = this.appSettings.settings;
    // this.auth.userData.subscribe((userData) => {
    //   this.careseekerProfileService
    //     .getProfile(this.auth.user.uid)
    //     .then((profile) => {
    //       console.log('careseekerProfile', profile);
    //       this.csProfile = profile;
    //     });
    // });
  }


  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.viewColChanged) {
      this.getColumnCount(changes.viewColChanged.currentValue);

    }

    for (let propName in changes) {
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.care-giver.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;
      //     this.directiveRef.update();
      //   }
      // }
    }
  }

  public getColumnCount(value) {
    if (value == 25) {
      this.column = 4;
    } else if (value == 33.3) {
      this.column = 3;
    } else if (value == 50) {
      this.column = 2;
    } else {
      this.column = 1;
    }
  }

  public getStatusBgColor(status) {
    switch (status) {
      case 'For Sale':
        return '#558B2F';
      case 'For Rent':
        return '#1E88E5';
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default:
        return '#01579B';
    }
  }

  canServeCSS() {
    return true;
  }

  canServe() {
    // const distance = (this.careGiver?.AreasServedRateAndConditions?.KmMiles.toLowerCase() === 'km') ?
    //   this.careGiver.distance.kilometers : this.careGiver.distance.kilometers;
    //
    // return (distance <=  this.careGiver?.AreasServedRateAndConditions.ServiceRadius);
    return true;
  }

  public get careTeamTiers(): typeof CareTeamTiers {
    return CareTeamTiers;
  }



  editBasicInfo() {
    this.editBasicInfoEvent.emit();
  }

  editInfo() {
    this.editInfoEvent.emit();
  }

  handleFileInput($event: Event) {
    this.editPhotoEvent.emit($event);
  }

  getQualificationsOther(service: string) {
    const svc = ServicesSkillsQualificationsList.qualificationsOther.find(v => v.value === service);
    return this.lang.currentLang === 'en' ? svc.en : svc.fr;

  }

  getService(service: string) {
    const svc = ServicesSkillsQualificationsList.services.find(v => v.value === service);
    if (!svc) {
      return null;
    }
    return this.lang.currentLang === 'en' ? svc.en : svc.fr;
  }

  getSkill(service: string) {
    const svc = ServicesSkillsQualificationsList.skills.find(v => v.value === service);
    if (!svc) {
      return null;
    }
    return this.lang.currentLang === 'en' ? svc.en : svc.fr;

  }

  getLanguages(service: string) {
    const svc = ServicesSkillsQualificationsList.languages.find(v => v.value === service);
    return this.lang.currentLang === 'en' ? svc.en : svc.fr;

  }

  getStrippedName(Name: NameModel) {
    return GeneralUtil.stripLastName(Name);
  }

  checkAddressClass() {
    if (!GeneralUtil.checkAddress(this.careGiver.Address)) {
      return 'bad-address';
    }
    return '';
  }

  checkAddress() {
    return GeneralUtil.checkAddress(this.careGiver.Address);
  }
}
