import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddressModel } from '@nx-c4g/c4g-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  constructor(private http: HttpClient) {}

  getGeoFromAddress(address: AddressModel): Observable<AddressModel> {
    return this.http
      .get(
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          address.PostalCode +
          '+' +
          address.State +
          '+' +
          address.Country +
          '&key=AIzaSyDTB_Kc6MONyqCpwFvEP0aYk0iD_0Ix0hs'
      )
      .pipe(
        map((geo) => {
          address.Longitude = geo['results'][0]?.geometry.location.lng;
          address.Latitude = geo['results'][0]?.geometry.location.lat;
          console.log('getGeoFromAddress', geo, address);
          return address;
        })
      );
  }
}
