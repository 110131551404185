<a mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
  <span class="mx-1 uppercase menu-name">{{ 'Menu.Account' | translate }}</span>
  <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
</a>
<mat-menu
  #userMenu="matMenu"
  [overlapTrigger]="false"
  xPosition="before"
  class="app-menu-panel user-menu"
>
  <span (mouseleave)="userMenuTrigger.closeMenu()">

    <mat-accordion (click) = "$event.stopPropagation()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div *ngIf="user && careGiver" class="info-box">
              <p>{{ careGiver.Name.FirstAndLast }}</p>
              <p>{{ careGiver.Email }}</p>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a mat-menu-item
           class="warning"
           (click)="deleteAccount()">
          <mat-icon class="mat-icon-sm">cancel</mat-icon>
          <span>{{ 'Menu.DeleteAccount' | translate }}</span>
        </a>
      </mat-expansion-panel>

    </mat-accordion>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/bank">
      <span>Transactions</span>
    </a>

    <a mat-menu-item routerLink="/care-givers/me">
      <mat-icon class="mat-icon-sm">home</mat-icon>
      <span>{{ 'Menu.MyProfile' | translate }}</span>
    </a>
    <a
      mat-menu-item
      href="https://care4giver.atlassian.net/servicedesk/customer/portals"
      target="_blank"
    >
      <mat-icon class="mat-icon-sm">help_outline</mat-icon>
      <span>{{ 'Menu.Support' | translate }}</span>
    </a>

    <ng-container *ngIf="isAdmin()">
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/bank">
      <span>Login On Behalf</span>
    </a>
  </ng-container>

    <mat-divider></mat-divider>
    <a *ngIf="user" mat-menu-item routerLink="/login/logout">
      <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
      <span>{{ 'signout' | translate }}</span>
    </a>
  </span>
</mat-menu>
