import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {Capacitor} from "@capacitor/core";

if (environment.production) {
  enableProdMode();
}

if (Capacitor.isNativePlatform()) {
  StatusBar.setOverlaysWebView({overlay: false});
  StatusBar.setStyle({style: Style.Dark});

  const showStatusBar = async () => {
    await StatusBar.show();
  };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
