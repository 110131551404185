<h1 mat-dialog-title>{{"bookingEx.extendTitle" | translate}}</h1>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="8px" class="time-row">
    <p>{{"bookingEx.by" | translate}}</p>
    <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
      <mat-label>{{"bookingEx.Hours" | translate}}</mat-label>
      <mat-select
        [(ngModel)]="selectedHours"
      >
        <mat-option *ngFor="let hour of hours" [value]="hour.value">
          {{ hour.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
      <mat-label>Minutes</mat-label>
      <mat-select
        [(ngModel)]="selectedMinute"
      >
        <mat-option *ngFor="let minute of minutes" [value]="minute.value">
          {{ minute.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" *ngIf="data.appt.type !== 'blocked'">
    <p>{{"bookingEx.reasonsTitle" | translate}}</p>
    <mat-form-field [appearance]="'outline'" [floatLabel]="'always'" class="full-width">
      <mat-label>{{"bookingEx.reason" | translate}}</mat-label>
      <mat-select
        [(ngModel)]="reason"
      >
        <mat-option *ngFor="let reason of reasons" [value]="reason">
          {{ getReasonLabel(reason) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="reason && reason.value === 'other'">
    <mat-form-field
      [appearance]="'outline'"
      [floatLabel]="'always'"
      fxFlex="100"
    >
      <mat-label>{{"bookingEx.reasonsOtherLabel" | translate}}</mat-label>
      <textarea
        matInput
        [(ngModel)]="otherReason"
        rows="4"
        class="text-area"
      ></textarea>
    </mat-form-field>
  </div>
  <div *ngIf="reason && reason.value === 'tasks'">
    <c4g-tasks
      [(tasks)]="newTasks"
    >
    </c4g-tasks>
  </div>

  <ng-container *ngIf="data.mode === 'cs'">
    <div class="mt-4"></div>
    <div fxLayout="row">
      <div>
        <div *ngIf="data.balance !== null">
          bal: {{data.balance | currency}}
        </div>
        <div>
          cost: {{calculateCost() | currency}}
        </div>
      </div>
      <div *ngIf="data.balance < calculateCost()">
        <button
          class="ml-2"
          mat-raised-button
          (click)="deposit()"
        >
          {{"bookingPay.deposit" | translate}} {{ calculateCost() - data.balance | currency }}
        </button>
      </div>
    </div>

  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button *ngIf="requestType === 'request'" (click)="okDialog()" [disabled]="!isValid()">
    {{"bookingEx.request" | translate}}
  </button>
  <button mat-button *ngIf="requestType === 'direct'" (click)="okDialog()" [disabled]="!isValid()">
    {{"bookingEx.ok" | translate}}
  </button>
  <button mat-button color="warn" (click)="cancelDialog()">{{"bookingEx.cancel" | translate}}</button>

</div>
