import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";
import { notify } from "@nx-c4g/c4g-ui";
import { AgendaService } from "../agenda.service";
import { GetPaymentInfoComponent } from "../get-payment-info/get-payment-info.component";

@Component({
  selector: 'nx-c4g-deposit-dialog',
  templateUrl: './deposit-dialog.component.html',
  styleUrls: ['./deposit-dialog.component.scss'],
})
export class DepositDialogComponent  {
  amount: number;
  type = 'cc';
  public careSeeker: any;
  bookingFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DepositDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private agendaService: AgendaService,
  ) {
    this.careSeeker = data.careSeeker;

    if (data.amount) {
      this.amount = data.amount;
    }
  }


  async getCC() {
    // check if customer and CreditCardService are setup
    // if customer doesn't exist, create
    if (
      !this.careSeeker.stripe_account ||
      this.careSeeker.stripe_account === ''
    ) {
      const stripeAccount = await this.agendaService.createCustomerAccount(
        this.careSeeker
      );
      this.careSeeker.stripe_account = stripeAccount;
    }

    // if CreditCardService doesn't exist, create
    // if (!this.csProfile.stripe_cc
    //   || this.csProfile.stripe_cc === undefined
    //   || this.csProfile.stripe_cc === '') {
    const dialogRef = this.dialog.open(GetPaymentInfoComponent, {
      panelClass: 'cc-dialog',
      disableClose: false,
      data: { customer: this.careSeeker },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Stripe', result);
        this.careSeeker.last4 = result.source.card.last4;
        this.careSeeker.exp_mo = result.source.card.exp_month;
        this.careSeeker.exp_y = result.source.card.exp_year;
      }
    });
    // }
    // if cancel, return
  }


  async confirmCC() {
    const bank = await this.agendaService.depositCC(this.amount);
    if (bank) {
      notify('Deposit has been successful', 'info');
    }
    this.dialogRef.close(true);
  }
}
