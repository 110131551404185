import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'c4g-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent {
  @Input() tasks!: string[];
  @Output()
  tasksChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  newTask = '';
  timeout: any = null;

  newTaskClicked() {
    console.log('Added task', this.newTask);
    this.tasks.push(this.newTask);
    this.newTask = '';
    this.tasksChange.emit(this.tasks);
  }

  remove(task: string) {
    const index = this.tasks.indexOf(task, 0);
    if (index > -1) {
      this.tasks.splice(index, 1);
      this.tasksChange.emit(this.tasks);
    }
  }

}
