import {ExceptionZonesEntityModel} from "./ExceptionZonesEntityModel";

export class AreasServedRateAndConditionsModel {
  CGHourlyRate: number;
  CGSmokersHouse: string;
  CGTrainedOutOfBed: string;
  CGSpecialInterests: string[] = [];
  CGPetsCannotEnter: string[] = [];
  CGBio: string;
  ServiceRadius: number;
  KmMiles: string;
  ExceptionZones?: ExceptionZonesEntityModel[] | null;
  ZonesYouCannotServe: boolean;
  CGBlankcheck?: null[] | null;
  ShortestBookingYouAreWillingToAccept: string;
  OtherInterests: string;
  OtherRestrictions?: string;
}

export class AreasServedRateAndConditionsSmallModel {
  CGHourlyRate: number;
  CGSmokersHouse: string;
  CGTrainedOutOfBed: string;
}
