import { Component, Input } from "@angular/core";
import { LovedOneCareNeedsAssessmentModel, NeedsAndLevelOfAutonomyModel } from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-lo-need',
  templateUrl: './lo-need.component.html',
  styleUrls: ['./lo-need.component.scss'],
})
export class LoNeedComponent  {
  @Input() jsonElement: NeedsAndLevelOfAutonomyModel;
  @Input() notes: any;
  @Input() address: any;
  @Input() assessment: LovedOneCareNeedsAssessmentModel;
  @Input() typeOfCareAndNotes: string;
  @Input() hidePersonalInfo = false;


  getClass(name: any) {
    if (name && typeof name === 'string' && name !== '') {
      const newName = name.replaceAll('.', '-').toLowerCase();
      return newName;
    }
    return '';
  }
}
