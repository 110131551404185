<div class="section default">
  <div class="px-3">
    <div class="theme-container">
      <h1 class="section-title">Our Mission</h1>

      <mat-card class="p-0" fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-md="50" class="p-3">
          <div fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
              <mat-icon color="primary" class="mat-icon-xlg"
                >monetization_on</mat-icon
              >
              <h2 class="capitalize fw-600 my-2">save money</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
                dolor magnam, facilis voluptas quia excepturi provident
                cupiditate.
              </p>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
              <mat-icon color="primary" class="mat-icon-xlg">thumb_up</mat-icon>
              <h2 class="capitalize fw-600 my-2">better ideas</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
                dolor magnam, facilis voluptas quia excepturi provident
                cupiditate.
              </p>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
              <mat-icon color="primary" class="mat-icon-xlg">group</mat-icon>
              <h2 class="capitalize fw-600 my-2">collaboration</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
                dolor magnam, facilis voluptas quia excepturi provident
                cupiditate.
              </p>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
              <mat-icon color="primary" class="mat-icon-xlg">search</mat-icon>
              <h2 class="capitalize fw-600 my-2">easy to find</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae
                dolor magnam, facilis voluptas quia excepturi provident
                cupiditate.
              </p>
            </div>
          </div>
        </div>
        <div fxFlex="100" fxFlex.gt-md="50" fxShow="false" fxShow.gt-md>
          <img
            src="assets/images/others/mission.jpg"
            alt="mission"
            class="mw-100 d-block"
          />
        </div>
      </mat-card>
    </div>
  </div>
</div>
