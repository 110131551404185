import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AppointmentModel,
  CheckInModel,
  GeneralUtil,
} from '@nx-c4g/c4g-models';
import { notify } from '@nx-c4g/c4g-ui';

import { Plugins } from '@capacitor/core';
import { CheckInsService } from '../../services/check-ins/check-ins.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { WhySoFarDialogComponent } from './WhySoFarDialog.component';
import { TestDataServiceService } from '../../services/test-data-service.service';
import { WhyEarlyOrLateDialogComponent } from './WhyEarlyOrLateDialog.component';

const { Geolocation } = Plugins;

@Component({
  selector: 'nx-c4g-in-out-appointment',
  templateUrl: './in-out-appointment.component.html',
  styleUrls: ['./in-out-appointment.component.scss'],
})
export class InOutAppointmentComponent implements OnChanges {
  @Input() appointment: AppointmentModel;

  displayedAppointment: AppointmentModel;
  inDisabled = false;
  outDisabled = false;
  init = false;

  constructor(
    private dialog: MatDialog,
    private checkInsService: CheckInsService,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private cd: ChangeDetectorRef,
    private testDataServiceService: TestDataServiceService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appointment && !this.init) {
      this.displayedAppointment = JSON.parse(JSON.stringify(this.appointment));
      this.init = true;
    }
  }

  get name() {
    return GeneralUtil.stripLastName(
      this.displayedAppointment.lovedOnesPassports[0].LovedOnePersonalInfo.Name,
    );
  }

  async getInOutModel(): Promise<CheckInModel> {
    let coordinates;
    try {
      coordinates = await Geolocation.getCurrentPosition({
        timeout: 10000,
        enableHighAccuracy: true,
        maximumAge: 1,
      });
      if (!coordinates) {
        console.error('Location error, no coordinates returned by API');
        notify(this.translate.instant('location-error'), 'error');
        alert(this.translate.instant('location-error'));
        return null;
      }
    } catch (error) {
      console.error('Location error, no coordinates returned by API', error);
      notify(this.translate.instant('location-error'), 'error');
      alert(this.translate.instant('location-error'));
      return null;
    }

    const latitude = coordinates.coords.latitude;
    const longitude = coordinates.coords.longitude;
    console.log('Appointment', this.appointment);
    const checkInModel: CheckInModel = {
      appointmentId: this.appointment._id,
      careGiverId: this.auth.user.uid,
      careGiverName: this.appointment.caregiverName,
      careSeekerId: this.appointment.careseeker,
      careSeekerName: this.appointment.careseekerName,
      careBookId:
        this.appointment.lovedOnesPassports[0] !== null
          ? this.appointment.careseeker +
            this.appointment.lovedOnesPassports[0].Id
          : null,
      position: {
        Latitude: latitude,
        Longitude: longitude,
      },
    };

    return checkInModel;
  }

  info() {
    // Open google map in a new tab with the location of the appointment
    const apptLocation = { Latitude: 0, Longitude: 0 };
    apptLocation.Latitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Latitude;
    apptLocation.Longitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Longitude;
    const url = `https://www.google.com/maps/search/?api=1&query=${apptLocation.Latitude},${apptLocation.Longitude}`;
    window.open(url, '_blank');
  }

  haversine_distance(mk1, mk2) {
    let R = 6371.071; // Radius of the Earth in km
    let rlat1 = mk1.Latitude * (Math.PI / 180); // Convert degrees to radians
    let rlat2 = mk2.Latitude * (Math.PI / 180); // Convert degrees to radians
    let difflat = rlat2 - rlat1; // Radian difference (latitudes)
    let difflon = (mk2.Longitude - mk1.Longitude) * (Math.PI / 180); // Radian difference (longitudes)

    let d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2),
        ),
      );
    return d;
  }

  async openWhySoFarDialog(): Promise<string | null> {
    const dialogRef = this.dialog.open(WhySoFarDialogComponent, {
      width: '400px',
    });

    const result = await dialogRef.afterClosed().toPromise();
    return result;
  }

  async openEarlyLaterDialog(): Promise<string | null> {
    const dialogRef = this.dialog.open(WhyEarlyOrLateDialogComponent, {
      width: '400px',
    });

    const result = await dialogRef.afterClosed().toPromise();
    return result;
  }

  async in() {
    const testData = await this.testDataServiceService.testDataService();
    if (!testData) {
      alert(this.translate.instant('connection-error-checkin'));
      return;
    }

    this.inDisabled = true;
    const checkInModel = await this.getInOutModel();
    if (!checkInModel) {
      this.inDisabled = false;
      return;
    }
    const apptLocation = { Latitude: 0, Longitude: 0 };
    apptLocation.Latitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Latitude;
    apptLocation.Longitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Longitude;

    // Check if early or late (more than 15 minutes)
    const now = new Date();
    const startTime = new Date(this.appointment.startDate);
    const diff = Math.abs(now.getTime() - startTime.getTime());
    const minutes = Math.floor(diff / 60000);
    console.log('MINUTES', minutes);
    if (minutes > 15) {
      const reason = await this.openEarlyLaterDialog();
      if (reason !== null && reason !== '') {
        checkInModel.earlyOrLate = true;
        checkInModel.earlyOrLateReason = reason;
      } else {
        notify('You have to specify a reason, please retry', "error");
        this.inDisabled = false;
        return;
      }
    }

    const distance = this.haversine_distance(
      apptLocation,
      checkInModel.position,
    );
    console.log('DISTANCE', distance);
    if (distance > 0.2) {
      const reason = await this.openWhySoFarDialog();
      if (reason !== null && reason !== '') {
        checkInModel.farDistanceReasonProvided = true;
        checkInModel.farDistanceReason = reason;
        await this.commitIn(checkInModel);
      } else {
        notify(
          `You must be at the target to Check-In or Out, you are currently ${distance.toFixed(
            1,
          )} km away and didn't provide a reason`,
          'error',
        );
      }

      this.inDisabled = false;
    } else {
      await this.commitIn(checkInModel);
    }
  }

  private async commitIn(checkInModel: CheckInModel) {
    try {
      const checkInOutModel = await this.checkInsService.in(checkInModel);
      this.displayedAppointment.checkInOut = checkInOutModel;
      this.inDisabled = false;
      this.cd.detectChanges();
      notify('Check-In successful', 'info');
    } catch (ex) {
      this.inDisabled = false;
      notify(ex['error'], 'error');
    }
  }

  async out() {
    const testData = await this.testDataServiceService.testDataService();
    if (!testData) {
      alert(this.translate.instant('connection-error-checkin'));
      return;
    }

    this.outDisabled = true;

    const checkInModel = await this.getInOutModel();
    if (!checkInModel) {
      this.inDisabled = false;
      return;
    }

    // Check if early or late (more than 15 minutes)
    const now = new Date();
    const endTime = new Date(this.appointment.endDate);
    const diff = Math.abs(now.getTime() - endTime.getTime());
    const minutes = Math.floor(diff / 60000);
    console.log('MINUTES', minutes);
    if (minutes > 15) {
      const reason = await this.openEarlyLaterDialog();
      if (reason !== null) {
        checkInModel.earlyOrLate = true;
        checkInModel.earlyOrLateReason = reason;
      } else {
        this.outDisabled = false;
        return;
      }
    }

    const apptLocation = { Latitude: 0, Longitude: 0 };
    apptLocation.Latitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Latitude;
    apptLocation.Longitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Longitude;

    const distance = this.haversine_distance(
      apptLocation,
      checkInModel.position,
    );
    console.log('DISTANCE', distance);
    if (distance > 0.2) {
      const reason = await this.openWhySoFarDialog();
      if (reason !== null) {
        checkInModel.farDistanceReasonProvided = true;
        checkInModel.farDistanceReason = reason;
        await this.commitOut(checkInModel);
      } else {
        notify(
          `You must be at the target to Check-In or Out, you are currently ${distance.toFixed(
            1,
          )} km away and didn't provide a reason`,
          'error',
        );
      }

      this.outDisabled = false;
    } else {
      await this.commitOut(checkInModel);
    }
  }

  private async commitOut(checkInModel: CheckInModel) {
    try {
      const checkInOutModel = await this.checkInsService.out(checkInModel);
      this.displayedAppointment.checkInOut = checkInOutModel;
      this.cd.detectChanges();
      this.outDisabled = false;
      notify('Check-Out successful', 'info');
    } catch (ex) {
      this.outDisabled = false;
      notify(ex['error'], 'error');
    }
  }
}
