<form [formGroup]="parentFormGroup">
  <div fxLayout="row wrap">
    <mat-form-field
      fxFlex.gt-md="33.3"
      fxFlex.gt-sm="50"
      fxFlex.sm="100"
      fxFlex.lt-sm="100"
      class="p-2"
      [appearance]="'outline'"
      [floatLabel]="'always'"
    >
      <mat-label>{{ 'Form1.FirstName' | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'Form1.FirstName' | translate"
        [formControlName]="'First'"
        required
      />
      <mat-error *ngIf="parentFormGroup.controls.First.errors?.required"
        >required</mat-error
      >
    </mat-form-field>
    <mat-form-field
      fxFlex.gt-md="33.3"
      fxFlex.gt-sm="50"
      fxFlex.sm="100"
      fxFlex.lt-sm="100"
      class="p-2"
      [appearance]="'outline'"
      [floatLabel]="'always'"
    >
      <mat-label>{{ 'Form1.LastName' | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'Form1.LastName' | translate"
        [formControlName]="'Last'"
        required
      />
      <mat-error *ngIf="parentFormGroup.controls.Last.errors?.required"
        >required</mat-error
      >
    </mat-form-field>
    <mat-form-field
      fxFlex.gt-md="33.3"
      fxFlex.gt-sm="100"
      fxFlex.sm="100"
      fxFlex.lt-sm="100"
      class="p-2"
      [appearance]="'outline'"
      [floatLabel]="'always'"
    >
      <mat-label>{{ 'Form1.Phone' | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'Form1.Phone' | translate"
        [formControlName]="'Phone'"
        required
      />
      <mat-error *ngIf="parentFormGroup.controls.Phone.errors?.required"
        >required</mat-error
      >
    </mat-form-field>
    <mat-form-field
      fxFlex="50"
      fxFlex.gt-sm="50"
      fxFlex.sm="50"
      class="p-2"
      [appearance]="'outline'"
      [floatLabel]="'always'"
    >
      <mat-label>{{ 'Form3.CGHourlyRate' | translate }}</mat-label>
      <input
        matInput
        type="number"
        [formControlName]="'CGHourlyRate'"
        required
      />
      <mat-error *ngIf="parentFormGroup.controls.CGHourlyRate.errors?.required"
        >required</mat-error
      >
    </mat-form-field>
    <!--    <mat-form-field fxFlex="50" fxFlex.gt-sm="50" fxFlex.sm="50" class="p-2" hidden-->
    <!--                    [appearance]="'outline'" [floatLabel]="'always'">-->
    <!--      <mat-label>{{"Form3.calculatedRate" | translate}}</mat-label>-->
    <!--      <input matInput [value]="getCalculatedRate(CGHourlyRate.value)" type="number" [formControlName]="'calculatedRate'"-->
    <!--             readonly/>-->
    <!--    </mat-form-field>-->
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <mat-form-field
      fxFlex="50"
      fxFlex.gt-sm="50"
      fxFlex.sm="50"
      [appearance]="'outline'"
      [floatLabel]="'always'"
      class="p-2"
    >
      <mat-label>{{ 'Form1.CaregiverType' | translate }}</mat-label>
      <mat-select [formControlName]="'CaregiverType'">
        <mat-option *ngFor="let qualification of qualificationsStandard" [value]="qualification.value">
          {{ lang === 'en' ? qualification.en : qualification.fr }}
        </mat-option>

      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <mat-radio-group
      fxFlex="100"
      fxFlex.gt-sm="100"
      fxFlex.sm="100"
      formControlName="Residence"
      required
    >
      <mat-label  class="floating-label mr-2">{{ 'Form1.Residence' | translate }}</mat-label>
      <mat-radio-button [value]="true">{{
        'Form2.Yes' | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="false">{{
        'Form2.No' | translate
      }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <hr />

  <div fxLayout="column">
    <nxx-address-autocomplete
      fxFlex="33.3"
      fxFlex.lt-md="100"
      id="autocomplete"
      (setAddress)="customAddress($event)"
      [initialAddress]="careGiver?.Address"
    ></nxx-address-autocomplete>

  </div>

</form>
