<div fxLayout="row wrap">
  <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100" class="p-2">
    <mat-form-field [appearance]="'outline'" fxFlex="100">
      <mat-label>{{ 'tasks.New Task' | translate }}</mat-label>
      <input
        matInput
        placeholder="Enter task"
        [(ngModel)]="newTask"
        class="input-with-inline-button"
        (keyup.enter)="newTaskClicked()"
      />
      <button mat-button (click)="newTaskClicked()" class="inline-button">
        {{ 'tasks.Add' | translate }}
      </button>
    </mat-form-field>
  </div>
</div>
<div fxLayout="row" *ngIf="tasks">
  <h5>{{ 'tasks.Tasks' | translate }}</h5>
  <div class="card-tasks p-2">
    <div role="list" class="list">
      <div
        role="listitem"
        *ngFor="let task of tasks"
        class="list-item"
      >
        <div>{{ task }}</div>
        <button
          mat-icon-button
          (click)="remove(task)"
          class="list-button"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


