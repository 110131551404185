<mat-toolbar id="top-toolbar" color="primary">
  <div
    class="theme-container full-width"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="space-between" class="full-width">
      <!--      <app-currency></app-currency>-->
      <span fxHide="false" fxHide.gt-sm>
        <button mat-button (click)="sidenavToggle()">
          <mat-icon class="menu-icon">menu</mat-icon>
        </button>
      </span>
      <div></div>
      <div fxLayoutAlign="end end">
        <app-lang></app-lang>
        <app-user-menu  *ngIf="auth.isLoggedIn() | async"></app-user-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
<mat-toolbar fxHide.lt-md id="main-toolbar" class="mat-elevation-z2" fxShow.gt-sm="true" xLayout="row wrap"
             fxLayoutAlign="space-between center">
    <a class="logo" routerLink="/">
      <app-logo></app-logo>
    </a>
    <div class="horizontal-menu">
      <a
        *ngIf="auth.isLoggedIn() | async"
        mat-button
        [routerLink]="['/']"
        routerLinkActive="horizontal-active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="uppercase">{{ 'Menu.Home' | translate }}</span>
      </a>
      <a
      *ngIf="auth.isLoggedIn() | async"
      mat-button
      [routerLink]="['/dashboard']"
      routerLinkActive="horizontal-active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="uppercase">{{ 'Menu.dashboard' | translate }}</span>
    </a>
      <a
        *ngIf="auth.isLoggedIn() | async"
        mat-button
        [routerLink]="['/care-givers/me']"
        routerLinkActive="horizontal-active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="uppercase">{{ 'Menu.MyProfile' | translate }}</span>
      </a>
      <a
        *ngIf="auth.isLoggedIn() | async"
        mat-button
        [routerLink]="['/extensions']"
        routerLinkActive="horizontal-active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span [matBadge]="extensions?.length + invitationCount" matBadgeColor="warn" class="uppercase" [matBadgeHidden]="extensions?.length + invitationCount === 0">{{ 'Menu.invitations' | translate }}</span>
      </a>
      <a
        *ngIf="auth.isLoggedIn() | async"
        mat-button
        [routerLink]="['/availabilities']"
        routerLinkActive="horizontal-active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="uppercase">{{ 'Menu.Availabilities' | translate }}</span>
      </a>
      <a
        *ngIf="auth.isLoggedIn() | async"
        mat-button
        [routerLink]="['/check-ins']"
        routerLinkActive="horizontal-active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="uppercase">{{ 'Menu.CheckIns' | translate }}</span>
      </a>
      <a
        *ngIf="auth.isLoggedIn() | async"
        mat-button
        [routerLink]="['/teams']"
        routerLinkActive="horizontal-active-link"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="uppercase">{{ 'Menu.Teams' | translate }}</span>
      </a>
    </div>
</mat-toolbar>
