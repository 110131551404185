import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppointmentModel, CheckInNoteModel } from '@nx-c4g/c4g-models';

@Component({
  selector: 'c4g-check-in-notes',
  templateUrl: './check-in-notes.component.html',
  styleUrl: './check-in-notes.component.scss',
})
export class CheckInNotesComponent {
  note: string;

  constructor(
    public dialogRef: MatDialogRef<CheckInNotesComponent>,
  ) {

  }


  save() {
    this.dialogRef.close(this.note);
  }
}
