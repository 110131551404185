import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { tap } from 'rxjs/operators';
import { LoginDeeplinkService } from '../../services/login-deeplink/login-deeplink.service';

@Injectable()
export class LoggedInGuard  {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private loginDeeplinkService: LoginDeeplinkService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
    return new Promise<boolean>(async (resolve, reject) => {
      let user = await firstValueFrom(this.authService.isLoggedIn());

      if (user) {
        resolve(true);
      } else {
        this.loginDeeplinkService.set(state.url);
        await this.router.navigateByUrl('login');
        resolve(false);
      }
    });
  }
}
