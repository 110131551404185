import {Type} from "class-transformer";

export class CleanModel {
  _id: string;
  dateDiff: number;
  @Type(() => Date)
  apptStartDate: Date;
  @Type(() => Date)
  sevenDaysAgo: Date;
  apptId: string;
  type: string;
}
