import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'nx-c4g-why-so-far-dialog',
  templateUrl: './WhySoFarDialog.component.html',
  styleUrl: './WhySoFarDialog.component.scss',
})
export class WhySoFarDialogComponent implements OnInit {
  reason = '';

  constructor(public dialogRef: MatDialogRef<WhySoFarDialogComponent>) {}

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    this.dialogRef.close(this.reason);
  }
}
