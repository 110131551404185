import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { MeetModel, MeetRequestModel } from "@nx-c4g/c4g-models";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MeetService {

  constructor(
    private http: HttpClient,

  ) { }

  async getCGMeetRequest(id: string) {
    let result;
    try {
      result = await firstValueFrom<MeetRequestModel>(this.http.get<MeetRequestModel>(`${environment.api}meet/cg-meet-request/${id}`));
      result.timeSlots.map((timeSlot) => {
        timeSlot.startDate = new Date(timeSlot.startDate);
        timeSlot.endDate = new Date(timeSlot.endDate);
      });
    } catch (error) {
      console.error(error);
      result = null;
    }
    return  result;
  }

  async setMeetRequestResponse(meetRequest: MeetRequestModel) {
    let result;
    try {
      result = await firstValueFrom<MeetRequestModel>(this.http.put<MeetRequestModel>(`${environment.api}meet/cg-meet-request/${meetRequest._id}`, meetRequest));
    } catch (error) {
      console.error(error);
      result = null;
    }
    return  result;
  }
}
