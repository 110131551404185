import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, ReplaySubject, firstValueFrom, shareReplay } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import firebase from 'firebase/compat';
import User = firebase.User;
import { LoginDeeplinkService } from '../login-deeplink/login-deeplink.service';
import {
  GoogleAnalyticsService,
  GTMEventloginFailure,
  GTMEventloginSuccess,
  GTMEventlogout,
} from '../google-analytics.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { Title } from '@angular/platform-browser';

import {
  ActionPerformed,
  DeliveredNotifications,
  PushNotificationSchema,
  PushNotifications,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { App } from '@capacitor/app';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userData: Observable<User>;
  loggedInUser = false;
  user: any = null;
  device: string;
  initialized = new ReplaySubject<boolean>();

  stateChange: EventEmitter<any> = new EventEmitter<any>();

  private _idToken: string = null;

  public get idToken(): string {
    return this._idToken;
  }

  public set idToken(theToken: string) {
    this._idToken = theToken;
  }

  isAdmin$: Observable<boolean>;
  private _isAdmin: boolean = null;

  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
    private loginDeeplinkService: LoginDeeplinkService,
    private angularFireAuth: AngularFireAuth,
    private translateService: TranslateService,
    public gaService: GoogleAnalyticsService,
    private angularFireMessaging: AngularFireMessaging,
    private titleService: Title,
    private router: Router
  ) {
    this.isAdmin$ = this.http
      .get<boolean>(environment.api + 'admin/isAdmin')
      .pipe(shareReplay(1));


    this.isLoggedIn()
      .pipe(
        tap(async (user) => {
          if (user) {
            this.user = user;
            this.user.getIdToken(false).then((token) => {
              this.gaService.pushTag(GTMEventloginSuccess);
              this.idToken = token;
              setInterval(() => {
                console.log('Refresh ID Token');
                this.user.getIdToken(false).then((rtoken) => {
                  this.idToken = rtoken;
                });
              }, 50 * 60 * 1000); // Refresh Token every 50 minutes
            });
            this.loggedInUser = true; // do something
            // this._isAdmin = await firstValueFrom(this.isAdmin$);
            // console.log('isAdmin', this._isAdmin);
            console.log('Initializing HomePage');

            if (Capacitor.isNativePlatform()) {
              await this.setupPushNotification();
            }
            this.sleepDetect();
            this.initialized.next(true);
          } else {
            this.user = null;
            this.loggedInUser = false;
            this.idToken = null;
            this.initialized.next(true);
            // do something else
          }
        })
      )
      .subscribe();
    // this.angularFireAuth.onAuthStateChanged((user) => {
    //   if (user && user.emailVerified) {
    //     console.log('Email is verified');
    //   } else if (user) {
    //     console.log('Email is not verified');
    //     user.sendEmailVerification();
    //   }
    // }).then(r => console.log('onAuthStateChanged', r));

    this.userData = angularFireAuth.authState;
  }

  async getUserIfLoggedIn() {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.authState.subscribe({
        next: async (user) => {
          if (!user) {
            this.user = null;
            this.loggedInUser = false;
            this.idToken = null;
            reject('User not logged in');
          }
          const token = await user.getIdToken(true);
          this.idToken = token;
          this.loggedInUser = true; // do something
          resolve(user);
        },
        error: async (err) => {
          this.user = null;
          this.loggedInUser = false;
          this.idToken = null;
          reject(err);
        },
      });
    });
  }

  async waitForInit() {
    return firstValueFrom(this.initialized);
  }

  // Add caching for isAdmin

  isAdmin(): boolean {
    return this._isAdmin;
  }

  private lastSleep = Date.now();

  private async setupPushNotification() {
    console.log('Push registration Init V2');

    if (Capacitor.getPlatform() === 'android') {
      console.log('App state changed setup');
      App.addListener('appStateChange', async ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
        if (isActive) {
          const notificationList: DeliveredNotifications =
            await PushNotifications.getDeliveredNotifications();
          for (const notification of notificationList.notifications) {
            console.log(
              'delivered notifications',
              notification.title,
              notification.body
            );
          }
        }
      });
    }

    console.log('addListener: registration');
    await PushNotifications.addListener('registration', (token) => {
      console.log('Push Registration token: ', token.value);
      this.saveDevice(token.value);
    });

    console.log('addListener: registrationError');
    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Push Registration error: ', err.error);
    });

    console.log('addListener: pushNotificationReceived');
    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push notification received: ', notification);

        if (Capacitor.getPlatform() !== 'web') {
          console.log(
            'Push notification action performed',
            notification.click_action,
            notification.data
          );
          this.performClickAction(notification);
        }
      }
    );

    console.log('addListener: pushNotificationActionPerformed');
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
        this.performAction(notification);
      }
    );

    console.log('PushNotifications.checkPermissions()');
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      console.log('PushNotifications.requestPermissions()');
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      return;
    }

    console.log('PushNotifications.register()');
    await PushNotifications.register();

    const getDeliveredNotifications = async () => {
      const notificationList =
        await PushNotifications.getDeliveredNotifications();
      console.log('Push delivered notifications', notificationList);
    };
  }

  private sleepDetect() {
    this.lastSleep = Date.now();

    setInterval(() => {
      let now = Date.now();
      if (now - this.lastSleep > 31000) {
        console.log('Refresh ID Token after sleep');
        window.location.reload();
      }
      this.lastSleep = now;
    }, 30000);
  }

  isLoggedIn() {
    return this.angularFireAuth.authState;
  }

  /* Sign up */
  SignUp(email: string, password: string) {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  handleVerifyEmail(mode, oobCode, apiKey, lang) {
    console.log('Mode', mode);
    console.log('oobCode', oobCode);
    console.log('apiKey', apiKey);
    console.log('lang', lang);
    if (mode === 'verifyEmail') {
      this.angularFireAuth.applyActionCode(oobCode).then((result) => {
        console.log('applyActionCode', result);
      });
    }
  }

  handlePasswordChange(mode, oobCode, newpass) {
    if (mode === 'resetPassword') {
      this.angularFireAuth
        .confirmPasswordReset(oobCode, newpass)
        .then((result) => {
          console.log('applyActionCode', result);
        });
    }
  }

  /* Sign in */
  signInOnBehalf(behalftoken: any): Promise<boolean> {
    this.stateChange.emit(true);
    return new Promise<boolean>((resolve, reject) => {
      this.angularFireAuth
        .signInWithCustomToken(behalftoken)
        .then(async (res) => {
          this.userData = this.angularFireAuth.authState;
          this.gaService.pushTag(GTMEventloginSuccess);
          if (this.loginDeeplinkService.get() !== '') {
            const url = this.loginDeeplinkService.get();
            this.loginDeeplinkService.set(''); // Clear last URK
            this.router.navigateByUrl(url);
          } else {
            this.router.navigateByUrl('/');
          }
          resolve(true);
        })
        .catch((err) => {
          this.gaService.pushTag(GTMEventloginFailure);
          resolve(false);
        });
    });
  }

  SignIn(email: string, password: string): Promise<boolean> {
    email = email.toLowerCase().trim();
    this.stateChange.emit(true);

    return new Promise<boolean>((resolve, reject) => {
      this.angularFireAuth
        .signInWithEmailAndPassword(email, password)
        .then(async (res) => {
          localStorage.setItem(
            environment.production ? 'prt' : 'rt',
            res.user.refreshToken
          );

          if (res && res.user && !res.user.emailVerified) {
            console.log('Email is not verified');
            await res.user.sendEmailVerification();
          }
          this.userData = this.angularFireAuth.authState;
          this.gaService.pushTag(GTMEventloginSuccess);
          if (this.loginDeeplinkService.get() !== '') {
            const url = this.loginDeeplinkService.get();
            this.loginDeeplinkService.set(''); // Clear last URK
            this.router.navigateByUrl(url);
          } else {
            this.router.navigateByUrl('/');
          }
          resolve(true);
        })
        .catch((err) => {
          this.gaService.pushTag(GTMEventloginFailure);
          resolve(false);
        });
    });
  }

  /* Sign out */
  SignOut() {
    if (this.userData) {
      this.userData = null;
    }
    this.stateChange.emit(true);
    localStorage.removeItem(environment.production ? 'prt' : 'rt');
    this.gaService.pushTag(GTMEventlogout);
    this.angularFireAuth.signOut();
    this.router.navigateByUrl('/login');
  }

  ForgotPassword(email: string) {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  saveDevice(device: string) {
    if (device) {
      console.log('Save Device', device);
      this.http
        .put(environment.api + 'care-giver/device', { device: device })
        .subscribe();
    }
  }

  private performAction(notification: ActionPerformed) {
    console.log('notification performAction', notification);
    if (notification.notification.data.type) {
      switch (notification.notification.data.type) {
        case 'Extension-Request':
          {
            const extensionId = notification.notification.data.extensionId;
            this.ngZone.run(() => {
              this.router.navigateByUrl('/extensions');
            });
          }
          break;
        case 'check-in':
          this.ngZone.run(() => {
            this.router.navigateByUrl('/');
          });

          break;
        case 'check-in-late':
          this.router.navigateByUrl('/');
          break;
        case 'check-out':
          this.router.navigateByUrl('/');
          break;
        case 'check-out-late':
          this.router.navigateByUrl('/');
          break;
        case 'Booking-Changed':
          this.router.navigateByUrl(
            '/appt-view/' +
              notification.notification.data.bookingId +
              '/' +
              notification.notification.data.apptId
          );
          break;
      }
    }
  }

  performClickAction(notification: PushNotificationSchema) {
    switch (notification.data.type) {
      case 'Extension-Request':
        {
          const extensionId = notification.data.extensionId;
          const result = confirm(
            this.translateService.instant('extensionRequest')
          );
          if (result) {
            this.ngZone.run(() => {
              this.router.navigateByUrl('/extensions');
            });
          }
        }
        break;
      case 'Booking-Changed':
        const result = confirm(this.translateService.instant('bookingChanged'));
        if (result) {
          console.log(
            'bookingChanged',
            '/appt-view/' +
              notification.data.bookingId +
              '/' +
              notification.data.apptId
          );
          this.ngZone.run(() => {
            this.router.navigateByUrl(
              '/appt-view/' +
                notification.data.bookingId +
                '/' +
                notification.data.apptId
            );
          });
        }
        break;
      default:
        break;
    }
    throw new Error('Method not implemented.');
  }

  async currentUser() {
    this.user = await this.angularFireAuth.currentUser;
  }
}
