import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { loadStripe } from "@stripe/stripe-js";
import { AgendaService } from "../agenda.service";
import { ENVSETTINGS } from "../environment";
import { notify } from "@nx-c4g/c4g-ui";

@Component({
  selector: "app-get-payment-info",
  templateUrl: "./get-payment-info.component.html",
  styleUrls: ["./get-payment-info.component.scss"]
})
export class GetPaymentInfoComponent implements AfterViewInit {
  @ViewChild("cardElement") cardElement: ElementRef;
  stripe;
  card;
  cardErrors;
  loading = false;
  confirmation;

  constructor(
    public dialogRef: MatDialogRef<GetPaymentInfoComponent>,
    public agendaService: AgendaService,
    @Inject(ENVSETTINGS) private environment: any,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  async ngAfterViewInit() {
    this.stripe = await loadStripe(this.environment.stripe);
    const elements = this.stripe.elements();

    this.card = elements.create("card", {
      style: {
        base: {
          iconColor: "#666EE8",
          color: "#31325F",
          lineHeight: "40px",
          fontWeight: 300,
          fontFamily: "Helvetica Neue",
          fontSize: "15px",

          "::placeholder": {
            color: "#CFD7E0"
          }
        }
      }
    });
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener("change", ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  async saveCard() {
    // const { cardsource, error } = await this.stripe.createSource(this.card);
    // const cardToken = await this.stripe.tokens.create(this.card);

    try {

      const result = await this.stripe.createSource(this.card);
      if (result.error) {
        // Inform the customer that there was an error.
        this.cardErrors = result.error.message;
        notify(`${ result.error.message}`, 'error');
      } else {
        console.log("RESSULT", result);
        const customer = await this.agendaService.saveCard(
          {
            careSeekerId: this.data.customer._id,
            customerId: this.data.customer.stripe_account,
            expMo: result.source.card.exp_month,
            expY: result.source.card.exp_year,
            last4: result.source.card.last4,
            sourceId: result.source.id
          }
        );
        customer["source"] = {
          card: {
            last4: result.source.card.last4,
            exp_month: result.source.card.exp_month,
            exp_year: result.source.card.exp_year
          }
        };
        this.dialogRef.close(customer);
        // Handle result.error or result.paymentMethod
      }
    } catch (e) {
      console.error(e);
      notify(`${e["error"]["message"]}`, "error");
    }

    //   .then(result => {
    //   if (result.error) {
    //     // Inform the customer that there was an error.
    //     this.cardErrors = result.error.message;
    //   } else {
    //     await this.stripe.setupIntents.create({
    //       customer: this.data.customer.stripe_account,
    //     });
    //
    //     // Handle result.error or result.paymentMethod
    //   }
    // });
  }
}
