import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthenticationService } from './authentication-service/authentication.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private token = 'secrettoken';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private auth: AuthenticationService,
    private dialogRef: MatDialog
    ) {}

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   console.warn('AuthInterceptor logged in?', this.auth.user);
  //   return undefined;
  // }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.idToken) {
      if (
        req.url.includes('localhost') ||
        req.url.includes('care4giver') ||
        req.url.includes('webhook') ||
        req.url.includes('prod-api')
      ) {
        req = req.clone({
          setHeaders: { Authorization: `Bearer ${this.auth.idToken}` },
        });
      }
    }

    return next.handle(req).pipe(tap(
      event => event instanceof HttpResponse ? 'succeeded' : '',
      err => {
        if (this.auth.idToken) {
          if (
            req.url.includes('localhost') ||
            req.url.includes('care4giver') ||
            req.url.includes('webhook') ||
            req.url.includes('prod-api')
          ) {
            if (err.status === 403) {
              console.warn('AuthInterceptor error', err);
              this.dialogRef.closeAll();
              this.auth.SignOut();
            }
          }
        }
      }
    ));
  }
}
