import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentLangService {
  currentLang: string = 'en';

  constructor() {}

  use(lang: string) {
    this.currentLang = lang;
  }
}
