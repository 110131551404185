<!--suppress TypeScriptValidateTypes -->
<div class="info-container">
  <div>
    <mat-tab-group class="tab-group">

      <mat-tab [label]="'NeedsAssessment.title' | translate" class="scrolling-y">
        <h3>{{ 'NeedsAssessment.title' | translate }}</h3>
        <div fxLayout="column" *ngIf="assessment">
          <div class="mb-1">
            <b class="mr-3">{{"NeedsAssessment.Current Residential Setting" | translate }}</b>
            {{assessment.currentResidentialSetting | translate }}
          </div>
        </div>

        <h4>{{ 'Health issues and medical diagnostics' | translate }}</h4>
        <div fxLayout="column" *ngIf="assessment">
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.alzheimerOtherDementia' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.alzheimerOtherDementia | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.parkinson' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.parkinson | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.muscularDystrophy' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.muscularDystrophy | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.muscularSclerosis' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.muscularSclerosis | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.arthritis' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.arthritis | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.mobilityIssues' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.mobilityIssues | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.liftingTransfers' | translate
              }}</b>
            {{assessment.healthIssuesAndMedicalDiagnostics.liftingTransfers | translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.HealthIssues.other' | translate
              }}</b><br/>
            <c4g-chip *ngFor="let item of assessment.healthIssuesAndMedicalDiagnostics.other">
              {{item | translate }}
            </c4g-chip>
          </div>
        </div>

        <h4>{{'NeedsAssessment.Communication' | translate}}</h4>
        <div fxLayout="column" *ngIf="assessment">
          <div class="mb-1">
            <b class="mr-3">{{'NeedsAssessment.VerbalTitle' | translate}}</b>
            {{assessment.communication.verbal| translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{'NeedsAssessment.VisionTitle' | translate}}</b>
            {{assessment.communication.vision| translate }}
          </div>
          <div class="mb-1">
            <b class="mr-3">{{'NeedsAssessment.HearingTitle' | translate}}</b>
            {{assessment.communication.hearing| translate }}
          </div>
        </div>

        <h4>{{'NeedsAssessment.other' | translate}}</h4>
        <div fxLayout="column" *ngIf="assessment">
          <div class="mb-1">
            <b class="mr-3">{{'NeedsAssessment.ContinenceTitle' | translate}}</b>
            {{assessment.other.continence| translate }}
          </div>
          <div fxLayout="column" class="mb-1">
            <b class="mr-3">{{
              'NeedsAssessment.otherConcerns' | translate
              }}</b>
            <c4g-chip *ngFor="let item of assessment.other.concerns">
              {{item | translate }}
            </c4g-chip>
          </div>
        </div>
      </mat-tab>

      <mat-tab [label]="'NeedsAssessment.LevelOfAutonomy' | translate" class="scrolling-y">
        <h3>{{ 'NeedsAssessment.LevelOfAutonomy' | translate }}</h3>
        <div fxLayout="column" *ngIf="assessment">
          <div class="mb-1" *ngFor="let item of jsonElement.LevelOfAutonomy | keyvalue">
            <b class="mr-3">{{"NeedsAssessment.LovedOneLeveOfAutonomyModel." + item.key | translate }}</b>
            <c4g-chip [ngClass]="getClass(item.value)">
              {{item.value | translate }}
            </c4g-chip>
          </div>
        </div>

        <h4 class="mt-4 mb-1">{{ 'lo-needs.Limitations and sensitivities' | translate }}</h4>
        <div class="c4g-chip-list">
          <div
            *ngFor="let limitation of jsonElement.LimitationsAndSensitivities"
          >
            <c4g-chip>{{ limitation }}</c4g-chip>
          </div>
        </div>

        <h4 class="mt-4 mb-1">{{ 'NeedsAssessment.typeOfCareAndNotes' | translate }}</h4>
        <div>
          {{ typeOfCareAndNotes }}
        </div>

        <h4 class="mt-4 mb-1">{{ 'lo-needs.Notes' | translate }}</h4>
        <div class="mb-5">
          {{ notes }}
        </div>
      </mat-tab>


      <mat-tab *ngIf="!hidePersonalInfo" [label]="'lo-needs.Emergency' | translate" class="tab-emergency">
        <div *ngIf="jsonElement.EmergencySection">
          <h3>{{ 'lo-needs.In Case of Emergency' | translate }}</h3>
          <div *ngIf="jsonElement.EmergencySection.FamilyMember1">
            <h4>{{ 'lo-needs.Family member' | translate }} #1</h4>
            {{ jsonElement.EmergencySection.FamilyMember1 }} :
            {{ jsonElement.EmergencySection.PhoneFamilyMember1 }}
          </div>
          <div *ngIf="jsonElement.EmergencySection.FamilyMember2">
            <h4>{{ 'lo-needs.Family member' | translate }} #2</h4>
            {{ jsonElement.EmergencySection.FamilyMember2 }} :
            {{ jsonElement.EmergencySection.PhoneFamilyMember2 }}
          </div>
          <div *ngIf="jsonElement.EmergencySection.FriendOrNeighbour1">
            <h4>{{ 'lo-needs.Friend or Neighbour' | translate }} #1</h4>
            {{ jsonElement.EmergencySection.FriendOrNeighbour1 }}
            : {{ jsonElement.EmergencySection.PhoneFriendorNeighbour1 }}
          </div>
          <div *ngIf="jsonElement.EmergencySection.FriendOrNeighbour2">
            <h4>{{ 'lo-needs.Friend or Neighbour' | translate }} #2</h4>
            {{ jsonElement.EmergencySection.FriendOrNeighbour2 }}
            : {{ jsonElement.EmergencySection.PhoneFriendOrNeighbour2 }}
          </div>
          <div *ngIf="jsonElement.EmergencySection.Pharmacy">
            <h4>{{ 'lo-needs.Pharmacy' | translate }}</h4>
            {{ jsonElement.EmergencySection.Pharmacy }} :
            {{ jsonElement.EmergencySection.PhonePharmacy }}
          </div>
          <div *ngIf="jsonElement.EmergencySection.MedicalDoctor">
            <h4>{{ 'lo-needs.Medical (Doctor)' | translate }}</h4>
            {{ jsonElement.EmergencySection.MedicalDoctor }} :
            {{ jsonElement.EmergencySection.PhoneMedical }}
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
