import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';

import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AppRouting } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { AppInterceptor } from './theme/utils/app-interceptor';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { Toolbar2Component } from './theme/components/toolbar2/toolbar2.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { CurrencyComponent } from './theme/components/currency/currency.component';
import { LangComponent } from './theme/components/lang/lang.component';
import { SocialIconsComponent } from './theme/components/social-icons/social-icons.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthenticationService } from './services/authentication-service/authentication.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogConfig } from '@angular/material/dialog';
import { GeoLocationService } from './services/geolocation/geo-location.service';
import { CaregiverProfileService } from './services/profile/caregiver-profile.service';
import { CareseekerProfileService } from './services/profile/careseeker-profile.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggedInGuard } from './guards/logged-in-guard/logged-in.guard';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAuth,
  AngularFireAuthModule,
} from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CurrentLangService } from './services/current-lang.service';

import { PusherService } from './services/pusher/pusher.service';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { CaregiverStatsService } from './services/caregiver-stats/caregiver-stats.service';
import { LoginDeeplinkService } from './services/login-deeplink/login-deeplink.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { CheckInsService } from './services/check-ins/check-ins.service';
import { CheckInOutComponent } from './pages/check-in-out/check-in-out.component';
import { EmailValidationComponent } from './pages/email-validation/email-validation.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ENVSETTINGS } from '@nx-c4g/agenda';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { ExtensionsService } from './services/extensions/extensions.service';
import { MeetRequestComponent } from './pages/meet-request/meet-request.component';
import { AppointmentSummaryTemplateComponent } from './pages/meet-request/appointment-summary-templates/appointment-summary-template.component';
import { UIENVSETTINGS } from '@nx-c4g/c4g-ui';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { TestDataServiceService } from './services/test-data-service.service';

@NgModule({ declarations: [
        AppComponent,
        PagesComponent,
        NotFoundComponent,
        UserMenuComponent,
        CurrencyComponent,
        LangComponent,
        SocialIconsComponent,
        Toolbar1Component,
        Toolbar2Component,
        HorizontalMenuComponent,
        VerticalMenuComponent,
        FooterComponent,
        CheckInOutComponent,
        EmailValidationComponent,
        MeetRequestComponent,
        AppointmentSummaryTemplateComponent,
    ],
    bootstrap: [AppComponent], imports: [NgHttpLoaderModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        CommonModule,
        AngularFireAuthModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        GoogleMapsModule,
        AppRouting,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: CGHttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxMatNativeDateModule], providers: [
        GoogleAnalyticsService,
        AppSettings,
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        { provide: 'googleTagManagerId', useValue: 'GTM-KDFQNM2' },
        DatePipe,
        AuthenticationService,
        MatDatepickerModule,
        GeoLocationService,
        CaregiverProfileService,
        CareseekerProfileService,
        DeviceDetectorService,
        MatDialogConfig,
        LoggedInGuard,
        AngularFireAuth,
        CurrentLangService,
        PusherService,
        CaregiverStatsService,
        LoginDeeplinkService,
        CheckInsService,
        TestDataServiceService,
        ExtensionsService,
        { provide: ENVSETTINGS, useValue: environment },
        { provide: UIENVSETTINGS, useValue: environment },
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ] })
export class AppModule {}

export function CGHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
