<mat-form-field appearance="outline">
  <mat-label>Month</mat-label>
  <mat-select [value]="selected" (selectionChange)="selectionChanged($event)">
    <mat-option *ngFor="let log of careLogs" [value]="log">{{
      getDateLabel(log)
    }}</mat-option>
  </mat-select>
</mat-form-field>
<button mat-icon-button (click)="doRefresh()"><mat-icon>refresh</mat-icon></button>

<c4g-care-log-book
  *ngIf="careLog"
  [careLog]="careLog"
  [csId]="csId"
  [loId]="loId"
  [name]="name"
  (changed)="addedLog($event)"
></c4g-care-log-book>
