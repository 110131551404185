import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { CareLogEntryModel, CareLogModel } from "@nx-c4g/c4g-models";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'c4g-care-logs',
  templateUrl: './care-logs.component.html',
  styleUrls: ['./care-logs.component.css'],
})
export class CareLogsComponent implements OnInit, OnChanges {
  @Input() careLogs!: CareLogModel[];
  @Input() csId!: string;
  @Input() loId!: string;
  @Input() name!: string;
  @Output()
  changed: EventEmitter<CareLogEntryModel> = new EventEmitter<CareLogEntryModel>();
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  selected: CareLogModel | undefined;
  careLog: any;

  constructor(
    private numPipe: DecimalPipe,
    private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selected = this.careLogs[this.careLogs.length - 1];
    this.careLog = null;
    this.loadSelection(this.selected);
  }

  getDateLabel(log: CareLogModel) {
    return this.numPipe.transform(log.month, '2.0') + '-' + log.year;
  }

  selectionChanged($event: MatSelectChange) {
    this.loadSelection($event.value);
  }

  loadSelection(careLog: CareLogModel) {
    this.careLog = careLog;
  }

  addedLog($event: CareLogEntryModel) {
    this.changed.emit($event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['careLogs']) {
      this.selected = this.careLogs[this.careLogs.length - 1];
      this.loadSelection(this.selected);
      if (this.careLogs.length === 0) {
        const careLog = new CareLogModel();
        careLog._id = `${this.csId}${this.loId}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`
        careLog.month = new Date().getMonth() + 1;
        careLog.year = new Date().getFullYear();
        careLog.date = new Date();
        careLog.entries = [];
        this.careLogs.push(careLog);
        this.selected = careLog;
        this.loadSelection(careLog);
      }
      console.log('Care Logs', this.careLogs);
    }
  }

  doRefresh() {
    this.refresh.emit(true);
  }
}
