import { Route, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoggedInGuard } from './guards/logged-in-guard/logged-in.guard';
import { CheckInOutComponent } from './pages/check-in-out/check-in-out.component';
import { EmailValidationComponent } from './pages/email-validation/email-validation.component';
import { MeetRequestComponent } from "./pages/meet-request/meet-request.component";

export const routes: Route[] = [
  {
    path: '',
    component: PagesComponent,
    children: [
      // { path: '', redirectTo: '/landing', pathMatch: 'full' },
      {
        path: '',
        canActivate: [LoggedInGuard],

        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'error-page',
        loadChildren: () =>
          import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
      },

      {
        canActivate: [LoggedInGuard],

        path: 'appt-view/:bookingId/:apptId',
        loadChildren: () =>
          import('./pages/appointment-view/appointment-view.module').then((m) => m.AppointmentViewModule),
      },
      {
        canActivate: [LoggedInGuard],

        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        canActivate: [LoggedInGuard],

        path: 'meet-request/:id',
        component: MeetRequestComponent
      },
      {
        path: 'extensions',
        canActivate: [LoggedInGuard],

        loadChildren: () =>
          import('./pages/extensions/extensions.module').then(
            (m) => m.ExtensionsModule
          ),
      },
      {
        path: 'availabilities',
        canActivate: [LoggedInGuard],
        loadChildren: () =>
          import('./pages/availabilities/availabilities.module').then(
            (m) => m.AvailabilitiesModule
          ),
      },
      {
        path: 'wait',
        loadChildren: () =>
          import('./pages/cg-update-wait/cg-update-wait.module').then(
            (m) => m.CgUpdateWaitModule
          ),
      },
      {
        path: 'teams',
        loadChildren: () =>
          import('./pages/care-teams/care-teams.module').then(
            (m) => m.CareTeamsModule
          ),
        canActivate: [LoggedInGuard],
      },

      {
        path: 'accept',
        loadChildren: () =>
          import('./pages/acceptance/accept/accept.module').then(
            (m) => m.AcceptModule
          ),
        canActivate: [LoggedInGuard],
      },
      {
        path: 'reject',
        loadChildren: () =>
          import('./pages/acceptance/reject/reject.module').then(
            (m) => m.RejectModule
          ),
        canActivate: [LoggedInGuard],
      },
      // {
      //   path: 'new',
      //   loadChildren: () =>
      //     import('./proxies/new-user-proxy/new-user-proxy.module').then(
      //       (m) => m.NewUserProxyModule
      //     ),
      //   canActivate: [LoggedInGuard],
      // },
      {
        path: 'care-givers',
        loadChildren: () =>
          import('./pages/care-givers/caregivers.module').then(
            (m) => m.CaregiversModule
          ),
        canActivate: [LoggedInGuard],
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'email-validate',
        component: EmailValidationComponent,
      },
      {
        path: 'forgotpassword',
        loadChildren: () =>
          import('./pages/forgot-password/forgot-password.module').then(
            (m) => m.ForgotPasswordModule
          ),
      },
      {
        path: 'register/:id',
        loadChildren: () =>
          import('./pages/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./pages/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: 'termsofservices',
        loadChildren: () =>
          import('./pages/terms-conditions/terms-conditions.module').then(
            (m) => m.TermsConditionsModule
          ),
      },
      {
        path: 'terms-conditions',
        loadChildren: () =>
          import('./pages/terms-conditions/terms-conditions.module').then(
            (m) => m.TermsConditionsModule
          ),
      },
      {
        path: 'care-book',
        loadChildren: () =>
          import('./pages/care-book-view/care-book.module').then(
            (m) => m.CareBookModule
          ),
        canActivate: [LoggedInGuard],
      },
      {
        path: 'bank',
        loadChildren: () =>
          import('./pages/bank-transactions/bank-transactions.module').then(
            (m) => m.BankTransactionsModule
          ),
        canActivate: [LoggedInGuard],
      },
      {
        path: 'check-ins',
        canActivate: [LoggedInGuard],

        component: CheckInOutComponent,
      },
      {
        path: 'agenda-view',
        loadChildren: () =>
          import('./pages/agenda/agenda-page.module').then(
            (m) => m.AgendaPageModule
          ),
        canActivate: [LoggedInGuard],
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

export const AppRouting: ModuleWithProviders<any> = RouterModule.forRoot(
  routes,
  {
    onSameUrlNavigation: 'reload'
  }
);
