import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AppointmentModel,
  AppointmentStatusModel,
  CareSeekerModel,
  CareTeamModel,
  GeneralUtil,
  LovedOnePassportModel,
  NameModel,
} from '@nx-c4g/c4g-models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'nx-c4g-appointment-template',
  templateUrl: './appointment-template.component.html',
  styleUrls: ['./appointment-template.component.scss'],
})
export class AppointmentTemplateComponent {
  @Input() appt!: AppointmentModel;
  @Input() careSeeker!: CareSeekerModel;
  @Input() ct!: CareTeamModel;
  @Input() isMine = false;
  @Input() canViewCareBook = true;
  @Input() cantDeleteReason: string | undefined;
  @Input() text!: string;

  @Output() onViewCareBook: EventEmitter<{ cs: string; lo: string }> =
    new EventEmitter<{ cs: string; lo: string }>();
  @Output() onViewLovedOne: EventEmitter<{ lo: LovedOnePassportModel }> =
    new EventEmitter<{ lo: LovedOnePassportModel }>();

  constructor() {}

  canEdit() {
    return (
      this.appt.status === AppointmentStatusModel.careBookEntryUnfulfilled
      || this.appt.status === AppointmentStatusModel.careBookEntryBooked
      || this.appt.status === AppointmentStatusModel.careBookEntryFulfilled
    )
      && this.canViewCareBook && this.isMine
  }

  convertLB(arg0: string) {
    return GeneralUtil.convertLineBreaksToHTML(arg0);
  }

  resolveCt(member: any) {
    if (member && this.ct) {
      const found = this.ct.team.find((tm) => tm.id === member);
      return found?.name;
    } else {
      return '';
    }
  }

  getStrippedName(Name: NameModel) {
    return GeneralUtil.stripLastName(Name);
  }

  getStrippedNameString(Name: string) {
    return GeneralUtil.stripLastNameString(Name);
  }
}
