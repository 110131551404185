import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication-service/authentication.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  handleError(error: any) {
    console.log('GlobalError', error);
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }

    if (error) {
      const callStack = error.stack;
      const errorMessage = error.message;
      const errorStatus = error.status;
      this.auth.isLoggedIn().subscribe((user) => {
        console.warn('User from global error', user ? user.uid : null,);
        this.http
          .put(environment.api + 'cg-errors', {
            userId: user ? user.uid : null,
            errorMessage: errorMessage,
            errorStatus: errorStatus,
            callStack: callStack,
          })
          .subscribe();
      });
      // console.error('Error from global errorMessage', errorMessage);
      // console.error('Error from global errorStatus', errorStatus);
      // console.error('Error from global callStack', callStack);
      // Send the error to the server
    } else {
      console.error('An error occurred:', error);
    }
  }
}
