import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  AmountTx,
  AppointmentExtensionModel,
  AppointmentExtensionResult,
  AppointmentModel,
  AppointmentStatusModel,
  AppointmentTypesModel,
  CareGiverModel,
  CareSeekerModel,
  DateUtil,
  PricingUtil,
  TaxesUtil
} from "@nx-c4g/c4g-models";
import { TranslateService } from "@ngx-translate/core";
import { AgendaService } from "../agenda.service";
import { DepositDialogComponent } from "../deposit-dialog/deposit-dialog.component";
import { notify } from "@nx-c4g/c4g-ui";

@Component({
  selector: 'nx-c4g-agenda-extension',
  templateUrl: './agenda-extension.component.html',
  styleUrls: ['./agenda-extension.component.scss'],
})
export class AgendaExtensionComponent {
  reasons: { value: string, en: string, fr: string }[] = [
    {
      value: 'cgLate',
      fr: "Le prochain aide-soignant ou membre de la famille sera en retard",
      en: "Next caregiver or family member will be late"
    },
    {
      value: 'help',
      fr: "Le patient a besoin de plus d'attention ou d'aide aujourd'hui",
      en: "Patient needs more attention or help today"
    },
    {
      value: 'tasks',
      fr: "Besoin d'effectuer des tâches supplémentaires",
      en: "Need the following extra tasks done today"
    },
    {
      value: 'other',
      fr: "Autre",
      en: "Other"
    }
  ]

  hours: { value: number, label: string }[] = [
    {value: 0, label: `0 ${this.translateService.instant('bookingEx.Hour')}`},
    {value: 1, label: `1 ${this.translateService.instant('bookingEx.Hour')}`},
    {value: 2, label: `2 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 3, label: `3 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 4, label: `4 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 5, label: `5 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 6, label: `6 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 7, label: `7 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 8, label: `8 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 9, label: `9 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 10, label: `10 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 11, label: `11 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 12, label: `12 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 13, label: `13 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 14, label: `14 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 15, label: `15 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 16, label: `16 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 17, label: `17 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 18, label: `18 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 19, label: `19 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 20, label: `20 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 21, label: `21 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 22, label: `22 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 23, label: `23 ${this.translateService.instant('bookingEx.Hours')}`},
    {value: 24, label: `24 ${this.translateService.instant('bookingEx.Hours')}`},
  ]
  minutes: { value: number, label: string }[] = [
    {value: 0, label: '0 minutes'},
    {value: 15, label: '15 minutes'},
    {value: 30, label: '30 minutes'},
    {value: 45, label: '45 minutes'},
  ]
  selectedMinute: any = 0;
  selectedHours: any = 1;
  reason: { value: string, en: string, fr: string };
  newTasks: string[] = [];
  otherReason = "";

  constructor(
    private translateService: TranslateService,
    private agendaService: AgendaService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AgendaExtensionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      appt: AppointmentModel,
      mode: 'cg' | 'cs',
      cg: CareGiverModel,
      cs: CareSeekerModel,
      balance: number,
    }
  ) {

  }

  private get cgRequestType(): string {
    return 'direct';
  }

  private get csRequestType(): string {
    return 'request';
  }

  get requestType(): string {
    if (this.data.mode === 'cg') {
      return this.cgRequestType;
    } else {
      return this.csRequestType;
    }
  }

  async okDialog() {
    const extension: AppointmentExtensionModel = {
      csId: this.data.appt.careseeker,
      cgId: this.data.appt.caregiver,
      reason: this.reason,
      newTasks: this.newTasks,
      otherReason: this.otherReason,
      hours: DateUtil.hoursMinutesToDecimalHours(this.selectedHours, this.selectedMinute),
      apptId: this.data.appt._id,
      mode: this.data.mode
    }
    const result: {appt: AppointmentModel, result: AppointmentExtensionResult} = await this.agendaService.extendAppointment(extension);
    switch (result.result) {
      case AppointmentExtensionResult.added:
        notify(this.translateService.instant('bookingEx.Extended'), 'info', 3000);
        this.data.appt = result.appt;
        break;
      case AppointmentExtensionResult.requestSentToCaregiver:
        notify(this.translateService.instant('bookingEx.requestSentToCaregiver'), 'info', 3000);
        break;
      case AppointmentExtensionResult.requestSentToCareseeker:
        notify(this.translateService.instant('bookingEx.requestSentToCareseeker'), 'info', 3000);
        break;
      case AppointmentExtensionResult.noFunds:
        notify(this.translateService.instant('bookingEx.noFunds'), 'error', 3000);
        break;
    }
    this.dialogRef.close(result);
  }

  cancelDialog() {
    this.dialogRef.close(false);
  }

  getReasonLabel(reason: { en: string; fr: string }) {
    return this.reasons.find(r => r.en === reason.en)?.[this.translateService.currentLang] || reason.en;
  }

  calculateCost() {
    if (this.data.mode === 'cs') {
        const appt = this.data.appt;
        const taxeRates = TaxesUtil.getCsTaxeRates(this.data.cs);
        const amount = appt.status === AppointmentStatusModel.careBookEntryBooked ||
        appt.status === AppointmentStatusModel.careBookEntryFulfilled ?
          appt.hourlyCharge * DateUtil.hoursMinutesToDecimalHours(this.selectedHours, this.selectedMinute) :
          appt.maxRate * DateUtil.hoursMinutesToDecimalHours(this.selectedHours, this.selectedMinute);
        const taxes = TaxesUtil.calculateTaxes(amount, taxeRates);
        const total: AmountTx = {
          amount, taxes
        }
        return PricingUtil.total(total);
    }
    return 0;
  }

  isValid() {
    if (this.data.appt.type === AppointmentTypesModel.blocked) {
      return true;
    }
    let validReason = false;
    if (this.reason) {
      if (this.reason.value === 'tasks') {
        validReason = this.newTasks.length > 0;
      } else if (this.reason.value === 'other') {
        validReason = this.otherReason.length > 0;
      } else {
        validReason = true;
      }
    }

    let enoughBalance = true;
    if (this.data.balance < this.calculateCost()) {
      enoughBalance = false;
    }

    if (this.data.mode === 'cg') {
      return validReason;
    } else {
      return validReason && enoughBalance;
    }
  }

  deposit() {
    const amount = PricingUtil.roundToTwoDecimals(this.calculateCost() - this.data.balance);
    console.log('Deposit', amount);
    const dialogRef = this.dialog.open(DepositDialogComponent, {
      width: '500px',
      data: {
        careSeeker: this.data.cs,
        ccOnly: true,
        amount: amount
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.agendaService.getBalance(this.data.cs).then((bi) => {
          this.data.balance = bi.balance;
        });
      }
    });
  }
}
