import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { CountryModel } from "@nx-c4g/c4g-models";
import { UIENVSETTINGS } from "@nx-c4g/c4g-ui";

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  countries: CountryModel[] = [];
  constructor(
    private http: HttpClient,
    @Inject(UIENVSETTINGS) private environment: any
  ) { }

  async getCountries(): Promise<CountryModel[]> {
    if (this.countries.length) {
      return this.countries;
    }
    const countries = await firstValueFrom<CountryModel[]>(this.http.get<CountryModel[]>(`${this.environment.api}countries`));
    this.countries = countries;
    return countries;
  }

}
