<mat-horizontal-stepper
  [linear]="'true'"
  #stepper
  (selectionChange)="onStepChange($event)"
>
  <mat-step>
    <ng-template matStepLabel>{{
      'bookingform.steps.step1' | translate
    }}</ng-template>
    <h3>{{ 'bookingform.steps.step1Question' | translate }}</h3>
    <mat-radio-group [(ngModel)]="bookingMode" (change)="bookingModeChange()">
      <mat-radio-button value="one" class="mr-3">{{
        'bookingform.steps.step1Answer1' | translate
      }}</mat-radio-button>
      <mat-radio-button value="many">{{
        'bookingform.steps.step1Answer2' | translate
      }}</mat-radio-button>
    </mat-radio-group>
    <div fxLayout="row" fxLayoutAlign="flex-end" class="full-width">
      <div>
        <button mat-raised-button color="primary" matStepperNext>Next</button>
      </div>
    </div>
  </mat-step>
  <mat-step [stepControl]="bookingFormGroup">
    <form [formGroup]="bookingFormGroup" fxLayout="column" class="form-wrapper">
      <ng-template matStepLabel>Booking Information</ng-template>
      <div fxLayout="row wrap">
        <div fxLayout="row" class="mt-3">
          <nx-c4g-duration-picker [formControl]="durationCtrl" (dateChanged)="durationChanged($event)"></nx-c4g-duration-picker>
        </div>
        <ng-container *ngIf="bookingMode === 'many'">
          <div fxFlex="33.3" fxFlex.gt-sm="33.3" fxFlex.sm="33.3" class="p-2">
            <mat-form-field [appearance]="'outline'" class="time-fields">
              <mat-label>Number of Weeks</mat-label>
              <mat-select placeholder="Weeks" formControlName="weeks">
                <mat-option *ngFor="let week of weeks" [value]="week">
                  {{ week }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-button-toggle-group
            name="days"
            aria-label="days"
            [multiple]="true"
            fxLayout="row"
            formControlName="days"
            (change)="daysChange($event)"
            class="p-2"
          >
            <mat-button-toggle #sunday value="0" flex="14"
              >Sun</mat-button-toggle
            >
            <mat-button-toggle #monday value="1" flex="14"
              >Mon</mat-button-toggle
            >
            <mat-button-toggle #tuesday value="2" flex="14"
              >Tue</mat-button-toggle
            >
            <mat-button-toggle #wednesday value="3" flex="14"
              >Wed</mat-button-toggle
            >
            <mat-button-toggle #thursday value="4" flex="14"
              >Thu</mat-button-toggle
            >
            <mat-button-toggle #friday value="5" flex="14"
              >Fri</mat-button-toggle
            >
            <mat-button-toggle #saturday value="6" flex="14"
              >Sat</mat-button-toggle
            >
          </mat-button-toggle-group>
        </ng-container>
      </div>

      <mat-error>
        <span *ngIf="bookingFormGroup.errors?.available" class="error">
          This time range is not available
        </span>
        <span *ngIf="bookingFormGroup.errors" class="error">
          {{bookingFormGroup.errors | json}}
        </span>
      </mat-error>
      <div fxLayout="row" fxFlexAlign="end">
        <div>
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            (click)="save()"
            [disabled]="bookingFormGroup.invalid"
          >
            Create
          </button>
        </div>
      </div>

    </form>
  </mat-step>

</mat-horizontal-stepper>
