import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AppointmentModel,
  CareBookModel,
  CareLogEntryModel,
  CareLogModel,
} from '@nx-c4g/c4g-models';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CareBookService {
  constructor(private http: HttpClient) {}

  async rejectBooking(careBookId: string, apptId: any) {
    await firstValueFrom(
      this.http.put(
        environment.api +
          `care-book/reject/${careBookId}/${apptId}`,
        {},
      ),
    );
  }

  async fulfillReverseBooking(
    careBookId: string,
    csId: string,
    appointment: AppointmentModel,
  ) {
    return new Promise<CareBookModel>((resolve, reject) => {
      try {
        firstValueFrom(
          this.http.put(
            environment.api + `booking/fulfill/${careBookId}/${csId}`,
            appointment,
          ),
        ).then((res) => {
          resolve(res as CareBookModel);
        }).catch((ex) => {
          console.warn('fulfillReverseBooking', ex);
          reject(ex);
        });
      } catch (ex) {
        console.warn('fulfillReverseBooking', ex);
        reject(ex);
      }
    });
  }

  async getCareBook(csId: string, id: string): Promise<CareBookModel> {
    return new Promise<CareBookModel>((resolve, reject) => {
      this.http
        .get<CareBookModel>(environment.api + `care-book/cg/${csId}/${id}`)
        .toPromise()
        .then((cb: CareBookModel) => {
          resolve(cb);
        });
    });
  }

  async updateCareBook(id: string, cb: CareBookModel): Promise<CareBookModel> {
    return new Promise<CareBookModel>((resolve, reject) => {
      this.http
        .put<CareBookModel>(environment.api + 'care-book/' + id, cb)
        .toPromise()
        .then((cbr: CareBookModel) => {
          resolve(cbr);
        });
    });
  }

  async getCareLogs(csid: string, id: string): Promise<CareLogModel[]> {
    return new Promise<CareLogModel[]>((resolve, reject) => {
      this.http
        .get<CareLogModel[]>(
          environment.api + `care-book/logbycg/${csid}/${id}`,
        )
        .toPromise()
        .then((cls: CareLogModel[]) => {
          resolve(cls);
        });
    });
  }

  async addLog(entry: CareLogEntryModel) {
    return new Promise<CareLogModel>((resolve, reject) => {
      this.http
        .put<CareLogModel>(environment.api + 'care-book/log', entry)
        .toPromise()
        .then((cle: CareLogModel) => {
          resolve(cle);
        });
    });
  }
}
