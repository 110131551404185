export enum AppointmentTypesModel {
  careBookEntry = 'careBookEntry', // Care book entry when not accepted yet
  blocked = 'blocked', // Time off by CG
  // travelTime = 'travelTime', // Travel Time

}

export enum AppointmentStatusModel {
  none = 'none',
  cancelled = 'cancelled', // Both direct and Care book entry when cancelled
  cancelledByCG = 'cancelledByCG', // Both direct and Care book entry when cancelled by CG
  paidToCG = 'paidToCG', // Both direct and Care book entry when paid to CG
  // fulfilled = 'fulfilled', // when checked-out
  careBookEntryUnfulfilled = 'careBookEntryUnfulfilled', // New Care book entry
  careBookEntryBooked = 'careBookEntryBooked', // Care book entry when booked but not worked
  careBookEntryFulfilled = 'careBookEntryFulfilled', // Care book entry when checked-out


}


