import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  MeetModel
} from "@nx-c4g/c4g-models";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "nx-c4g-meet-appointment-summary-template",
  templateUrl: "./appointment-summary-template.component.html",
  styleUrls: ["./appointment-summary-template.component.css"]
})
export class AppointmentSummaryTemplateComponent {
  @Input() text!: string;
  @Input() showButton = false;
  @Input() appt!: MeetModel;
  @Output() selectAppt = new EventEmitter<MeetModel>();


  constructor() {
  }

  select(appt: MeetModel) {
    this.selectAppt.emit(appt);
  }
}
