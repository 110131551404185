import {Type} from "class-transformer";

export enum NotificationTypes {
  pleaseRate = 'pleaseRate'
}

export class NotificationModel {
  _id: string;

  NotificationType: NotificationTypes;
  targetId: string;
  careseekerId: string;
  caregiverId: string;
  appointmentId: string;
  read: boolean;
  @Type(() => Date)
  createdDate: Date;
  @Type(() => Date)
  readDate: Date;
  url: string;
}
