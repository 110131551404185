import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { C4gChipComponent } from './c4g-chip/c4g-chip.component';
import { LovedOnePopupComponent } from './loved-one-popup/loved-one-popup.component';
import { LoNeedComponent } from './loved-one-popup/lo-need/lo-need.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { CaregiverBasicInfoComponent } from './caregiver-basic-info/caregiver-basic-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GooglePlacesComponent } from './google-places/google-places.component';
import { MatInputModule } from '@angular/material/input';
import { CareGiverBasicInfoDialogComponent } from './care-giver-basic-info-dialog/care-giver-basic-info-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { LovedOneInfoComponent } from './loved-one-info/loved-one-info.component';
import { CareBookTasksComponent } from './care-book-tasks/care-book-tasks.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CareLogBookComponent } from './care-log-book/care-log-book.component';
import { CareLogsComponent } from './care-logs/care-logs.component';
import { MatSelectModule } from '@angular/material/select';
import { IncomingCallComponent } from './incoming-call/incoming-call.component';
import { MatRadioModule } from '@angular/material/radio';
import { NotifyDrawerComponent } from './notify-drawer/notify-drawer.component';
import { NotifyService } from './notify-drawer/notify.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TasksComponent } from './tasks/tasks.component';
import { AvailabilityRangeComponent } from './availability-range/availability-range.component';
import { TimeValidatorDirective } from './availability-range/time-validator.directive';
import { MapsLoaderService } from './google-places/maps-loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddressDisplayComponent } from './google-places/address-display/address-display.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CountriesService } from './google-places/countries.service';
import { RefreshComponent } from './refresh/refresh.component';
import { CommunicationNotesComponent } from './comm-notes/CommunicationNotes.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CheckInNotesComponent } from './comm-notes/check-in-notes.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
  ],
  declarations: [
    C4gChipComponent,
    LoNeedComponent,
    CaregiverBasicInfoComponent,
    GooglePlacesComponent,
    CareGiverBasicInfoDialogComponent,
    LovedOneInfoComponent,
    CareBookTasksComponent,
    CareLogBookComponent,
    CareLogsComponent,
    IncomingCallComponent,
    NotifyDrawerComponent,
    LovedOnePopupComponent,
    TasksComponent,
    AvailabilityRangeComponent,
    TimeValidatorDirective,
    AddressDisplayComponent,
    RefreshComponent,
    CommunicationNotesComponent,
    CheckInNotesComponent
  ],
  providers: [NotifyService, MapsLoaderService, CountriesService],
  exports: [
    AvailabilityRangeComponent,
    C4gChipComponent,
    LoNeedComponent,
    CaregiverBasicInfoComponent,
    GooglePlacesComponent,
    CareGiverBasicInfoDialogComponent,
    LovedOneInfoComponent,
    CareBookTasksComponent,
    CareLogBookComponent,
    CareLogsComponent,
    NotifyDrawerComponent,
    TasksComponent,
    RefreshComponent,
    CommunicationNotesComponent,
  ],
})
export class C4gUiModule {}
