import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CaregiverProfileService } from '../profile/caregiver-profile.service';
import { VisitModel } from '@nx-c4g/c4g-models';

@Injectable({
  providedIn: 'root',
})
export class CaregiverStatsService {
  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    private caregiverProfileService: CaregiverProfileService
  ) {}

  addVisit(caregiverId: string, careSeekerId: string) {
    const now: Date = new Date(Date.now());

    const visit: VisitModel = { visitor: careSeekerId, time: now };

    this.http
      .put(`${environment.api}care-giver-visits/${caregiverId}`, visit)
      .toPromise()
      .then((res) => {})
      .catch((error) => {
        console.error('Visit not logged', error);
      });
  }

  getVisits(caregiverId: string) {
    const params = new HttpParams().set('id', caregiverId);

    return this.http.get(
      environment.api + 'care-giver-visits' + '/' + caregiverId,
      { params }
    );
  }

  getYTD(caregiverId: string) {
    const params = new HttpParams().set('id', caregiverId);

    return this.http.get(
      environment.api + 'care-giver-stats/yeartodate/' + caregiverId,
      { params }
    );
  }

  getUpcoming(caregiverId: string) {
    const params = new HttpParams().set('id', caregiverId);

    return this.http.get(
      environment.api + 'care-giver-stats/upcoming/' + caregiverId,
      { params }
    );
  }

  getPending(caregiverId: string) {
    const params = new HttpParams().set('id', caregiverId);

    return this.http.get(
      environment.api + 'care-giver-stats/pending/' + caregiverId,
      { params }
    );
  }
}
