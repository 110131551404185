<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav"
               [opened]="false"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               #sidenav>
    <button mat-icon-button
            color="warn"
            class="close"
            (click)="sidenav.close()">
      <mat-icon color="warn">close</mat-icon>
    </button>
    <div class="divider"></div>

    <div class="vertical-menu-wrapper">
      <app-vertical-menu [menuParentId]="0"></app-vertical-menu>
    </div>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column">
    <app-toolbar1 class="sticky-navbar"
                  (onMenuIconClick)="sidenav.toggle()"></app-toolbar1>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="sticky-footer-helper"
         fxFlex="grow"></div>



  </mat-sidenav-content>

</mat-sidenav-container>
<app-footer class="sticky-footer"></app-footer>
