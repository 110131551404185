import {CareTeamMember, CareTeamModel, CareTeamStatus} from "./CareTeamModel";
import { AppointmentModel } from "./appointmentModel";
import { CareBookModel } from "./careBookModel";
import {CareSeekerModel} from "./careSeekerModel";

export class CGTeamsModel {
  _id: string;
  status?: CareTeamStatus;
  team: CareTeamMember[];
  teamName: CareSeekerModel;
  careBooks?: CareBookModel[] = [];
  // Dict of appointments for each carebook
  appointments?: AppointmentModel[]  = [];
}

