<div>
  <mat-form-field appearance="outline" class="w-100 mt-2">
    <mat-icon matPrefix class="mr-1 text-muted">attach_money</mat-icon>
    <mat-label>Amount</mat-label>
    <input
      matInput
      placeholder="amount"
      [(ngModel)]="amount"
      type="number"
      required
    />
  </mat-form-field>

</div>

<div #cc *ngIf="type === 'cc'">
  <ng-template matStepLabel>Billing Information</ng-template>
  <div fxLayout="row wrap" fxLayoutAlign="flex-start center">
    <div class="mr-2">
      <mat-icon class="credit-card-icon">credit_card</mat-icon>
    </div>
    <div fxLayout="column" class="cc-layout mr-3">
      <p class="cc-info">
        {{
          careSeeker.last4
            ? 'XXXX XXXX XXXX ' + careSeeker.last4
            : 'No Card on file'
        }}
      </p>
      <p *ngIf="careSeeker.last4" class="cc-info">
        {{ 'exp: ' + careSeeker.exp_mo + '/' + careSeeker.exp_y }}
      </p>
    </div>
    <div fxLayoutAlign="flex-start center">
      <button *ngIf="careSeeker.last4" (click)="getCC()">Change Card</button>
      <button *ngIf="!careSeeker.last4" (click)="getCC()">Add Card</button>
    </div>
  </div>
  <div>
    <button (click)="confirmCC()">Submit</button>
  </div>
</div>
