import { EventEmitter, Injectable } from '@angular/core';
import { CareBookService } from '../carebook/care-book.service';
import { CareTeamService } from './care-team.service';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { CaregiverProfileService } from '../profile/caregiver-profile.service';
import {
  AppointmentTypesModel,
  CGTeamsModel,
  CareBookModel,
  CareTeamStatus,
  CareTeamTiers,
  CareGiverModel,
  GeneralUtil,
  AppointmentModel,
} from '@nx-c4g/c4g-models';
import { PusherService } from '../pusher/pusher.service';

@Injectable({
  providedIn: 'root',
})
export class CareTeamExpandedService {
  private careTeamsCache: CGTeamsModel[];
  private channelInitialized = false;
  private channelCGId: string;
  private ignoreList: { cbId: string; apptId: string; exp: Date }[] = [];

  public changed = new EventEmitter<any>();
  public cacheChanged = new EventEmitter<any>();

  constructor(
    private caregiverProfileService: CaregiverProfileService,
    private pusherService: PusherService,
    private careTeamService: CareTeamService,
    private careBookService: CareBookService,
  ) {}



  async reloadCB(
    cgProfile: CareGiverModel,
    force = false,
  ): Promise<CGTeamsModel[]> {
    if (!cgProfile || !cgProfile._id) {
      return [];
    }
    await this.initChannel(cgProfile);

    if (this.careTeamsCache && this.careTeamsCache.length > 0 && !force) {
      return this.careTeamsCache;
    }

    // Load the ignore list from localStorage
    let ignoreListStorage = localStorage.getItem(
      `careBookIgnoreList-${cgProfile._id}`,
    );
    if (ignoreListStorage) {
      this.ignoreList = JSON.parse(ignoreListStorage);
    }

    const cts = await this.caregiverProfileService.getCareTeams();

    for (let ct of cts) {
      const status = await this.getTeamStatus(ct, cgProfile._id);
      // const careBooks: CareBookModel[] = await this.getCareBooks(ct, cgProfile);
      ct.status = status;
    }

    this.careTeamsCache = cts;
    // Remove the ignored appointments from the careTeamsCache
    // if (this.ignoreList && this.ignoreList.length > 0) {
    //   for (let ignore of this.ignoreList) {
    //     for (let ct of this.careTeamsCache) {
    //       for (let cb of ct['careBooks']) {
    //         cb.appointments = cb.appointments.filter(
    //           (appt) => appt._id !== ignore.apptId,
    //         );
    //         cb.appointments = cb.appointments.sort((a, b) => {
    //           return (
    //             new Date(a.startDate).getTime() -
    //             new Date(b.startDate).getTime()
    //           );
    //         });
    //       }
    //     }
    //   }
    // }
    return cts;
  }

  async initChannel(cgProfile: CareGiverModel) {
    if (!cgProfile || !cgProfile._id) {
      return;
    }

    if (
      !this.channelInitialized ||
      (this.channelCGId && cgProfile._id !== this.channelCGId)
    ) {
      if (this.channelInitialized) {
        const oldChannel = this.pusherService.getChannel(this.channelCGId);
        oldChannel.unbind('cb-invite-change');
        oldChannel.unbind('cb-schedule-change');
      }

      this.channelInitialized = true;
      this.channelCGId = cgProfile._id;
      const channel = this.pusherService.getChannel(cgProfile._id);

      channel.bind('cb-invite-change', async (data) => {
        console.log('cb-invite-change');
        this.changed.emit();
      });

      channel.bind('cb-schedule-change', async (data) => {
        console.log('cb-schedule-change');
        this.changed.emit();
      });
    }
  }


  private getTeamStatus(ct: CGTeamsModel, uid): CareTeamStatus {
    // console.log('CT:', ct['team'], ct['team'].filter((team) => team.id === uid)[0]);
    return ct['team'].filter((team) => team.id === uid)[0].status;
  }


}
