import { PricingUtil } from './pricing.util';
import { TaxeModel } from '../../taxeModel';
import { AmountTx } from '../../bank/AmountTx';
import { CareGiverModel } from '../../careGiverModel';
import { CareSeekerModel, CareSeekerTypeModel } from '../../careSeekerModel';
import { CareBookModel } from '../../careBookModel';
import { GeneralUtil } from '../general.util';

export class TaxeStateModel {
  state = '';
  code: string[] = [];
  taxes: TaxeModel[] = [];
}

export class TaxeCountryModel {
  country = '';
  code: string[] = [];
  states: TaxeStateModel[] = [];
}

export class TaxesData {
  private static _instance: TaxesData = new TaxesData();
  public static get instance() {
    return TaxesData._instance;
  }

  private constructor() {}

  taxes: TaxeCountryModel[] = [
    {
      country: 'CANADA',
      code: ['CA'],
      states: [
        {
          code: ['AB'],
          state: 'Alberta',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
          ],
        },
        {
          code: ['BC'],
          state: 'British Columbia',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
            {
              code: 'PST',
              name: 'Provincial sales tax',
              type: 'provincial',
              tax: 0.07,
            },
          ],
        },
        {
          code: ['MB'],
          state: 'Manitoba',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
            {
              code: 'PST',
              name: 'Provincial sales tax',
              type: 'provincial',
              tax: 0.08,
            },
          ],
        },
        {
          code: ['NB'],
          state: 'New Brunswick',
          taxes: [
            {
              code: 'HST',
              name: 'Harmonized Sales Tax',
              type: 'harmonized',
              tax: 0.15,
            },
          ],
        },
        {
          code: ['NL'],
          state: 'Newfoundland and Labrador',
          taxes: [
            {
              code: 'HST',
              name: 'Harmonized Sales Tax',
              type: 'harmonized',
              tax: 0.15,
            },
          ],
        },
        {
          code: ['NS'],
          state: 'Nova Scotia',
          taxes: [
            {
              code: 'HST',
              name: 'Harmonized Sales Tax',
              type: 'harmonized',
              tax: 0.15,
            },
          ],
        },
        {
          code: ['NT'],
          state: 'Northwest Territories',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
          ],
        },
        {
          code: ['NU'],
          state: 'Nunavut',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
          ],
        },
        {
          code: ['ON'],
          state: 'Ontario',
          taxes: [
            {
              code: 'HST',
              name: 'Harmonized Sales Tax',
              type: 'harmonized',
              tax: 0.13,
            },
          ],
        },
        {
          code: ['PE'],
          state: 'Prince Edward Island',
          taxes: [
            {
              code: 'HST',
              name: 'Harmonized Sales Tax',
              type: 'harmonized',
              tax: 0.15,
            },
          ],
        },
        {
          code: ['QC', 'QU'],
          state: 'Québec',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
            {
              code: 'QST',
              name: 'Québec Sales Tax',
              type: 'provincial',
              tax: 0.09975,
            },
          ],
        },
        {
          code: ['SK'],
          state: 'Saskatchewan',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
            {
              code: 'PST',
              name: 'Provincial sales tax',
              type: 'provincial',
              tax: 0.06,
            },
          ],
        },
        {
          code: ['YT'],
          state: 'Yukon',
          taxes: [
            {
              code: 'GST',
              name: 'Goods and Services Tax',
              type: 'federal',
              tax: 0.05,
            },
          ],
        },
      ],
    },
  ];
}

export class TaxesUtil {
  private static _taxesData: TaxesData = TaxesData.instance;

  private static getTaxes(countryCode: string, stateCode: string): TaxeModel[] {
    const country: TaxeCountryModel | undefined =
      TaxesUtil._taxesData.taxes.find((t) => t.code.includes(countryCode));
    if (country) {
      const state: TaxeStateModel | undefined = country.states.find((s) =>
        s.code.includes(stateCode)
      );
      if (state) {
        return state.taxes;
      }
    }
    return [];
  }

  static applyTaxes(amount: number, taxes: TaxeModel[]): AmountTx {
    const calculatedAmount: AmountTx = {
      amount: amount,
      taxes: [],
    };
    for (const tax of taxes) {
      const taxToApply: TaxeModel = {
        code: tax.code,
        name: tax.name,
        tax: PricingUtil.roundToTwoDecimals(tax.tax * amount),
        type: tax.type,
      };
      calculatedAmount.taxes.push(taxToApply);
    }
    return calculatedAmount;
  }

  static calculateTaxes(amount: number, taxes: TaxeModel[]): TaxeModel[] {
    const returnTaxes: TaxeModel[] = [];
    for (const tax of taxes) {
      const taxToApply: TaxeModel = {
        code: tax.code,
        name: tax.name,
        tax: PricingUtil.roundToTwoDecimals(tax.tax * amount),
        type: tax.type,
      };
      returnTaxes.push(taxToApply);
    }
    return returnTaxes;
  }

  // ===========================================================================================================
  // = BUSINESS LOGIC RULES FOR TAXATION
  // ===========================================================================================================

  // RETURNS TAX rate, not the actual tax value
  static getCgTaxeRates(cg: CareGiverModel): TaxeModel[] {
    switch (cg.Address.Country) {
      case 'CA':
        return TaxesUtil.getCgTaxeRatesCanada(cg.Address.stateOrProvinceCode);
      default:
        return [];
    }
  }

  // RETURNS TAX rate, not the actual tax value
  static getCsTaxeRates(cs: CareSeekerModel): TaxeModel[] {
    switch (cs.CareSeekerInformation.Address.Country.toUpperCase()) {
      case 'CA':
      case 'CANADA':
        return TaxesUtil.getCsTaxeRatesCanada(
          cs.CareSeekerInformation.Address.stateOrProvinceCode,
          cs.type === CareSeekerTypeModel.partner
        );
      default:
        return [];
    }
  }

  // ===========================================================================================================
  // = COUNTRY SPECIFIC BUSINESS LOGIC RULES FOR TAXATION
  // ===========================================================================================================

  private static getCgTaxeRatesCanada(stateCode: string): TaxeModel[] {
    // Canada: Independent caregivers do not need to collect sales tax for their work
    return [];
  }

  private static getCsTaxeRatesCanada(
    stateCode: string,
    isPartner: boolean
  ): TaxeModel[] {
    if (isPartner) {
      // We charge the sales tax to Partners for work done as they are a commercial entity.
      return TaxesUtil.getTaxes('CA', stateCode);
    } else {
      // We charge taxes to the CS
      return TaxesUtil.getTaxes('CA', stateCode);
    }
  }

  static mergeTaxes(combinedTaxes: TaxeModel[]): TaxeModel[] {
    const mergedTaxes: TaxeModel[] = [];
    for (const taxeModel of combinedTaxes) {
      const existingTax: TaxeModel | undefined = mergedTaxes.find(
        (t) => t.code === taxeModel.code
      );
      if (existingTax) {
        existingTax.tax += taxeModel.tax;
      } else {
        mergedTaxes.push(GeneralUtil.deepClone(taxeModel));
      }
    }
    return mergedTaxes;
  }
}
