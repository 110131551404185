import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLangService } from '../../../services/current-lang.service';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss'],
})
export class LangComponent implements OnInit {
  public flags = [
    { name: 'English', code: 'en', image: 'assets/images/flags/gb.svg' },
    { name: 'Français', code: 'fr', image: 'assets/images/flags/fr.svg' },
  ];
  public flag: any;

  constructor(
    private translate: TranslateService,
    private currentLangService: CurrentLangService
  ) {}

  ngOnInit() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.flag = JSON.parse(lang);
      this.changeLang(JSON.parse(lang));
    } else {
      this.changeLang({
        name: 'English',
        code: 'en',
        image: 'assets/images/flags/gb.svg',
      });
    }
  }

  public changeLang(flag) {
    this.flag = flag;
    this.translate.use(flag.code);
    this.currentLangService.use(flag.code);
    localStorage.setItem('lang', JSON.stringify(flag));
    console.log('changeLang', flag);
  }
}
