import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaComponent } from './agenda.component';
import { AppointmentTemplateComponent } from './appointment-templates/appointment-template.component';
import { AppointmentSummaryTemplateComponent } from './appointment-summary-templates/appointment-summary-template.component';
import { WeeksNavigatorComponent } from './weeks-navigator/weeks-navigator.component';
import { AgendaService } from './agenda.service';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { DurationPickerComponent } from './duration-picker/duration-picker.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgendaExtensionComponent } from './agenda-extension/agenda-extension.component';
import { MatSelectModule } from '@angular/material/select';
import { DepositDialogComponent } from './deposit-dialog/deposit-dialog.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { C4gUiModule } from '../../../c4g-ui/src/lib/c4g-ui.module';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  imports: [
    CommonModule,
    C4gUiModule,
    FormsModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MatDividerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    AgendaComponent,
    AppointmentTemplateComponent,
    AppointmentSummaryTemplateComponent,
    WeeksNavigatorComponent,
    DurationPickerComponent,
    AgendaExtensionComponent,
    DepositDialogComponent,
  ],
  exports: [
    AgendaComponent,
    AppointmentTemplateComponent,
    AppointmentSummaryTemplateComponent,
    WeeksNavigatorComponent,
    DurationPickerComponent,
    AgendaExtensionComponent,
  ],
  providers: [AgendaService, MatDatepickerModule],
})
export class AgendaModule {}
