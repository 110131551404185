import { AppointmentModel } from "./appointmentModel";
import { AmountTx } from "./bank/AmountTx";
import { PenaltyModel } from "./bank/PenaltyModel";
import { CgPaymentCustomLineItemModel } from "./custom-line-item.model";
import { PricingUtil } from "./utils/pricing/pricing.util";
import { Type } from "class-transformer";

export interface PaymentIntentModel {
  cost: AmountTx, // The cost is our (C4G) cost, which is the base CG hourly rate and the taxes.
  reserve: AmountTx,
  charge: AmountTx, // The charge is what the CS has to pay. Based on the CG’s Calculated rate and the taxes.
  refund: AmountTx,
  penaltyCost: AmountTx // Amount of penalty based on cancellation
  penaltyCharge: AmountTx // Amount of penalty based on cancellation
  penaltyC4g: AmountTx // Amount of penalty based on cancellation
  c4g: AmountTx,
  total_amount_cost_txt?: string,
}

export interface PaymentIntentCSEmailModel {
  amount_charged: string;
  taxes: TaxeEmailModel[];
  total_amount_charged: string;
}

export interface TaxeEmailModel {
  code: string;
  tax: string;
}



// This interface will be used when Applying a Payment to a CG
export interface CgPaymentModel {
  _id: string;
  cgId: string;
  from: Date;
  to: Date;
  date: Date;
  appointments: AppointmentModel[];
  penalties: AmountTx;

  payment: AmountTx;
  customLineItems: { [key: string]: CgPaymentCustomLineItemModel[] } ;
}

export class CgPaymentClass implements CgPaymentModel {
  _id: string;
  appointments: AppointmentModel[];
  cgId: string;
  @Type(() => Date)
  date: Date;
  @Type(() => Date)
  from: Date;
  @Type(() => Date)
  to: Date;
  payment: AmountTx;
  penalties: AmountTx;
  customLineItems: { [key: string]: CgPaymentCustomLineItemModel[] } = {};
}

export interface PaymentLineItemModel {
  desc: string;
  amount: string;
}

export class CgPaymentModelHelper {
  private _cgPaymentModel: CgPaymentModel;
  private _appointments: AppointmentModel[] = [];

  constructor(cgPaymentModel: CgPaymentModel) {
    this._cgPaymentModel = cgPaymentModel;
    this._appointments.push(...cgPaymentModel.appointments);
  }

  get totalHours(): number {
    return this._appointments.map((appt) => appt.hours).reduce((total, hours) => total + hours, 0);
  }

  get totalHoursWorked(): number {
    return this._appointments.map((appt) => {
      return appt.checkInOut ? appt.checkInOut.totalHours : 0;
    }).reduce((total, hours) => total + hours, 0);
  }

  get totalAmount(): number {
    return this._cgPaymentModel.payment.amount;
  }

  get totalTaxes(): number {
    return PricingUtil.totalTx(this._cgPaymentModel.payment.taxes);
  }

  get appointments(): AppointmentModel[] {
    return this._appointments;
  }

}

export interface CGPaymentModelPrint {
  totalHours: number;
  totalAmount: string;
  totalTaxes: string;
  penaltiesAmount: string;
  penaltiesTaxes: string;
  grandTotal: string;
}

export interface RatesModel {
  minimum: number;
  percent: number;
  rn: number;
  lpn: number;
  pab: number;
  psw: number;
  aprn: number;
}
