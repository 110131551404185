import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CareLogEntryModel, CareLogModel, CareLogPriority } from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-care-log-book',
  templateUrl: './care-log-book.component.html',
  styleUrls: ['./care-log-book.component.scss'],
})
export class CareLogBookComponent {
  @Input() careLog!: CareLogModel;
  @Input() csId!: string;
  @Input() loId!: string;
  @Input() name!: string;

  @Output()
  changed: EventEmitter<CareLogEntryModel> = new EventEmitter<CareLogEntryModel>();

  newTask = '';
  priority = CareLogPriority.normal;


  newTaskClicked() {
    if (!this.newTask || this.newTask === '') {
      return;
    }

    console.log('Added log entry', this.newTask);
    const entry = new CareLogEntryModel();
    entry.entry = this.newTask;
    entry.name = this.name;
    entry.csId = this.csId;
    entry.loId = this.loId;
    entry.date = new Date();
    entry.priority = CareLogPriority[this.priority];

    this.careLog.entries.push(entry);
    this.newTask = '';
    this.changed.emit(entry);
  }

  getEntries() {
    return this.careLog?.entries.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }
}
