import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateUtil, DaysEnum, GeneralUtil, WeeklyAvailabilitiesModel } from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-available-range',
  templateUrl: './availability-range.component.html',
  styleUrls: ['./availability-range.component.scss'],
})
export class AvailabilityRangeComponent {
  weeklyAvailability: WeeklyAvailabilitiesModel = DateUtil.processAvailability({
    searchEndTime: "", searchStartTime: "",
    day : DaysEnum.MON,
    startTime : '09:00',
    endTime : '17:00',
    endDay: DaysEnum.MON
  });

  constructor(
    public dialogRef: MatDialogRef<AvailabilityRangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WeeklyAvailabilitiesModel
  ) {
    if (data) {
      this.weeklyAvailability = data;
    }
  }

  accept() {
    this.weeklyAvailability.startTime = DateUtil.convertTimeTo24HourFormat(this.weeklyAvailability.startTime);
    this.weeklyAvailability.endTime = DateUtil.convertTimeTo24HourFormat(this.weeklyAvailability.endTime);
    this.weeklyAvailability = DateUtil.processAvailability(this.weeklyAvailability);

    this.dialogRef.close({save: true, range: this.weeklyAvailability});
  }

  reject() {
    this.dialogRef.close({save: false, range: null});
  }

  getDaysEnum() {
    return GeneralUtil.sortedDaysEnum();
  }

  validForm() {
    if (!this.weeklyAvailability.startTime || !this.weeklyAvailability.endTime) {
      return false;
    }
    if (this.weeklyAvailability.startTime === this.weeklyAvailability.endTime) {
      return false;
    }
    if (!DateUtil.validTimeFormat(this.weeklyAvailability.startTime) || !DateUtil.validTimeFormat(this.weeklyAvailability.endTime)) {
      console.log('invalid time format', this.weeklyAvailability.startTime, this.weeklyAvailability.endTime);
      return false;
    }
    if (DateUtil.convertTimeTo24HourFormat(this.weeklyAvailability.startTime) > DateUtil.convertTimeTo24HourFormat(this.weeklyAvailability.endTime)) {
      this.weeklyAvailability.endDay = DateUtil.nextDay(this.weeklyAvailability.day);
    }
    return true;
  }
}
