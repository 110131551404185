<div fxLayout="row" fxLayoutAlign="start center">
  <button mat-icon-button class="button-wrapper icon-button-left" (click)="prev()" [disabled]="!hasPrevWeek()">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <div class="week">
    <div>{{currentWeek | date : "dd MMM"}} - {{lastDay(currentWeek) | date : "dd MMM"}} </div>
  </div>
  <button mat-icon-button class="button-wrapper icon-button-right" (click)="next()" [disabled]="!hasNextWeek()">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>
