import {
  AutonomyEnum,
  ConcernsEnum,
  ContinenceEnum,
  CurrentResidentialSettingEnum,
  DiagnosticArthritisEnum,
  DiagnosticEnum,
  DiagnosticEnumWithPossible,
  HearingEnum,
  LiftingEnum,
  MobilityIssuesEnum,
  OtherMedicalConditionsEnum,
  VerbalEnum,
  VisionEnum
} from "./enums/assessment.enum";
import {NameModel} from "./NameModel";
import {AddressModel} from "./AddressModel";




export class LovedOnePersonalInfoModel {
  Name: NameModel = new NameModel();
  LegalBusinessName: string;
  AccountingContactName: string;
  AccountingContactPhone: string;
  AccountingContactEmail: string;

  Email: string;
  Phone: string;
  Address: AddressModel = new AddressModel();
  UploadARecentPhotoOfTheLovedOne: [
    {
      ContentType?: string;
      Name?: string;
      File: string;
    }
  ];
  DateOfBirth: string;
}

export class LovedOneCareNeedsAssessmentModel {
  currentResidentialSetting: CurrentResidentialSettingEnum;
  healthIssuesAndMedicalDiagnostics: {
    alzheimerOtherDementia : DiagnosticEnumWithPossible;
    parkinson : DiagnosticEnum;
    muscularDystrophy: DiagnosticEnum;
    muscularSclerosis: DiagnosticEnum;
    arthritis: DiagnosticArthritisEnum;
    other: OtherMedicalConditionsEnum[];
    mobilityIssues: MobilityIssuesEnum;
    liftingTransfers: LiftingEnum;
  } = {
    alzheimerOtherDementia: DiagnosticEnumWithPossible.No,
    parkinson: DiagnosticEnum.No,
    muscularDystrophy: DiagnosticEnum.No,
    muscularSclerosis: DiagnosticEnum.No,
    arthritis: DiagnosticArthritisEnum.No,
    other: [],
    mobilityIssues: MobilityIssuesEnum.None,
    liftingTransfers: LiftingEnum.None
  }
  communication: {
    verbal: VerbalEnum;
    hearing: HearingEnum;
    vision: VisionEnum;
  } = {
    verbal: VerbalEnum.ease,
    hearing: HearingEnum.noAids,
    vision: VisionEnum.noGlasses
  }
  other: {
    continence: ContinenceEnum;
    concerns: ConcernsEnum[];
  } = {
    continence: ContinenceEnum.continent,
    concerns: []
  }
}

export class LovedOneLeveOfAutonomyModel {
  BathingShowering: AutonomyEnum;
  Dressing: AutonomyEnum;
  Toileting: AutonomyEnum;
  PreparingMeals: AutonomyEnum;
  FeedingEating: AutonomyEnum;
  StandingFromSitting: AutonomyEnum;
  TransferChair: AutonomyEnum;
  TransferBed: AutonomyEnum;
  Walking: AutonomyEnum;
  UsingTelephone: AutonomyEnum;
  ManagingMedications: AutonomyEnum;
  ShoppingErrands: AutonomyEnum;
  DrivingManagingTransportation: AutonomyEnum;
  LightHousework: AutonomyEnum;
  Laundry: AutonomyEnum;
}

export class NeedsAndLevelOfAutonomyModel {
  LimitationsAndSensitivities: string[];
  LevelOfAutonomy: LovedOneLeveOfAutonomyModel = new LovedOneLeveOfAutonomyModel();
  EmergencySection: {
    FamilyMember1: string;
    PhoneFamilyMember1: string;
    FriendOrNeighbour1: string;
    PhoneFriendorNeighbour1: string;
    Pharmacy: string;
    MedicalDoctor: string;
    PhonePharmacy: string;
    PhoneMedical: string;
    FriendOrNeighbour2: string;
    PhoneFriendOrNeighbour2: string;
    FamilyMember2: string;
    PhoneFamilyMember2: string;
  };
}

export class LovedOnePassportModel {
  Id: string;
  cbId: string;
  NeedsAndLevelOfAutonomy: NeedsAndLevelOfAutonomyModel = new NeedsAndLevelOfAutonomyModel();
  careNeedsAssessmentModel: LovedOneCareNeedsAssessmentModel = new LovedOneCareNeedsAssessmentModel();
  TypeOfCareAndNotes: string;
  LovedOnePersonalInfo: LovedOnePersonalInfoModel = new LovedOnePersonalInfoModel();
  ItemNumber: number;
  notes: string;
  checked?: boolean;
}

export interface LovedOneKeyValuePairModel {
  id: string;
  name: string;
}
