<div class="section mt-3">
  <div class="px-3">
    <div class="theme-container">
      <h1 class="section-title">Our Services</h1>

      <div fxLayout="row wrap" class="services-wrapper">
        <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center"
          >
            <mat-icon class="mat-icon-xlg" color="primary"
              >location_on</mat-icon
            >
            <h2 class="capitalize fw-600 my-3">
              Find places anywhere in the world
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vestibulum egestas nunc sed ultricies.
            </p>
          </mat-card>
        </div>
        <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center"
          >
            <mat-icon class="mat-icon-xlg" color="primary"
              >supervisor_account</mat-icon
            >
            <h2 class="capitalize fw-600 my-3">
              We have agents with experience
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vestibulum egestas nunc sed ultricies.
            </p>
          </mat-card>
        </div>

        <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center"
          >
            <mat-icon class="mat-icon-xlg" color="primary">home</mat-icon>
            <h2 class="capitalize fw-600 my-3">
              Buy or rent beautiful properties
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vestibulum egestas nunc sed ultricies.
            </p>
          </mat-card>
        </div>

        <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center"
          >
            <mat-icon class="mat-icon-xlg" color="primary"
              >format_list_bulleted</mat-icon
            >
            <h2 class="capitalize fw-600 my-3">
              With agent account you can list properties
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vestibulum egestas nunc sed ultricies.
            </p>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
