<div *ngIf="timezone && timezone !== browserTimezone" class="mb-3 error">
  <div class="red-box p-4">
    <b>{{"timezone.yours" | translate}}: <span class="tz">{{browserTimezone}}</span></b><br/>
    <b>{{"timezone.target" | translate}}: <span class="tz">{{timezone}}</span></b><br/>
  {{"timezone.info" | translate}}
  </div>
</div>

<div fxLayout="row wrap" class="ml-2 full-width" fxLayoutGap="8px">
  <div fxLayout="column">
    <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">

      <input
        #inCtrl
        matInput
        [ngxMatDatetimePicker]="$any(pickerIn)"
        placeholder="Choose a date/time"
        [(ngModel)]="start"
        (dateChange)="onStartDateChanged($event)"
        [ngClass]="negative()"
      />
      <mat-label>Start</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(pickerIn)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #pickerIn
        [showSpinners]="true"
        [showSeconds]="false"
        [stepHour]="1"
        [stepMinute]="30"
        [touchUi]="isMobile()"
        [disableMinute]="false"
        [hideTime]="false"

      >
        <!-- Custom icon or text of Apply icon -->
        <ng-template>
          <!-- <mat-icon>star</mat-icon> -->
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <div *ngIf="lessThanToday(start)" class="error">Start cannot be in the past</div>
  </div>
  <div fxLayout="column">
    <mat-form-field  [appearance]="'outline'" [floatLabel]="'always'">

      <input
        #endCtrl
        matInput
        [ngxMatDatetimePicker]="$any(pickerOut)"
        placeholder="Choose a date/time"
        [(ngModel)]="end"
        (dateChange)="onEndDateChanged($event)"
        [ngClass]="negative()"
      />
      <mat-label>End</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="$any(pickerOut)"
      ></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #pickerOut
        [showSpinners]="true"
        [showSeconds]="false"
        [stepHour]="1"
        [stepMinute]="30"
        [touchUi]="isMobile()"
        [disableMinute]="false"
        [hideTime]="false"
      >
        <!-- Custom icon or text of Apply icon -->
        <ng-template>
          <!-- <mat-icon>star</mat-icon> -->
          <span>OK</span>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <div *ngIf="lessThanToday(end)" class="error">End cannot be in the past</div>

  </div>

</div>
<div fxLayout="column" class="ml-2 full-width" fxLayoutAlign="center center">
  <h6 [ngClass]="negative()" class="m-0 p-0 pt-2">Duration: {{displayDuration}}</h6>
  <div *ngIf="isNegative()" class="error">Duration cannot be negative</div>

</div>
