<div id="app"
     class="app">
  <ng-container *ngIf="goodDbVersion && !needsNotifications()">
    <router-outlet></router-outlet>
    <ng-http-loader></ng-http-loader>
    <c4g-notify-drawer></c4g-notify-drawer>
  </ng-container>
  <ng-container *ngIf="!goodDbVersion">
    <ng-container *ngIf="!loadingVersion">
      <div class="p-3">
        <h1>The application needs to be upgraded</h1>
        <h2>Please go to your respective App Store or Play Store to update</h2>
        <!-- row with 20px gap between items -->
        <div fxLayout="row"
             fxLayoutGap="20px">
          <!-- Images from assets/images/stores -->
          <a href="https://apps.apple.com/ca/app/care4giver-caregiver/id1636504311"><img
                 src="assets/images/stores/ios.png"
                 alt="App Store"
                 width="150"></a>
          <a href="https://play.google.com/store/apps/details?id=com.care4giver.caregiver&hl=en&pli=1"><img
                 src="assets/images/stores/google.png"
                 alt="Play Store"
                 width="150"></a>
        </div>
      </div>
    </ng-container>
    <div *ngIf="loadingVersion">
      <!-- Center Horizontally and vertically -->
      <div fxLayout="column"
           fxLayoutAlign="center center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="goodDbVersion && needsNotifications()">
    <div class="p-3">
      <ng-container *ngIf="!loadingNotif">
        <h1>Checking permissions...</h1>
        <h1>To use this application, you must enable notifications.</h1>
        <h2>Please go to your settings and turn ON notifications for the Care4Giver app</h2>
        <div fxLayout="row"
             fxLayoutGap="20px">
          <!-- Images from assets/images/stores -->
          <button mat-raised-button
                  color="primary"
                  (click)="openSettings()">Go to SETTINGS</button>

        </div>
      </ng-container>
      <div *ngIf="loadingNotif">
        <!-- Center Horizontally and vertically -->
        <div fxLayout="column"
             fxLayoutAlign="center center">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>

        </div>
      </div>
    </div>
  </ng-container>
</div>
