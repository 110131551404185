<form [formGroup]="parentFormGroup">
  <h3>{{ 'Form2.BackgroundCheck' | translate }}</h3>
  <div fxLayout="column">
    <mat-radio-group formControlName="hadBackgroundCheckDone">
      <mat-label class="mr-2">{{
        'Form2.HadBackgroundCheck' | translate
        }}</mat-label>
      <mat-radio-button [value]="true">{{
        'Form2.Yes' | translate
        }}</mat-radio-button>
      <mat-radio-button [value]="false">{{
        'Form2.No' | translate
        }}</mat-radio-button>
    </mat-radio-group>
    <mat-form-field
      [appearance]="'outline'"
      [floatLabel]="'always'"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
    >
      <mat-label>{{ 'Form2.HadBackgroundCheckNumber' | translate }}</mat-label>
      <input matInput formControlName="backgroundFileNumber"/>
    </mat-form-field>
    <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="50" class="p-2">
      <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
        <mat-label>{{ 'Form2.HadBackgroundCheckDate' | translate }}</mat-label>
        <input
          matInput
          formControlName="backgroundCheckDate"
          [matDatepicker]="startPicker"
          (dateChange)="dateChange('change', $event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <h6>{{ 'Form2.BackgroundCheckWarning' | translate }}</h6>

  <p>
    {{ 'Form2.HowManySeniorsHaveYouCaredForInTheLast24Months' | translate }}
  </p>
  <mat-radio-group
    aria-label="Select an option"
    [formControlName]="'HowManySeniorsHaveYouCaredForInTheLast24Months'"
    required
  >
    <mat-radio-button value="None">{{
      'Form2.HowManySeniorsHaveYouCaredForInTheLast24Months_dropdown.1'
        | translate
      }}</mat-radio-button>
    <mat-radio-button value="1 to 3">{{
      'Form2.HowManySeniorsHaveYouCaredForInTheLast24Months_dropdown.2'
        | translate
      }}</mat-radio-button>
    <mat-radio-button value="4 to 8">{{
      'Form2.HowManySeniorsHaveYouCaredForInTheLast24Months_dropdown.3'
        | translate
      }}</mat-radio-button>
    <mat-radio-button value="More than 8">{{
      'Form2.HowManySeniorsHaveYouCaredForInTheLast24Months_dropdown.4'
        | translate
      }}</mat-radio-button>
  </mat-radio-group>
  <p
    class="error"
    *ngIf="
      isSubmitTwo &&
      this.parentFormGroup.value['HowManySeniorsHaveYouCaredForInTheLast24Months'] == ''
    "
  >
    You need to select at least one of options.
  </p>

  <h3>{{ 'Form2.ServicesSkillsAndQualificationsHeading' | translate }}</h3>

  <h4>{{ 'CareGiverInfo.qualifications' | translate }}</h4>
  <div fxLayout="row wrap">
    <mat-checkbox
      *ngFor="let qualificationOther of qualificationsOther"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
      class="example-margin"
      (change)="qualificationsOtherSelected($event)"
      [value]="qualificationOther.value"
      [checked]="getQualificationOtherSelected(qualificationOther.value)"
    >{{ lang.currentLang === 'en' ? qualificationOther.en : qualificationOther.fr }}</mat-checkbox
    >
  </div>
  <h4>{{ 'CareGiverInfo.services' | translate }}</h4>
  <div fxLayout="row wrap">
    <mat-checkbox
      *ngFor="let service of services"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
      class="example-margin"
      (change)="servicesSelected($event)"
      [value]="service.value"
      [checked]="getServiceSelected(service.value)"
    >{{ lang.currentLang === 'en' ? service.en : service.fr }}</mat-checkbox
    >
  </div>
  <h4>{{ 'CareGiverInfo.skills' | translate }}</h4>
  <div fxLayout="row wrap">
    <mat-checkbox
      *ngFor="let skill of skills"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
      class="example-margin"
      (change)="skillsSelected($event)"
      [value]="skill.value"
      [checked]="getSkillSelected(skill.value)"
    >{{ lang.currentLang === 'en' ? skill.en : skill.fr }}</mat-checkbox
    >
  </div>

  <div>
    <i class="small m-0"
    >*
      {{ 'Form2.Services_requirements_requires_RN_Certificate' | translate }}</i
    >
  </div>

  <div>
    <h4>{{ 'Form2.DoYouPlanToOfferMedicationAdmin' | translate }}</h4>
    <mat-radio-group
      aria-label="Select an option"
      [formControlName]="'MedicationAdministrationservices'"
      (change)="offerAdminServices($event)"
    >
      <mat-radio-button value="Yes">{{
        'Form2.YesOrNone.1' | translate
        }}</mat-radio-button>
      <mat-radio-button value="No">{{
        'Form2.YesOrNone.2' | translate
        }}</mat-radio-button>
    </mat-radio-group>
    <br/>
    <i class="small m-0">{{ 'Form2.requiresRNCertification' | translate }}</i>
  </div>

  <div>
    <h4>{{ 'Form2.DoYouPlanToOfferHygieneServices' | translate }}</h4>
    <mat-radio-group
      aria-label="Select an option"
      [formControlName]="'PlanToOfferHygieneServices'"
      (change)="offerHygieneServices($event)"
    >
      <mat-radio-button value="Yes">{{
        'Form2.YesOrNone.1' | translate
        }}</mat-radio-button>
      <mat-radio-button value="No">{{
        'Form2.YesOrNone.2' | translate
        }}</mat-radio-button>
    </mat-radio-group>
    <br/>
  </div>

  <div fxLayout="row wrap">
    <mat-form-field
      [appearance]="'outline'"
      [floatLabel]="'always'"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
    >
      <mat-label>{{
        'Form2.PleaseProvideHealthCareAttendantNo' | translate
        }}</mat-label>
      <input matInput [formControlName]="'HealthcareAttendantNo'"/>
    </mat-form-field>
    <mat-form-field
      [appearance]="'outline'"
      [floatLabel]="'always'"
      fxFlex="50"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
    >
      <mat-label>{{
        'Form2.Please_provide_your_other_certification_no' | translate
        }}</mat-label>
      <input matInput [formControlName]="'OtherCertificationNo'"/>
    </mat-form-field>
  </div>

  <h3>{{ 'Form2.LanguagesYouSpeak' | translate }}</h3>
  <div fxLayout="row wrap">
    <mat-checkbox
      *ngFor="let language of languages"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
      class="example-margin"
      (change)="languageSelected($event)"
      [value]="language.value"
      [checked]="getLanguageSelected(language.value)"
    >{{ lang.currentLang === 'en' ? language.en : language.fr }}</mat-checkbox
    >
  </div>

  <h3>{{ 'Form2.Reference' | translate }}</h3>
  <div fxLayout="column">
    <mat-form-field
      [appearance]="'outline'"
      [floatLabel]="'always'"
      fxFlex="25"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100">
      <mat-label>
        {{ 'Form2.ReferenceText' | translate }}
      </mat-label>
      <input matInput formControlName="Reference" type="text"/>
    </mat-form-field>
  </div>
</form>
