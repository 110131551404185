import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MapsLoaderService } from "../maps-loader.service";
import { AddressModel, GeneralUtil } from "@nx-c4g/c4g-models";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "c4g-address-display",
  templateUrl: "./address-display.component.html",
  styleUrls: ["./address-display.component.scss"]
})
export class AddressDisplayComponent implements OnChanges {
  @Input() processedAddress: AddressModel = {
    CityStatePostalCode: "",
    Clean: true,
    Type: "",
    Street: "",
    Line3: "",
    City: "",
    State: "",
    stateOrProvinceCode: "",
    PostalCode: "",
    Country: "",
    countryCode: "",
    Latitude: undefined,
    Longitude: undefined
  };

  @Output() editAddress: EventEmitter<any> = new EventEmitter();
  @Input() canEdit = false;


  constructor(
    private mapsLoaderService: MapsLoaderService,
    private translate: TranslateService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["processedAddress"]) {
      this.processedAddress.Country = this.processedAddress?.Country?.toLowerCase();
      this.processedAddress.State = this.processedAddress?.State?.toLowerCase();
    }
  }

  get countries() {
    return this.mapsLoaderService.countryNames;
  }

  states(countryCode: string): { [stateOrProvince: string]: string; } {
    return this.mapsLoaderService.stateProvinceNames[countryCode];
  }


  checkMissing(addressPart: string | undefined, errorText: string) {
    if (!addressPart || addressPart.length === 0 || addressPart === "0") {
      return this.translate.instant(errorText);
    }
    return addressPart;
  }

  checkMissingPostalCode(addressPart: string | undefined, countryCode: string, errorText: string) {
    if (!countryCode || countryCode.length === 0) {
      return this.translate.instant(errorText);
    }
    if (!addressPart || addressPart.length === 0 || addressPart === "0" || !GeneralUtil.validatePostalCode(addressPart, countryCode)) {
      return this.translate.instant(errorText);
    }
    return addressPart;
  }

  addressError(addressPart: string | undefined) {

    if (!addressPart || addressPart.length === 0 || addressPart === "0") {
      return "error";
    }
    return "";
  }

  postalCodeError(addressPart: string | undefined, countryCode: string) {
    if (!countryCode || countryCode.length === 0) {
      return "error";
    }
    if (!addressPart || addressPart.length === 0 || addressPart === "0" || !GeneralUtil.validatePostalCode(addressPart, countryCode)) {
      return "error";
    }
    return "";
  }

  tzError(addressPart: string | undefined) {
    if (!addressPart || addressPart.length === 0) {
      return "error";
    }
    return "";
  }

  genAddressLine1() {
    return this.checkMissing(this.processedAddress?.Street, "address.street");
  }

  genAddressCity() {
    return this.checkMissing(this.processedAddress?.City, "address.city");
  }

  genAddressStateOrProvince() {
    return this.checkMissing(this.processedAddress?.State, "address.stateOrProvince");
  }

  genAddressStateOrProvinceCode() {
    return this.checkMissing(this.processedAddress?.stateOrProvinceCode, "address.stateOrProvinceCode");
  }

  genAddressCountry() {
    return this.checkMissing(this.processedAddress?.Country, "address.country");
  }

  genAddressCountryCode() {
    return this.checkMissing(this.processedAddress?.countryCode, "address.countryCode");
  }

  genAddressPostalCode() {
    return this.checkMissingPostalCode(this.processedAddress?.PostalCode, this.processedAddress?.countryCode, "address.postalCode");
  }

  genAddressLat() {
    return this.checkMissing(this.processedAddress?.Latitude?.toString(), "address.latitude");
  }

  genAddressLng() {
    return this.checkMissing(this.processedAddress?.Longitude?.toString(), "address.longitude");
  }

  genAddressTz() {
    return this.checkMissing(this.processedAddress?.timeZone, "address.timezone");
  }


  emitEditAddress() {
      console.log("emitEditAddress");
      this.editAddress.emit();
  }
}
