import { AppointmentModel } from "./appointmentModel";
import {AmountTx} from "./bank/AmountTx";

export class AppointmentExtensionModel {
  _id?: string;
  csId = "";
  cgId = "";
  apptId: string;
  newEndTime?: Date;

  hours: number;
  reason: { value: string, en: string, fr: string };
  newTasks: string[];
  otherReason: string;
  mode: 'cg' | 'cs';
  requestDate?: Date;
  charge?: AmountTx; // Amount to charge
  cost?: AmountTx; // Amount cost
}

export enum AppointmentExtensionResult {
  requestSentToCaregiver = 'requestSentToCaregiver',
  requestSentToCareseeker = 'requestSentToCareseeker',
  added = 'added',
  noFunds = 'noFunds',
}

export class AppointmentExtensionReferenceModel {
  extension: AppointmentExtensionModel;
  appt: AppointmentModel;
}
