
export class CsBankModel {
  _id: string;
  creditMargin: number;
  balance: number;
  reserved: number;
  spent: number;
}









