<mat-dialog-content>
  <mat-form-field [appearance]="'outline'" [floatLabel]="'always'">
    <mat-label class="floating-label">Notes</mat-label>
    <textarea
      matInput
      placeholder="Notes"
      rows="10"
      [(ngModel)]="note"
      required
    ></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
