import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  CareSeekerModel,
  CareSeekerTypeModel,
  LovedOnePassportModel,
  LovedOnePersonalInfoModel
} from "@nx-c4g/c4g-models";

@Component({
  selector: 'c4g-loved-one-popup',
  templateUrl: './loved-one-popup.component.html',
  styleUrls: ['./loved-one-popup.component.scss'],
})
export class LovedOnePopupComponent {
  name: any;
  photo: any;
  needs: any;
  notes: any;
  bookingId: any;
  loaded = false;
  info: LovedOnePersonalInfoModel;
  imgClass: string;
  cs: CareSeekerModel;
  lo: LovedOnePassportModel;

  mapsrc: any;
  isResidence = false;

  constructor(
    public dialogRef: MatDialogRef<LovedOnePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.lo = data.lo;
    this.data = data;
    this.name = data.name;
    this.photo = data.photo;
    this.needs = data.needs;
    this.info = data.info;
    this.notes = data.notes;
    this.bookingId = data.bookingId;
    this.cs = data.cs;
    this.mapsrc =
      'https://www.google.com/maps/search/?api=1&query=' +
      this.info.Address.Street +
      '+' +
      this.info.Address.City +
      '+' +
      this.info.Address.State +
      '+' +
      this.info.Address.PostalCode +
      '+' +
      this.info.Address.Country;
    this.imgClass = 'lo-photo-landscape';
    if (this.cs) {
      this.isResidence = this.cs.type === CareSeekerTypeModel.residence;
    }
    this.loaded = true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  accept() {
    // this.bookingService.acceptBooking(this.bookingId);

    this.dialogRef.close();
  }

  reject() {
    // this.bookingService.rejectBooking(this.bookingId);

    this.dialogRef.close();
  }

  handleFileInput(files: FileList) {
    // this.caregiverProfileService.uploadPhoto(files[0]).subscribe(newURL => {
    //   this.getCareGiverById(this.auth.user.uid);
    // });
  }
}
