import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ImgCropperConfig,
  ImgCropperErrorEvent,
  ImgCropperEvent,
  LyImageCropper,
} from '@alyle/ui/image-cropper';

function compressImage(src, newX, newY) {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    };
    img.onerror = (error) => rej(error);
  });
}

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class ImageCropperDialog implements OnInit {
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  @ViewChild('parent', { static: true }) parent: ElementRef;

  myConfig: ImgCropperConfig = {
    width: 300, // Default `250`
    height: 200, // Default `200`
    type: 'image/jpeg', // Or you can also use `image/jpeg`
    output: {
      width: 800,
      height: 600,
    },
  };
  scale: any;
  minScale: number;
  ready: boolean;
  croppedImage: ImgCropperEvent;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // this.cropper.loadImage(this.data.event);

    const sWidth = this.parent.nativeElement.offsetWidth;
    if (sWidth <= 600) {
      console.log('parent width', sWidth);
      this.myConfig.width = sWidth * 0.75;
      this.myConfig.height = this.myConfig.width * 0.75;
    }
    this.cropper.selectInputEvent(this.data.event);
  }

  save() {
    this.cropper.crop();
    console.log('Cropped img to save: ', this.croppedImage);

    this.dialogRef.close(this.croppedImage);

    // compressImage(this.croppedImage, 800, 600).then(compressed => {
    //   this.dialogRef.close(compressed);
    // });
  }

  onReady($event: ImgCropperEvent) {
    this.ready = true;
    console.log('Img ready for cropper', $event);
  }

  onCropped($event: ImgCropperEvent) {
    console.log('Cropped img: ', $event);
    this.croppedImage = $event;
  }

  onLoaded($event: ImgCropperEvent) {}

  onError($event: ImgCropperErrorEvent) {
    console.warn(`'${$event.name}' is not a valid image`, $event);
  }

  crop() {}
}
