<div class="menu-item">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button
    [routerLink]="['/']"
    routerLinkActive="horizontal-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="uppercase">{{ 'Menu.Home' | translate }}</span>
  </a>
</div>
<div class="menu-item">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button
    [routerLink]="['/dashboard']"
    routerLinkActive="horizontal-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="uppercase">{{ 'Menu.dashboard' | translate }}</span>
  </a>
</div>
<div class="menu-item">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button
    [routerLink]="['/care-givers/me']"
    routerLinkActive="horizontal-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="uppercase">{{ 'Menu.MyProfile' | translate }}</span>
  </a>
</div>
<div class="menu-item" fxLayout="row" fxLayoutAlign="stretch">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button

    [routerLink]="['/extensions']"
    routerLinkActive="vertical-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span [matBadge]="extensions?.length + invitationCount" matBadgeColor="warn" class="uppercase" [matBadgeHidden]="extensions?.length + invitationCount === 0">{{'Menu.invitations' | translate }}</span>
  </a>
</div>
<div class="menu-item">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button
    [routerLink]="['/availabilities']"
    routerLinkActive="horizontal-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="uppercase">{{ 'Menu.Availabilities' | translate }}</span>
  </a>
</div>
<div class="menu-item">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button
    [routerLink]="['/check-ins']"
    routerLinkActive="horizontal-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="uppercase">{{ 'Menu.CheckIns' | translate }}</span>
  </a>
</div>
<div class="menu-item">
  <a
    *ngIf="auth.isLoggedIn() | async"
    mat-button
    [routerLink]="['/teams']"
    routerLinkActive="horizontal-active-link"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="uppercase">{{ 'Menu.Teams' | translate }}</span>
  </a>
</div>

<div class="menu-item">
  <a
    *ngIf="!user"
    [routerLink]="'/login'"
    fxLayout="row"
    fxLayoutAlign="start center"
    mat-button
  ><span>Login </span></a
  >
  <a
    *ngIf="!user"
    [routerLink]="'/register'"
    fxLayout="row"
    fxLayoutAlign="start center"
    mat-button
  ><span>Register</span></a
  >
</div>
