<div #parent>
  <h3>Please crop your photo for best fit</h3>
  <ly-image-cropper
    [config]="myConfig"
    [(scale)]="scale"
    (ready)="onReady($event)"
    (minScale)="minScale = $event"
    (cleaned)="ready = false"
    (cropped)="onCropped($event)"
    (loaded)="onLoaded($event)"
    (error)="onError($event)"
    class="MinimaLight"
  ></ly-image-cropper>

  <div fxLayout="row" fxLayoutAlign="center center" class="mt-2">
    <button mat-raised-button color="primary" (click)="save()">Save</button>
  </div>
</div>
