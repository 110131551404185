<div>
  <mat-card class="lo-card">
    <!--  <mat-card-header>-->
    <!--    <div mat-card-avatar class="header-image" [style.background-image]="'url(' + photo + ')'"></div>-->
    <!--    <mat-card-title>{{name}}</mat-card-title>-->
    <!--    <mat-card-subtitle>{{info.Address.City}}</mat-card-subtitle>-->
    <!--  </mat-card-header>-->
    <div class="main-layout">
      <div class="main-layout-photo">
        <div class="lo-header">
          <mat-card-title>{{
            getName(data.LovedOnePersonalInfo)
          }}</mat-card-title>
          <mat-card-subtitle
            >{{ data.LovedOnePersonalInfo.Address?.Street }} <span *ngIf="data.LovedOnePersonalInfo.Address?.Line3">, {{data.LovedOnePersonalInfo.Address?.Line3}}</span>,
            {{ data.LovedOnePersonalInfo.Address?.City }}</mat-card-subtitle
          >
        </div>
      </div>
      <div class="main-layout-content">
        <mat-card-content class="card-content">
          <c4g-lo-need
            [jsonElement]="data.NeedsAndLevelOfAutonomy"
            [notes]="data.notes"
            [typeOfCareAndNotes]="data.TypeOfCareAndNotes"
            [assessment]="data.careNeedsAssessmentModel"
            [hidePersonalInfo]="hidePersonalInfo"
          ></c4g-lo-need>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
</div>
